import {
  Slide,
  Box,
  Paper,
  Divider,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import ToolBox from "ToolBox";
import RegionCard from "../RegionCard";
import COOImage from "../../../cooPhoto.jpg";
import BEEImage from "../../../beePhoto.jpg";
import GBAImage from "../../../gbaPhoto.jpg";
import CrestBaCsiroLogo from "images/Crest-BA-CSIRO.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Themes from "components/Themes/Themes";

const styles = makeStyles((theme) => ({
  logoPaper: {
    width: `100%`,
    height: `fit-content`,
    padding: theme.spacing(2),
    boxSizing: `border-box`,
    margin: `16px 0px`,
  },
}));

const HomePage = (props: { in: boolean; resetNetwork: () => void }) => {
  const classes = styles();

  const history = useHistory();

  return (
    <Slide
      in={props.in}
      direction={`left`}
      timeout={200}
      mountOnEnter
      unmountOnExit
    >
      <Box>
        <Paper className={`${classes.logoPaper} tour-guide tour-0`}>
          <Box display={`flex`} alignItems={`flex-end`} flexWrap={`wrap`}>
            <a href="https://www.bioregionalassessments.gov.au/">
              <img
                src={CrestBaCsiroLogo}
                alt={`GBA Logo`}
                style={{ marginLeft: `16px` }}
              />
            </a>
            <Typography
              variant={`h4`}
              style={{ marginLeft: `24px`, fontWeight: 300 }}
              color={`primary`}
            >
              GBA Explorer
            </Typography>
          </Box>
          <Divider style={{ width: `100%` }} />
          <Typography
            variant={`body2`}
            style={{ margin: "8px 0 -14px 0", padding: `0 16px` }}
          >
            <p>
              Welcome to the Geological and Bioregional Assessments (GBA)
              Explorer. The GBA Explorer is an interactive visualisation of the
              cause-and-effect relationships between unconventional gas resource
              developments and environment and water-related matters within the
              Beetaloo and Cooper GBA regions. GBA Explorer was developed
              through Stage 3 of the Australian Government’s $35.4 Million
              Geological and Bioregional Assessment Program.
            </p>
            <p>
              The graphical presentation provides immediate access to node
              descriptions, link evaluations and an overall assessment summary.
              Users can visualise the entire causal network or simplify it by
              selecting specific pathways. Spatial data are also presented via
              interactive maps that include the spatial information used to
              inform the assessment and spatial impact maps.
            </p>
            <p>
              Results are presented at a regional scale and are not a substitute
              for detailed site-specific assessments required under state,
              territory or Commonwealth law. The GBA Explorer provides decision
              makers with a shared understanding of the key environment and
              water-related matters that need more detailed investigation and
              the control measures that can be used to manage potential risks.
            </p>
            <Box
              style={{
                float: `right`,
                display: `inline-block`,
                marginTop: `-14px`,
              }}
            >
              <video
                style={{
                  margin: `0px 16px 0px`,
                  height: `240px`,
                  borderRadius: `2px`,
                }}
                controlsList="nodownload"
                src="https://publish.viostream.com/player/download/bgoo5gydjnzwyt"
                controls
              ></video>
            </Box>
            <p>
              GBA Explorer contains a large amount of information, ‘
              <a href={`/start_tour`}>Take the tour</a>’ to learn how to
              navigate this information. Information on the impact assessment
              method is provided on the ‘
              <a href={`/gba`}>About GBA Explorer</a>’ page. We also
              encourage you to read ‘<a href={`/how_to`}>How to use the site</a>
              ’ to learn more about the GBA Explorer.
            </p>
            <p>
              Please see{" "}
              <a href={`https://bioregionalassessments.gov.au/gba`}>
                bioregionalassessments.gov.au/gba
              </a>{" "}
              for details on the GBA Program and for further supporting
              materials, including the results from stage 1 and stage 2 of the
              GBA Program.
            </p>
            <p>
              All of the data, models and maps created or used by the GBA
              Program and in the GBA Explorer are publicly available for
              download from <a href={`https://data.gov.au`}>data.gov.au</a>.
            </p>
          </Typography>
          <Divider style={{ width: `100%` }} />
          <Box
            display={`flex`}
            flexDirection={`column`}
            alignItems={`flex-start`}
          >
            <Link
              to={`/how_to`}
              style={{ display: `contents`, textDecoration: `none` }}
            >
              <Button>
                How to use the site
                <span
                  className={`material-icons`}
                  style={{ marginLeft: "8px" }}
                >
                  chevron_right
                </span>
              </Button>
            </Link>
          </Box>
          <Divider style={{ width: `100%` }} />
          <Box display={`flex`} flexWrap={`wrap`}>
            <RegionCard
              image={BEEImage}
              title={`Beetaloo GBA region`}
              onClick={() => {
                props.resetNetwork();
                ToolBox.SetTenant(`bee`, history);
              }}
            />
            <RegionCard
              image={COOImage}
              title={`Cooper GBA region`}
              onClick={() => {
                props.resetNetwork();
                ToolBox.SetTenant("coo", history);
              }}
            />
            <RegionCard
              className={`tour-guide tour-1`}
              image={GBAImage}
              title={`About GBA Explorer`}
              onClick={() => {
                props.resetNetwork();
                ToolBox.SetTenant("gba", history);
              }}
            />
          </Box>
        </Paper>
      </Box>
    </Slide>
  );
};

export default HomePage;
