import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles(() => ({
  listItem: {
    color: `rgba(0, 0, 0, 0.5)`,
    cursor: `pointer`,
    "&:hover": {
      color: `rgba(0, 0, 0, 1) `,
    },
  },
}));

const TableOfContentsEntry = (props: {
  item: { title: string; href: string };
  selected: boolean;
}) => {
  const classes = styles();

  return (
    <a
      href={`#${props.item.href}`}
      style={{ display: `contents`, textDecoration: `none` }}
    >
      <ListItem
        className={`${classes.listItem}`}
        style={{
          borderLeft: `2px solid ${
            props.selected ? `rgba(0, 0, 0, 1)` : `rgba(0, 0, 0, 0.12)`
          }`,
          color: props.selected ? `rgba(0, 0, 0, 1)` : ``,
        }}
      >
        <ListItemText
          style={{ fontWeight: props.selected ? 700 : 400, color: `inherit` }}
        >
          {props.item.title}
        </ListItemText>
      </ListItem>
    </a>
  );
};

export default TableOfContentsEntry;
