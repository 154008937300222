import { Box, Divider, List, Slide } from "@material-ui/core";
import React, { useEffect } from "react";
import NamedPath from "types/NamedPath";
import PinnedListItem from "../../GraphPinnedControls/PinnedListItem";

const GraphNamedPathwayNodePane = (props: {
  in: boolean;
  pathway?: NamedPath;
  nodes: { uid: string; [key: string]: any }[];
  [key: string]: any;
}) => {
  const [isIn, setIsIn] = React.useState(props.in);
  const isInTimerRef: React.MutableRefObject<NodeJS.Timeout | null> = React.useRef(
    null as NodeJS.Timeout | null
  );

  useEffect(() => {
    if (props.in) {
      if (isInTimerRef.current) clearTimeout(isInTimerRef.current);
      isInTimerRef.current = setTimeout(() => setIsIn(true), 200);
    } else {
      if (isInTimerRef.current) clearTimeout(isInTimerRef.current);
      setIsIn(false);
    }
  }, [props.in]);

  return (
    <Slide in={isIn} direction={`left`} mountOnEnter unmountOnExit>
      <Box
        minWidth={`100%`}
        height={`100%`}
        overflow={`hidden auto`}
        className={`custom-scrollbar`}
      >
        <List>
          {props.pathway?.node_ids
            .map((uid) => props.nodes.find((n) => n.uid === uid))
            .filter((n) => n)
            .map((node, index) => (
              <>
                {index !== 0 && <Divider />}
                <PinnedListItem key={index} item={node} />
              </>
            ))}
        </List>
      </Box>
    </Slide>
  );
};

export default GraphNamedPathwayNodePane;
