import { Box, makeStyles, Slide } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

const styles = makeStyles((theme) => ({
  contentPageBox: {
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "column",
    top: 0,
    left: 0,
    width: "-webkit-fill-available",
    height: "100%",
    boxSizing: "border-box",
  },
  scrollable: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    paddingLeft: "18px",
  },
}));

const ContentPage = (props) => {
  const classes = styles();

  const history = useHistory();

  const [movement, setMovement] = React.useState(1);

  let index =
    history.location.pathname.split(/\//g)[!!props.isStandalone ? 5 : 4] || "0";

  const [isIn, setIsIn] = React.useState(false);
  const isInTimerRef = React.useRef();

  useEffect(() => {
    if (index === props.value) {
      isInTimerRef.current = setTimeout(() => {
        setIsIn(true);
        setMovement(index - props.value);
      }, 250);
    } else {
      clearTimeout(isInTimerRef.current);
      setIsIn(false);
      setMovement(index - props.value);
    }
  }, [index]);

  const getDirection = () => {
    return movement > 0 ? "right" : "left";
  };

  return (
    <Slide
      in={isIn}
      direction={getDirection()}
      timeout={200}
      mountOnEnter
      unmountOnExit
    >
      <Box
        className={`${!!props.scroll ? classes.scrollable : ""} ${
          classes.contentPageBox
        } custom-scrollbar`}
        style={{ overflow: props.isStandalone ? "unset" : `unset` }}
      >
        {props.children}
      </Box>
    </Slide>
  );
};

export default ContentPage;
