import { Box, Divider, List, Slide, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import NamedPath from "types/NamedPath";

const GraphNamedPathwayAboutPane = (props: {
  in: boolean;
  pathway?: NamedPath;
  [key: string]: any;
}) => {
  const [isIn, setIsIn] = React.useState(props.in);
  const isInTimerRef: React.MutableRefObject<NodeJS.Timeout | null> = React.useRef(
    null as NodeJS.Timeout | null
  );

  useEffect(() => {
    if (props.in) {
      if (isInTimerRef.current) clearTimeout(isInTimerRef.current);
      isInTimerRef.current = setTimeout(() => setIsIn(true), 200);
    } else {
      if (isInTimerRef.current) clearTimeout(isInTimerRef.current);
      setIsIn(false);
    }
  }, [props.in]);

  return (
    <Slide in={isIn} direction={`right`} mountOnEnter unmountOnExit>
      <Box
        boxSizing={`border-box`}
        padding={`4px 8px`}
        height={`100%`}
        minWidth={`100%`}
        overflow={`hidden auto`}
        className={`custom-scrollbar`}
      >
        <Typography variant={`body2`} style={{ fontWeight: 700 }}>
          Description
        </Typography>
        <Box
          className={`ck ck-content`}
          padding={`0 8px`}
          dangerouslySetInnerHTML={{
            __html: props.pathway?.description ?? "",
          }}
        ></Box>
        <Divider style={{ margin: `4px 0` }} />
        <Typography variant={`body2`} style={{ fontWeight: 700 }}>
          Level of concern
        </Typography>
        <Box padding={`0 8px`}>{props.pathway?.level_of_concern}</Box>
        <Divider style={{ margin: `4px 0` }} />
        <Typography variant={`body2`} style={{ fontWeight: 700 }}>
          Path confidence
        </Typography>
        <Box
          className={`ck ck-content`}
          padding={`0 8px`}
          dangerouslySetInnerHTML={{
            __html: props.pathway?.path_confidence ?? "",
          }}
        ></Box>
      </Box>
    </Slide>
  );
};

export default GraphNamedPathwayAboutPane;
