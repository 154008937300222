import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import NamedPath from "types/NamedPath";
import GraphNamedPathwayAboutPane from "./GraphNamedPathwayAboutPane";
import GraphNamedPathwayNodePane from "./GraphNamedPathwayNodePane";

const styles = makeStyles((theme) => ({
  paper: {
    display: `flex`,
    flexDirection: `column`,
    overflow: `hidden`,
    pointerEvents: "all",
    border: `1px solid ${theme.palette.divider}`,
  },
  headerBox: {
    boxShadow: `0px 0px ${theme.spacing(1)}px ${theme.palette.divider}`,
  },
  listHeading: {
    fontSize: ".6875rem",
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    lineHeight: 2.66,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
  },
  tabs: {
    height: `fit-content`,
    minHeight: `32px`,
  },
  tab: {
    minHeight: `unset`,
    minWidth: `unset`,
  },
}));

const GraphNamedPathwayCard = (props: {
  in: boolean;
  pathway?: NamedPath;
  nodes: { uid: string; [key: string]: any }[];
  handleClose: () => void;
  [key: string]: any;
}) => {
  const classes = styles();

  const [isIn, setIsIn] = React.useState(props.in);
  const isInTimerRef: React.MutableRefObject<NodeJS.Timeout | null> =
    React.useRef(null as NodeJS.Timeout | null);

  const [pathway, setPathway]: [
    NamedPath | undefined,
    React.Dispatch<React.SetStateAction<NamedPath | undefined>>
  ] = React.useState(undefined as NamedPath | undefined);
  const pathwayTimerRef: React.MutableRefObject<NodeJS.Timeout | null> =
    React.useRef(null as NodeJS.Timeout | null);

  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    if (props.in) {
      setTabIndex(0);
      if (pathwayTimerRef.current) clearTimeout(pathwayTimerRef.current);
      setPathway(props.pathway);
      if (isInTimerRef.current) clearTimeout(isInTimerRef.current);
      isInTimerRef.current = setTimeout(() => setIsIn(true), 500);
    } else {
      if (pathwayTimerRef.current) clearTimeout(pathwayTimerRef.current);
      pathwayTimerRef.current = setTimeout(() => setPathway(undefined), 500);
      if (isInTimerRef.current) clearTimeout(isInTimerRef.current);
      setIsIn(false);
    }
  }, [props.in, props.pathway]);

  return (
    <Slide
      direction={`right`}
      in={isIn}
      mountOnEnter
      unmountOnExit
      timeout={200}
    >
      <Box
        marginBottom={`8px`}
        display={`flex`}
        flexDirection={`column`}
        width={`360px`}
        flexGrow={1}
        minHeight={`38px`}
      >
        <Paper elevation={3} className={`${classes.paper}`}>
          <Box
            display={`flex`}
            alignItems={`center`}
            width={`100%`}
            height={`38px`}
            minHeight={`38px`}
            position={`relative`}
            className={`${classes.headerBox}`}
          >
            <Box width={`38px`} padding={`4px`} display={`flex`}>
              <IconButton size={`small`} onClick={props.handleClose}>
                <span className={`material-icons`}>arrow_back</span>
              </IconButton>
            </Box>
            <Tooltip title={pathway?.name ?? ""} enterDelay={1000} arrow>
              <Typography
                noWrap
                className={classes.listHeading}
                align={`center`}
              >
                {pathway?.name}
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Tabs
            classes={{ root: classes.tabs }}
            value={tabIndex}
            onChange={(e, value) => setTabIndex(value)}
          >
            <Tab
              value={0}
              label={<Typography variant={`body2`}>About</Typography>}
              classes={{ root: classes.tab }}
            />
            <Tab
              value={1}
              label={<Typography variant={`body2`}>Nodes</Typography>}
              classes={{ root: classes.tab }}
            />
          </Tabs>
          <Divider />
          <Box
            display={`flex`}
            position={`relative`}
            width={`100%`}
            height={`fit-content`}
            overflow={`hidden`}
          >
            <GraphNamedPathwayAboutPane in={tabIndex === 0} pathway={pathway} />
            <GraphNamedPathwayNodePane
              in={tabIndex === 1}
              pathway={pathway}
              nodes={props.nodes}
            />
          </Box>
        </Paper>
      </Box>
    </Slide>
  );
};

export default GraphNamedPathwayCard;
