import { Box, Collapse, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const GlossaryPageItem = (props: { glossary: { [key: string]: any } }) => {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <Box
      padding={`48px 0`}
      className={`content-page-item`}
      id={`${props.glossary.title.toLowerCase().replaceAll(" ", "_")}`}
    >
      <div
        style={{
          display: `contents`,
          textTransform: `none`,
          textDecoration: `none`,
          color: `unset`,
        }}
      ></div>
      <Box
        style={{ cursor: expanded ? `unset` : `pointer` }}
        onClick={() => (!expanded ? setExpanded(true) : null)}
      >
        <Box
          display={`flex`}
          alignItems={`center`}
          style={{ cursor: "pointer" }}
          onClick={() => setExpanded((exp) => !exp)}
        >
          <Typography style={{ flexGrow: 1 }} variant={`h5`} component={`h2`}>
            {props.glossary.title
              .split("")
              .reduce(
                (z: string, x: string, i: number) =>
                  `${z}${i === 0 ? x.toUpperCase() : x}`,
                ""
              )}
          </Typography>
          <IconButton
            size={`small`}
            style={{
              transition: `all 200ms ease`,
              transform: `rotate(${expanded ? "0deg" : "180deg"})`,
            }}
            color={expanded ? `default` : `primary`}
          >
            <span className={`material-icons`}>expand_less</span>
          </IconButton>
        </Box>
        <Collapse
          in={expanded}
          style={{ position: `relative` }}
          collapsedHeight={`40px`}
        >
          <Box
            position={expanded ? `absolute` : `relative`}
            style={{
              marginTop: `20px`,
              opacity: expanded ? 0 : 1,
              transition: `opacity 200ms ease`,
            }}
          >
            <Typography
              color={`textSecondary`}
              variant={`body2`}
              style={{
                textTransform: `uppercase`,
                textDecoration: `underline`,
                fontWeight: `bold`,
                fontSize: `13px`,
              }}
            >
              Read More
            </Typography>
          </Box>
          <Box
            position={expanded ? `relative` : `absolute`}
            style={{
              top: 0,
              marginTop: `48px`,
              opacity: expanded ? 1 : 0,
              transition: `opacity 200ms ease`,
            }}
          >
            <Typography variant={`body1`}>
              {props.glossary.seo_meta_description
                ?.split("")
                .map((l: string, i: number) => (i === 0 ? l.toUpperCase() : l))
                .join("")}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default GlossaryPageItem;
