import { Box, Card, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import ToolBox from "../../ToolBox";
import Themes from "../Themes/Themes";

const styles = makeStyles((theme) => ({
  contentDrawer: {
    pointerEvents: `all`,
    position: "absolute",
    transition: `all ${Themes.CurrentTheme().transition.normal} ease`,
    overflow: "hidden",
    zIndex: "0",
    margin: "8px",
    border: `1px solid ${theme.palette.divider}`,
  },
  dragHandle: {
    color: theme.palette.text.secondary,
    transform: `rotate(90deg)`,
    pointerEvents: "none",
    margin: `auto -6px`,
  },
}));

const ContentDrawer = (props) => {
  const classes = styles();

  const [mounted, setMounted] = React.useState(false);
  const timer = React.useRef();

  const [init, setInit] = React.useState(true);

  const _ = (s) => s;

  useEffect(() => {
    if (props.open) {
      setMounted(true);
      clearTimeout(timer.current);
      return;
    }

    clearTimeout(timer.current);
    timer.current = setTimeout(() => setMounted(false), 2000);
  }, [props.open]);

  const sendSizeUpdate = React.useState(() =>
    ToolBox.debounce((size) => {
      props.setDrawerWidth(size);
    }, 50)
  )[0];

  const handleResize = (e) => {
    e.dataTransfer.effectAllowed = "none";
    e.preventDefault();
    e.stopPropagation();
    if (e.clientX === 0) return;

    let outer = document.getElementById(`drawer-outer`);
    //let inner = document.getElementById(`drawer-inner`);

    let delta = outer.offsetLeft - e.clientX + 6;

    outer.style.width = `${outer.clientWidth + delta}px`;
    //outer.style.minWidth = `${Math.max(outer.clientWidth + delta, 600)}px`;
    outer.style.transition = "all 0ms";
    //outer.style.transform = `translate(${
    //  props.open ? 0 : `${1 * (outer.clientWidth + delta) + 16}px`
    //}, 0)`;

    //inner.style.width = `${inner.clientWidth + delta}px`;
  };

  let outerWidth =
    document.getElementById(`drawer-outer`)?.clientWidth ?? window.innerWidth;

  return true ? (
    <Card
      id={`drawer-outer`}
      style={{
        transform: `translate(${
          props.open ? 0 : `${1 * outerWidth + 16}px`
        }, 0)`,
        right: 0,
        top: 0,
        bottom: 0,
        minWidth: `${600}px`,
        width: `${props.width ?? 600}px`,
        maxWidth: init ? `${props.width ?? 500}px` : `calc(100% - 384px)`,
      }}
      className={`${classes.contentDrawer} tour-guide tour-8`}
      elevation={3}
    >
      <Box
        zIndex={1}
        position="absolute"
        display="flex"
        top="0"
        left="0"
        height="100%"
        width="12px"
        style={{ cursor: "col-resize" }}
        onDragEnd={() => {
          let outer = document.getElementById(`drawer-outer`);
          outer.style.transition = `all ${
            Themes.CurrentTheme().transition.normal
          } ease`;
        }}
        onDrag={handleResize}
        onDragStart={(e) => {
          e.stopPropagation();
          let i = new Image();
          i.src =
            "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
          e.dataTransfer.setDragImage(i, 0, 0);
          e.dataTransfer.effectAllowed = "none";
          setInit(false);
        }}
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        draggable={true}
      >
        <span className={`material-icons ${classes.dragHandle}`}>
          drag_handle
        </span>
      </Box>
      <Box
        id={`drawer-inner`}
        maxWidth="100%"
        minWidth={`${600 ?? 500}px`}
        height="100%"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          {props.children}
        </Box>
      </Box>
    </Card>
  ) : null;
};

export default ContentDrawer;
