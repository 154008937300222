import { Box, Fab, Grow, Tooltip, withStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import ToolBox from "ToolBox";
import LinkUnfurling from "../LinkUnfurling/LinkUnfurling";
import TOC from "../TOC/TOC";

import "../../styles/ckeditor.css";
import "../../styles/gbacolours.scss";

const UnfurlingTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: "unset",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: "512px",
    height: "fit-content",
    padding: 0,
  },
  arrow: {
    color: theme.palette.background.paper,
  },
}))(Tooltip);

const EntityContentViewer = (props: {
  content: string[];
  titles: (string | null)[];
  noTOC?: boolean;
  inline?: boolean;
  [key: string]: any;
}) => {
  const id = React.useState(ToolBox.uuidv4())[0];

  const [anchor, setAnchor] = React.useState(null as HTMLElement | null);
  const trigger = JSON.stringify(props.content);

  const shouldUnfurl = (link: HTMLAnchorElement) => {
    const href = link.href ?? "";

    const itemId = !href.match(
      new RegExp("^" + window.location.origin + "/.+$")
    )
      ? null
      : !!href.match(/^http(s)?:\/\/[^/]+\/[^/]+\/[^/]+\/item\/\d+\/?$/gi)
      ? href.split(`/item/`)?.[1]?.split(`/`)?.[0]
      : !!href.match(/^http(s)?:\/\/[^/]+\/[^/]+\/[^/]+\/\d+(\/.+)?\/?$/gi)
      ? href.split(/^http(s)?:\/\/[^/]+/gi)?.[2]?.split(`/`)?.[3]
      : null;

    // console.log(itemId)

    if (id !== null) {
      return !!window.location.href.match(
        /^http(s)?:\/\/[^/]+\/[^/]+\/[^/]+\/item\/\d+\/?/gi
      )
        ? itemId !== window.location.pathname.split(`/`)?.[4]
        : itemId !== window.location.pathname.split(`/`)?.[3];
    }

    return (
      !href.match(new RegExp("^" + window.location.origin + "/.+$")) ||
      !!href.match(/^http(s)?:\/\/[^/]+\/[^/]+\/[^/]+\/item\/\d+\/?$/gi) ||
      !!href.match(/^http(s)?:\/\/[^/]+\/[^/]+\/[^/]+\/\d+(\/.+)?\/?$/gi)
    );
  };
  useEffect(() => {
    process.nextTick(() => {
      let links = document
        .getElementById(`content-${id}`)
        ?.getElementsByTagName(`a`);

      [...(links ?? [])].forEach((link) => {
        if (shouldUnfurl(link)) {
          link.setAttribute("class", "unfurl");
          ReactDOM.render(
            <UnfurlingTooltip
              interactive
              arrow
              title={<LinkUnfurling link={link} />}
            >
              <span dangerouslySetInnerHTML={{ __html: link.innerHTML }}></span>
            </UnfurlingTooltip>,
            link
          );
        }
      });
    });
  }, [trigger]);

  return (
    <Box
      style={{
        overflowY: props.isStandalone ? "unset" : "unset",
        scrollBehavior: "smooth",
        marginTop: `8px`,
        padding: `0px ${props.isStandalone ? 36 : 0}px`,
      }}
      marginLeft={`auto`}
      marginRight={`auto`}
      maxWidth={`calc(min(1200px, 86%))`}
      minWidth={`calc(min(1200px, 86%))`}
      className={`custom-scrollbar`}
    >
      {!props.noTOC && (
        <Tooltip title={`Table of contents`}>
          <Fab
            className={`tour-guide tour-10`}
            onClick={(ev) => setAnchor((e) => (!!e ? null : ev.currentTarget))}
            size={`small`}
            style={{
              position: `sticky`,
              top: props.isStandalone ? `158px` : `56px`,
              marginLeft: `-36px`,
              zIndex: 1,
            }}
            color={`primary`}
          >
            <Box
              position={`relative`}
              style={{
                width: `24px`,
                height: `24px`,
              }}
            >
              <span className={`material-icons`}>toc</span>
            </Box>
          </Fab>
        </Tooltip>
      )}
      {!props.noTOC && (
        <TOC
          anchor={anchor}
          setAnchor={setAnchor}
          contentId={`content-${id}`}
          content={props.content}
          isStandalone={props.isStandalone}
        />
      )}
      <Box
        display={`flex`}
        padding={`0 12px`}
        flexDirection={"column"}
        height={`fit-content`}
        style={{
          marginTop: !props.noTOC ? `-49px` : `0px`,
          scrollBehavior: "smooth",
          overflow: !!props.inline ? "" : `auto`,
          overflowX: "hidden",
        }}
        className={`custom-scrollbar`}
      >
        <div
          style={{
            height: "fit-content",
            paddingLeft: "8px",
          }}
          dir="ltr"
          id={`content-${id}`}
          className={`ck ck-content`}
        >
          {props.titles.map((title, i) => (
            <>
              {!!title && <h2>{title}</h2>}
              <div dangerouslySetInnerHTML={{ __html: props.content[i] }}></div>
            </>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default EntityContentViewer;
