import {
  Dialog,
  Paper,
  Typography,
  Box,
  Divider,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Image1 from "../../../images/How_to_1.png";
import Image2 from "../../../images/How_to_2.png";
import Image3 from "../../../images/How_to_3.png";

const styles = makeStyles(() => ({
  headingText: {
    marginBottom: `-8px`,
  },
  bodyText: {
    margin: `0px 16px`,
  },
}));

const HowTo = (props: { in: boolean }) => {
  const classes = styles();

  const history = useHistory();

  return (
    <Dialog maxWidth={`md`} open={props.in} onClose={() => history.push(`/`)}>
      <Paper
        style={{
          display: `flex`,
          flexDirection: `column`,
          borderRadius: 2,
          overflow: `hidden`,
        }}
      >
        <Paper
          elevation={3}
          style={{
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            zIndex: 1,
            padding: `16px 32px`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Typography variant={`h4`} style={{ fontWeight: 300 }}>
            <>How to use the site</>
          </Typography>
          <Box>
            <Link
              to={`/`}
              style={{ display: `contents`, textDecoration: `none` }}
            >
              <IconButton size={`small`}>
                <span className={`material-icons`}>close</span>
              </IconButton>
            </Link>
          </Box>
        </Paper>
        <Box
          height={`fit-content`}
          padding={`0px 32px`}
          overflow={`auto`}
          className={`custom-scrollbar ck ck-content`}
        >
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              On the GBA Explorer site you will find the Geological and
              Bioregional Assessment (GBA) Program’s causal network assessments
              for the Cooper and Beetaloo GBA regions.
            </p>
          </Typography>
          <Typography variant={`h5`} className={`${classes.headingText}`}>
            How to assess changes to the environment and water using links and
            pathways
          </Typography>
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              For both GBA regions, GBA Explorer provides the step-by-step chain
              of events from an unconventional gas resource development scenario
              (the Driver) through to the environment or water-related values
              assessed by the Program (the Endpoints).
            </p>
            <p>
              ‘<a href={`/gba`}>About GBA Explorer</a>’ page explains the impact
              assessment method using 2 example pathways in the hypothetical
              causal network:
            </p>
            <ol>
              <li>
                Indirect pathway of low concern from the Driver to Endpoint K
              </li>
              <li>
                Direct pathway of potential concern from the Driver to Endpoint
                L
              </li>
            </ol>
            <Paper variant={`outlined`} className={`table`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ whiteSpace: `nowrap` }}>
                      Node type
                    </TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Examples</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Driver</TableCell>
                    <TableCell>
                      Major external driving forces (human or natural) that have
                      large-scale influences on natural systems
                    </TableCell>
                    <TableCell>Resource development</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Activity</TableCell>
                    <TableCell>
                      A planned event associated with unconventional gas
                      resource development
                    </TableCell>
                    <TableCell>
                      Civil construction
                      <br />
                      Transport of materials and equipment
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Stressor</TableCell>
                    <TableCell>
                      Physical, chemical or biological agent, environmental
                      condition or external stimulus caused by activities
                    </TableCell>
                    <TableCell>
                      Dust generation
                      <br />
                      Vehicle movement
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Process</TableCell>
                    <TableCell>
                      A naturally occurring mechanism that could change a
                      characteristic of an endpoint
                    </TableCell>
                    <TableCell>
                      Air pollution
                      <br />
                      Habitat degradation, fragmentation and loss
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Endpoint</TableCell>
                    <TableCell>
                      A value pertaining to water and the environment that may
                      be impacted by development activities
                    </TableCell>
                    <TableCell>
                      Aquifer condition Floodplain vegetation extent and
                      condition
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <img src={Image1} style={{ width: `100%` }} />
            <p>
              There are two primary ways to consider the effects of
              unconventional gas resource development on water and the
              environment using the GBA Explorer. They are:
            </p>
            <ul>
              <li>
                by considering the direct links from one node to another, and
              </li>
              <li>
                by considering the complete pathway from the driver –
                unconventional gas resource development – to the environment or
                water-related values to be protected (called endpoints) you are
                interested in (for example the Gouldian finch, or Cenozoic
                aquifer condition).
              </li>
            </ul>
          </Typography>
          <Typography variant={`h5`} className={`${classes.headingText}`}>
            Using pathways
          </Typography>
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              Multiple links connected through nodes show the cause-and-effect
              pathways for potential impacts on an endpoint. To fully assess the
              risk of change to the environment or water-related endpoints, it
              is important to consider the full pathway. The potential risk
              posed by any pathway is defined by the lowest risk link (think of
              it as the weakest link in the chain of events). The lowest risk
              link prevents all following links from occurring. In the first
              example pathway in the hypothetical causal network, the weakest
              links are the green links from Activity A to Stressor D and from
              Process G to Process H.
            </p>
            <img src={Image2} style={{ width: `100%` }} />
            <p>
              In the second example pathway in the hypothetical causal network,
              the weakest links are the light purple links from the Driver to
              Activity C and from Stressor F to Process I.
            </p>
            <img src={Image3} style={{ width: `100%` }} />
          </Typography>
          <Typography variant={`h5`} className={`${classes.headingText}`}>
            Using links
          </Typography>
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              Clicking on a link from one node to another allows you to
              understand the nature of the relationship between the two nodes
              (does one node increase or decrease the other). The colour of the
              link shows the likelihood, consequence – if a change is material –
              and the effectiveness of avoidance and mitigation measures on the
              relationship between nodes.
            </p>
            <p>
              For example, there is a link that evaluates whether it is possible
              for civil construction (an activity node) to increase invasive
              herbivores (a stressor node), and if so, whether the increase
              would be a material change from the baseline. Looking at
              individual links is a good way of identifying the key places where
              management, mitigation or monitoring can be applied to prevent
              potential impacts further along the pathway.
            </p>
          </Typography>
          <Typography variant={`h5`} className={`${classes.headingText}`}>
            Finding avoidance and mitigation strategies
          </Typography>
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              Avoidance and mitigation measures that can minimise or prevent
              impacts to environment or water-related endpoints can be viewed by
              clicking on an endpoint node. Mitigation and management practices
              are most effective at the activity-to-stressor step of a causal
              pathway, whereas links from stressors to natural processes and
              from natural processes to endpoints are often difficult, if not
              impossible, to mitigate.
            </p>
          </Typography>
          <Typography variant={`h5`} className={`${classes.headingText}`}>
            Finding monitoring strategies and techniques
          </Typography>
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              Monitoring techniques for each endpoint can be viewed by clicking
              on an endpoint node. Monitoring mostly applies to the processnodes
              as this is where the specific changes that cause impacts can be
              measured most effectively.
            </p>
          </Typography>
          <Typography variant={`h5`} className={`${classes.headingText}`}>
            Using the map viewer
          </Typography>
          <Typography variant={`body1`} className={`${classes.bodyText}`}>
            <p>
              The GBA Explorer shows the spatial evaluations of each link and
              each pathway.
            </p>
            <p>
              The GBA Explorer shows the spatial evaluations of each link and
              each pathway. The map viewer is not designed to be used for
              detailed spatial analytics. It should be used for indicative
              purposes only. Dedicated spatial analysis software should be used
              to perform more detailed analysis or to compare, contrast or
              overlay the Program’s results with other datasets. All of the
              maps, models and datasets used or created by the GBA Program are
              publicly available for download from{" "}
              <a href={`https://data.gov.au`}>data.gov.au</a> using the links in
              the GBA Explorer.
            </p>
            <p>
              The GBA Program’s assessment of links and pathways considers the
              varied landscapes within each region using spatial mapping. While
              the GBA Explorer presents each link in the causal network as a
              single colour (based on the maximum evaluation score for the
              link), this score may not be consistent across the region.
            </p>
            <p>
              Spatial maps for each link can be found in the ‘maps’ tab after
              clicking on the colour coded link ‘evaluation’. Similarly, maps of
              the impact assessment for each endpoint and the activities,
              stressors, processes and endpoints can be viewed through the
              ‘maps’ tab after selecting a node .
            </p>
          </Typography>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default HowTo;
