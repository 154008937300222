import React, { useEffect } from "react";
import { Slide, Snackbar, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import config from "../../config";

const Toast = (props) => {
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, props.toast.brownness ?? config.TOAST_TIMER);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.handleClose(props.toast.id);
  };

  return (
    <Slide in={open} direction="left" onExited={handleClose}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={true}
        style={{ marginBottom: props.index * 60 }}
        onClose={handleClose}
        message={!props?.toast?.severity && (props?.toast?.message || "")}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <span className={`material-icons`} style={{ fontSize: "small" }}>
                close
              </span>
            </IconButton>
          </React.Fragment>
        }
      >
        {!!props?.toast?.severity && (
          <Alert onClose={handleClose} severity={props.toast.severity}>
            {props?.toast?.message || ""}
          </Alert>
        )}
      </Snackbar>
    </Slide>
  );
};

export default Toast;
