import { makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
  arrowContainer: {
    zIndex: 0,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none"
  },
}));

const ArrowContainer = (props) => {
  const classes = styles();

  return <svg className={`${classes.arrowContainer}`}>{props.children}</svg>;
};

export default ArrowContainer;
