import {
  Card,
  Divider,
  LinearProgress,
  List,
  ListItem,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { formatDiagnosticsWithColorAndContext } from "typescript";
import RESTAPI from "../../../RESTAPI";
import Themes from "../../Themes/Themes";
import LinkItem from "../LinkItem/LinkItem";

const ExitingLinks = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [exitingLinks, setExitingLinks] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    if (!props.item) return;
    RESTAPI.GetChildrenOf([props.item.uid]).then((resp) => {
      setExitingLinks(
        (resp.docs ?? []).filter(
          (child) =>
            child.parent_links.find((link) => link.id === props.item.uid)
              ?.link_type === 0
        )
      );
      setLoading(false);
    });
  }, [props.item]);

  const getHeight = () =>
    loading ? 52 : !exitingLinks.length ? 37 : 37 * exitingLinks.length + 16;

  return (
    <Card
      style={{
        maxHeight: `${getHeight()}px`,
        minHeight: `${getHeight()}px`,
        transition: `all ${Themes.CurrentTheme().transition.short}`,
      }}
      variant="outlined"
    >
      <List>
        {loading ? (
          <Slide in={true} direction="left">
            <ListItem style={{ height: "36px" }}>
              <LinearProgress style={{ width: "100%" }} />
            </ListItem>
          </Slide>
        ) : !exitingLinks.length ? (
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginLeft: "16px", fontStyle: "italic" }}
          >
            There are no evaluations
          </Typography>
        ) : (
          exitingLinks
            .sort((a, b) => (a.title < b.title ? -1 : 1))
            .map((node, index) => (
              <>
                {index !== 0 && <Divider />}
                <LinkItem
                  isStandalone={props.isStandalone}
                  index={index}
                  item={node}
                  node={props.item}
                  history={props.history}
                  exiting={true}
                />
              </>
            ))
        )}
      </List>
    </Card>
  );
};

export default ExitingLinks;
