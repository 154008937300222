import {
  Box,
  ButtonBase,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Slide,
  TextField,
  Typography,
  Zoom,
  useTheme,
  Tooltip,
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import RESTAPI from "../../RESTAPI";
import ToolBox from "../../ToolBox";
import Themes from "../Themes/Themes";

const styles = makeStyles((theme) => ({
  listHeading: {
    "&::placeholder": {
      fontSize: ".6875rem",
      fontWeight: 700,
      textTransform: "uppercase",
      lineHeight: 2.66,
    },
  },
  searchResults: {
    overflowY: "auto",
    overflowX: "hidden",
    height: `100%`,
    pointerEvents: "all",
    borderLeft: `1px solid  ${
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.23)"
        : "rgba(0, 0, 0, 0.23)"
    }`,
    borderRight: `1px solid ${
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.23)"
        : "rgba(0, 0, 0, 0.23)"
    }`,
    borderBottom: `1px solid ${
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.23)"
        : "rgba(0, 0, 0, 0.23)"
    }`,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  outlinedPaper: {
    boxSizing: `border-box`,
    border: `1px solid ${theme.palette.divider}`,
  },
  wrapper: {
    height: `100%`,
  },
}));

const GraphSearchControls = (props) => {
  const classes = styles();

  const [searchedItems, setSearchedItems] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");

  const searchTimeoutRef = React.useRef(null);

  const [byTitle, setByTitle] = React.useState(
    window.localStorage.getItem("search-by-title") === "true"
  );

  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    window.localStorage.setItem("search-by-title", `${byTitle}`);
  }, [byTitle]);

  const handleResponse = (docs) => {
    setSearchedItems(docs);
    (props.handleSearch ?? (() => {}))(docs);
  };

  const searchDelay = (value, title) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(
      () => handleSearch(value, title),
      300
    );
  };

  const handleSearch = (value, title) => {
    setSearchTerm(value);
    value.length
      ? (title ?? byTitle
          ? RESTAPI.SearchByTitle(value)
          : RESTAPI.SearchForItem(value)
        ).then((res) => handleResponse(res.docs ?? []))
      : handleResponse(null);
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{ zIndex: 0, pointerEvents: "all", width: `360px` }}
      >
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton disabled>
                  <span className="material-icons">search</span>
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment>
                <Tooltip title={`Search only for title`} arrow>
                  <ToggleButton
                    selected={byTitle}
                    onChange={() => {
                      setByTitle((t) => !t);
                      searchDelay(searchTerm, !byTitle);
                    }}
                    size={`small`}
                    style={{
                      border: `none`,
                      transform: `scale(0.8)`,
                      backgroundColor: byTitle ? "#00293F20" : "unset",
                    }}
                  >
                    <span className={`material-icons`}>title</span>
                  </ToggleButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          inputProps={{ className: `${classes.listHeading}` }}
          placeholder="Search..."
          variant="outlined"
          style={{ width: "360px" }}
          id={`search-input`}
          onChange={(e) => searchDelay(e.currentTarget.value.trim())}
        />
      </Paper>
      <Collapse
        in={!!searchedItems}
        style={{
          width: `360px`,
          paddingBottom: `8px`,
          overflow: `hidden`,
          minHeight: !!searchedItems ? `35px` : `0`,
        }}
        classes={{ wrapper: classes.wrapper }}
      >
        <Paper className={`${classes.searchResults} custom-scrollbar`}>
          <List
            style={{
              width: "358px",
              maxHeight: "190px",
              paddingBottom: 0,
              paddingTop: "2px",
              transition: `height ${
                Themes.CurrentTheme().transition.normal
              } ease`,
              height: Math.min(searchedItems?.length || 0.6, 5) * 35,
            }}
            dense
          >
            {searchedItems?.map((item) => (
              <>
                <ListItem
                  button
                  onClick={() => ToolBox.SetDrawer(`${item.id}`, history)}
                >
                  <Box
                    position={"absolute"}
                    left="4px"
                    width="4px"
                    height="100%"
                    className={`${ToolBox.NodeColorClass(item.content_type)}`}
                  ></Box>
                  <ListItemText
                    style={{ overflow: `hidden` }}
                    primary={
                      <Typography
                        variant="caption"
                        noWrap
                        style={{ display: `block`, textOverflow: `elipsis` }}
                      >
                        {item.title}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      size="small"
                      onClick={() => ToolBox.SetDrawer(`${item.id}`, history)}
                    >
                      <span className="material-icons">chevron_right</span>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            ))}
            {!searchedItems?.length && (
              <Typography
                variant="caption"
                style={{ display: `block`, marginTop: `8px` }}
                align={`center`}
              >
                No items match search...
              </Typography>
            )}
          </List>
        </Paper>
      </Collapse>
    </>
  );
};

export default GraphSearchControls;
