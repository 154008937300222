import { ThemeOptions } from "@material-ui/core";

const theme = {
  name: "Dark",
  palette: {
    primary: {
      main: "#777777",
    },
    type: "dark",
  },
  shape: {
    borderRadius: 4,
  },
  links: (val) => {
    switch (val) {
      case 0:
        return "#237a53";
      case 1:
        return "#61b89c";
      case 2:
        return "#dadbdc";
      case 3:
        return "#b062ba";
      case 4:
        return "#6d2077";
      case 9:
        return "#a0d9e6";
      default:
        return "#FF00FF";
    }
  },
  nodes: (val) => {
    switch (val) {
      case "Driver":
        return "#bbbbbb";
      case "Activity":
        return "#f11500";
      case "Stressor":
        return "#FFa200";
      case "Process":
        return "#ffff00";
      case "Endpoint":
        return "#6bF13a";
      default:
        return "#FF00FF";
    }
  },
  transition: {
    short: "200ms",
    normal: "500ms",
    long: "1000ms",
  },
};

export default theme;
