import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ToolBox from "../../../ToolBox";
import DotButton from "../../DotButton/DotButton";
import Themes from "../../Themes/Themes";

const styles = makeStyles((theme) => ({
  text: {
    fontWeight: "700!important",
    color: theme.palette.text.secondary,
  },
}));

const PinnedListItem = (props) => {
  const classes = styles();

  const history = useHistory();

  const [hovered, setHovered] = React.useState(false);

  return (
    <ListItem
      button
      onClick={() => ToolBox.SetDrawer(`${props.item.id}`, history)}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        position={"absolute"}
        left="4px"
        width="4px"
        height="100%"
        bgcolor={Themes.CurrentTheme()?.nodes?.(props.item?.content_type)}
      ></Box>
      <ListItemText
        primary={
          <Typography noWrap variant="body2" className={`${classes.text}`}>
            {props.item.title}
          </Typography>
        }
        secondary={
          <Typography variant="caption" style={{ fontSize: `0.825rem` }}>
            {props.item.content_type}
          </Typography>
        }
      />
      {!!props.removePin && (
        <DotButton size="small" in={hovered}>
          <Tooltip arrow title={`Unpin this node.`} enterDelay={500}>
            <IconButton
              size="small"
              color="inherit"
              onClick={(e) => {
                e.stopPropagation();
                props.removePin(props.item);
              }}
            >
              <span
                className={`material-icons`}
                style={{ transform: `rotate(45deg)` }}
              >
                push_pin
              </span>
            </IconButton>
          </Tooltip>
        </DotButton>
      )}
    </ListItem>
  );
};

export default PinnedListItem;
