import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ToolBox from "ToolBox";

const styles = makeStyles((theme) => ({
  contentHeaderPaper: {
    display: `flex`,
    overflow: `hidden`,
    position: "relative",
    minHeight: `104px`,
    height: `104px`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const ContentHeading = (props) => {
  const classes = styles();

  const history = useHistory();

  console.log(props.item);

  return (
    <Paper
      elevation={3}
      className={`${classes.contentHeaderPaper}`}
      style={{ backgroundColor: `#336600` }}
    >
      <Slide
        direction="left"
        in={
          (!!props.item?.startNode?.title?.length &&
            !!props.item?.endNode?.title?.length) ||
          !!props.item?.title?.length
        }
        timeout={100}
      >
        <Box flexGrow={1} width={`calc(100% - 114px)`}>
          {!!props.isLink ? (
            <Box
              padding={`8px 32px`}
              justifyContent="flex-start"
              alignItems="center"
              overflow={`hidden`}
            >
              <Typography
                variant="h6"
                component={`h1`}
                align={"left"}
                style={{
                  width: `100%`,
                  color: `white`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  lineHeight: "20px",
                }}
              >
                {`${props.item?.startNode.title} `}{" "}
                <span style={{ fontWeight: 300, margin: `0 4px` }}>
                  {" "}
                  {`${
                    props.item?.endNode?.parent_links?.find(
                      (l) => l.id === props.item.startNode.uid
                    )?.d?.causal_effect
                  }s`.toLowerCase()}
                </span>{" "}
                {`${props.item?.endNode.title}`}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  marginTop: `8px`,
                  color: `white`,
                  fontWeight: 300,
                }}
                align="left"
              >
                {ToolBox.GetAssessment(
                  props.item?.endNode?.parent_links?.find(
                    (l) => l.id === props.item.startNode.uid
                  )?.d?.assessment_score
                )}
              </Typography>
            </Box>
          ) : (
            <Box display={`flex`} height={`100%`}>
              <Box position={`relative`} height={`100%`} maxWidth={`128px`}>
                {!!props.item?.seo_image && (
                  <img
                    alt={""}
                    src={props.item?.seo_image}
                    style={{
                      width: `128px`,
                      height: `104px`,
                      objectFit: `cover`,
                    }}
                  />
                )}
              </Box>
              <Box padding={`8px 32px`} height={`56px`}>
                <Typography
                  variant="h6"
                  component={`h1`}
                  align={"left"}
                  style={{
                    color: `white`,
                  }}
                >
                  {props.item?.title}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    marginTop: `8px`,
                    color: `white`,
                    fontWeight: 300,
                  }}
                  align="left"
                >
                  {props.item?.content_type}
                  {ToolBox.EPBC_LIST[ToolBox.CurrentTenant]?.includes(
                    props.item?.id
                  ) ? (
                    <>
                      {" - "}
                      <Tooltip
                        arrow
                        title={
                          <Typography
                            style={{
                              fontSize: `0.65rem`,
                            }}
                          >
                            Environment Protection and Biodiversity Conservation
                            Act 1999
                          </Typography>
                        }
                      >
                        <a
                          href={"https://www.environment.gov.au/epbc"}
                          style={{ textDecoration: "none", color: `white` }}
                        >
                          EPBC Act listed
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Slide>
      <Box margin={`0 0 auto auto`} padding={`8px`} display={`flex`}>
        {!props.isLink && (
          <>
            <Tooltip title={`Set as root node.`}>
              <IconButton
                size={`small`}
                style={{ marginRight: `4px` }}
                onClick={() => ToolBox.SetRoot(props.item?.id, history)}
              >
                <span className={`material-icons`} style={{ color: `white` }}>
                  room
                </span>
              </IconButton>
            </Tooltip>
            <Tooltip title={`Open as standalone page.`}>
              <Link
                to={`/${ToolBox.CurrentTenant}/items/item/${props.item?.id}/0`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  size={`small`}
                  style={{ marginRight: `4px` }}
                  onClick={() => {
                    props.resetNetwork();
                  }}
                >
                  <span className={`material-icons`} style={{ color: `white` }}>
                    open_in_full
                  </span>
                </IconButton>
              </Link>
            </Tooltip>
          </>
        )}
        <IconButton
          className={`tour-guide tour-15`}
          size={`small`}
          style={{}}
          onClick={props.handleCloseItem}
        >
          <span className={`material-icons`} style={{ color: `white` }}>
            close
          </span>
        </IconButton>
      </Box>
    </Paper>
  );
};

export default ContentHeading;
