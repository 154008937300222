import { Box, Table, TableRow, TableCell, Typography } from "@material-ui/core";
import React from "react";
import ToolBox from "ToolBox";

const NetworkArrowTooltip = (props: {
  startNode?: { title: string; [key: string]: any };
  endNode?: { title: string; [key: string]: any };
  data?: { causal_effect: string; [key: string]: any };
  [key: string]: any;
}) => {
  return (
    <Box display={`flex`}>
      <Table size={`small`}>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>From Node</TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.startNode?.title}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>To Node</TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.endNode?.title}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>Evaluation</TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {ToolBox.GetAssessment(props.data?.assessment_score)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>Relationship</TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.data?.causal_effect}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>
            Overall confidence
          </TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.data?.assessment_confidence}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>
            Confidence in evaluation
          </TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.data?.assessment_confidence_hypothesis}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>
            Confidence in materiality
          </TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.data?.assessment_confidence_materiality}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontSize: `0.8rem` }}>
            Confidence in mitigation strategies
          </TableCell>
          <TableCell style={{ fontSize: `0.8rem` }}>
            {props.data?.assessment_confidence_mitigation}
          </TableCell>
        </TableRow>
      </Table>
    </Box>
  );
};

export default NetworkArrowTooltip;
