import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slide,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import Panels from "./Panels/Panels";
import SettingsButton from "./SettingsButton/SettingsButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction={"up"} ref={ref} {...props} />;
});

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  content: {
    padding: 0,
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& .MuiTabs-root": {
        borderBottom: `1px solid ${
          theme.palette.type === "dark" ? "gray" : "lightgray"
        }`,
        "& .MuiTab-root": {
          fontSize: ".725rem",
        },
      },
    },
  },
  list: {
    minWidth: 240,
    paddingTop: 16,
    "& .MuiListItem-root": {
      borderRadius: "0 50px 50px 0",
    },
  },
}));

const SettingsPage = (props) => {
  const classes = styles();
  const theme = useTheme();
  const smMQ = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(1);

  const handleSettingsToggle = () => {
    setOpen((o) => !o);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      {false && <SettingsButton onClick={handleSettingsToggle} />}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClick={(e) => e.stopPropagation()}
        TransitionComponent={Transition}
        PaperProps={{ className: classes.root }}
        onClose={handleSettingsToggle}
      >
        <DialogTitle disableTypography>
          <Typography variant="h5" component="h2">
            Site Settings
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true} className={classes.content}>
          {smMQ ? (
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
            >
              <Tab label="Guide" />
            </Tabs>
          ) : (
            <List component="nav" className={classes.list} disablePadding>
              <ListItem
                button
                selected={tabIndex === 1}
                onClick={(event) => handleTabChange(event, 1)}
              >
                <ListItemIcon>
                  <span className={`material-icons`}>code</span>
                </ListItemIcon>
                <ListItemText>Dev</ListItemText>
              </ListItem>
            </List>
          )}
          <Box
            className={`custom-scrollbar`}
            padding={`${theme.spacing(2)}px ${theme.spacing(
              1
            )}px ${theme.spacing(2)}px ${theme.spacing(3)}px `}
            width={`100%`}
            style={{ overflowY: "scroll" }}
          >
            <Panels index={tabIndex} changeTheme={props.changeTheme} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SettingsPage;
