import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  LinearProgress,
  makeStyles,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import RESTAPI from "../../RESTAPI";
import EntityContentViewer from "../EntityContentViewer/EntityContentViewer";
import EntityViewHandler from "../EntityViewHandler";
import logo from "images/LTLogo.svg";
import CrestBaCsiroLogo from "images/Crest-BA-CSIRO.png";
import ToolBox from "../../ToolBox";

const STATUS = {
  LOADING: 0,
  DONE: 1,
  NOT_FOUND: 2,
};

const styles = makeStyles((theme) => ({
  contentBackground: {
    height: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
  },
}));

const SingleItemPage = (props) => {
  const classes = styles();

  const forceUpdate = React.useReducer(() => ({}), {})[1];

  const [status, setStatus] = React.useState(STATUS.LOADING);
  const [item, setItem] = React.useState(null);

  const [returnHome, setReturnHome] = React.useState(false);

  const { tenant, itemId } = useParams();

  const history = useHistory();

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [trigger, setTrigger] = React.useState(false);

  useEffect(() => {
    RESTAPI.GetItemFromSolr(itemId, true).then((res) => {
      if (res?.docs?.length) {
        RESTAPI.GetEntity(res?.docs?.[0].uid).then((d) => {
          setItem({ ...res?.docs?.[0], entity: d?.docs?.[0] });
          ToolBox.SetTab(0, history, true);
          setStatus(STATUS.DONE);
          process.nextTick(forceUpdate);
        });
      } else {
        setStatus(STATUS.NOT_FOUND);
      }
    });
  }, []);

  const scrollTarget = document.getElementById(`standalone-scroll-container`);

  useEffect(() => {
    console.log(scrollTarget);
    if (!scrollTarget) return;

    scrollTarget.onscroll = (e) => {
      console.log(scrollTarget.scrollTop);
      setTrigger(scrollTarget.scrollTop);
    };
  }, [scrollTarget]);

  console.log(128 - Math.max(0, trigger - 400));

  return status === STATUS.LOADING ? (
    <Box
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <LinearProgress style={{ width: "50%", marginBottom: `16px` }} />
      <Typography variant={`overline`}>Loading...</Typography>
    </Box>
  ) : status === STATUS.DONE ? (
    <Box
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Toolbar
        style={{
          transition: `height ease 200ms`,
          height: `${trigger > 512 || isCollapsed ? 64 : 104}px`,
        }}
      >
        <AppBar
          elevation={3}
          style={{
            display: `flex`,
            flexDirection: `row`,
            backgroundColor: `white`,
            transition: `height ease 200ms`,
            height: `${trigger > 512 || isCollapsed ? 64 : 104}px`,
            padding: `0 32px`,
          }}
          className={`${classes.outlinedPaper}`}
        >
          <a
            href="https://www.bioregionalassessments.gov.au/"
            style={{
              margin: `auto 0px`,
              display: `flex`,
              width: `fit-content`,
              height: `calc(100% - 8px)`,
            }}
          >
            <img
              style={{
                margin: `auto`,
                height: `calc(100% - 8px)`,
                cursor: "pointer",
              }}
              alt={`ba logo`}
              src={CrestBaCsiroLogo}
            />
          </a>
          <Typography
            variant={"h6"}
            color={`textPrimary`}
            style={{ margin: `auto 0 auto 32px`, fontSize: `1.7rem` }}
          >
            {`${ToolBox.GetTenantName()}`}
          </Typography>
          <Link
            style={{ display: `contents`, textDecoration: "none" }}
            to={`/${ToolBox.CurrentTenant}/?pins=${item.id}`}
          >
            <Button style={{ marginLeft: `auto` }} size={`small`}>
              <span className={`material-icons`}>alt_route</span>
            </Button>
          </Link>
          <Button size={`small`} onClick={() => history.push("/")}>
            <span className={`material-icons`}>home</span>
          </Button>
        </AppBar>
      </Toolbar>

      <Box
        height={`calc(100% - ${trigger > 400 || isCollapsed ? 64 : 104}px)`}
        id={`standalone-scroll-container`}
        className={`custom-scrollbar`}
        style={{ overflowY: `overlay`, overflowX: `hidden`, flexGrow: 1 }}
      >
        <Box
          position={`relative`}
          style={{
            width: `100%`,
            height: !isCollapsed && !!item.seo_image ? `512px` : `0px`,
            transition: `height 200ms ease`,
            objectFit: `cover`,
            backgroundImage: `url(${item.seo_image})`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center 32%`,
            backgroundAttachment: `fixed`,
          }}
        >
          {!!item.seo_image && false && (
            <img
              alt={""}
              src={item.seo_image}
              style={{
                width: `100%`,
                height: `400px`,
                objectFit: `cover`,
              }}
            />
          )}
        </Box>
        <Box
          display={`flex`}
          position={`sticky`}
          top={0}
          style={{
            zIndex: 1,
            background: `#336600`,
          }}
          color={`white`}
          padding={`16px 32px`}
        >
          <Box
            padding={`8px 32px`}
            height={`56px`}
            justifyContent="flex-start"
            alignItems="center"
            overflow={`hidden`}
            margin={`auto`}
          >
            <Box display={`flex`} alignItems={`flex-end`}>
              <Typography
                variant="h6"
                component={`h1`}
                align={"center"}
                style={{
                  display: `inline-block`,
                  overflow: `hidden`,
                  width: `100%`,
                  textOverflow: `ellipsis`,
                  fontSize: `2rem`,
                  marginTop: `-11px`,
                }}
                noWrap
              >
                {item.title}
              </Typography>
            </Box>
            <Typography
              align={"center"}
              variant="body1"
              style={{ marginLeft: "8px" }}
            >
              {item.content_type}
              {ToolBox.EPBC_LIST[ToolBox.CurrentTenant]?.includes(item?.id) ? (
                <>
                  {" - "}
                  <Tooltip
                    arrow
                    title={
                      <Typography
                        style={{
                          fontSize: `0.65rem`,
                        }}
                      >
                        Environment Protection and Biodiversity Conservation Act
                        1999
                      </Typography>
                    }
                  >
                    <a
                      href={"https://www.environment.gov.au/epbc"}
                      style={{ textDecoration: "none", color: `white` }}
                    >
                      EPBC Act listed
                    </a>
                  </Tooltip>
                </>
              ) : (
                ""
              )}
            </Typography>
          </Box>
        </Box>
        <Paper
          elevation={3}
          style={{
            height: `fit-content`,
            margin: `0px`,
            minHeight: `calc(100% - 104px)`,
            display: `grid`,
          }}
        >
          <Box
            display={`flex`}
            justifyContent={`center`}
            flexDirection={`column`}
            height={`100%`}
          >
            <EntityViewHandler
              setIsCollapsed={setIsCollapsed}
              isStandalone
              item={item}
              nodes={(props.network?.nodes ?? []).flat()}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  ) : status === STATUS.NOT_FOUND ? (
    <Box>
      <Typography variant="h4">404</Typography>
      <Typography variant="h6">
        The requested item could not be found
      </Typography>
    </Box>
  ) : (
    <Box>
      <Typography variant="h4" component="h1">
        An error has occured
      </Typography>
      <Button onClick={() => setReturnHome(true)}>Return Home</Button>
      {returnHome && <Redirect to={`/${tenant}`} />}
    </Box>
  );
};

export default SingleItemPage;
