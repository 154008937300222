import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import Themes from "../../Themes/Themes";

const styles = makeStyles((theme) => ({
  text: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

const GraphLegendRow = (props) => {
  const classes = styles();
  const [checked, setChecked] = React.useState(true);

  const getColour = () => {
    return !!props.isLink
      ? Themes.CurrentTheme()?.links?.(props.type)
      : Themes.CurrentTheme()?.nodes?.(props.type);
  };

  return (
    <ListItem style={{ padding: `4px 8px` }}>
      <div
        style={{
          position: `absolute`,
          left: `20px`,
          backgroundColor: getColour(),
          width: 6,
          height: `90%`,
        }}
      ></div>
      <ListItemText style={{ marginLeft: `48px` }} primary={props.title} />
    </ListItem>
  );
};

export default GraphLegendRow;
