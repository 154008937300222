import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
  controlLayer: {
    pointerEvents: "none",
  },
}));

const GraphControlLayer = (props) => {
  const classes = styles();

  return (
    <Box
      position={`fixed`}
      top={0}
      left={0}
      width={`100%`}
      height={`100%`}
      className={`${classes.controlLayer}`}
    >
      {props.children}
    </Box>
  );
};

export default GraphControlLayer;
