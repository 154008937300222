import {
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React from "react";

const EvaluationItem = (props: {
  text: React.ReactNode;
  onChange: () => void;
  defaultChecked: boolean;
  color?: string;
  count?: number;
}) => {
  return (
    <ListItem style={{ padding: `4px 8px` }}>
      <ListItemIcon style={{ position: "absolute" }}>
        <Checkbox
          defaultChecked={props.defaultChecked}
          onChange={props.onChange}
          style={{ color: props.color }}
          size={`small`}
        />
      </ListItemIcon>
      <ListItemText style={{ marginLeft: `48px` }} primary={props.text} />
    </ListItem>
  );
};

export default EvaluationItem;
