import { Box } from "@material-ui/core";
import React from "react";
import ToolBox from "../../ToolBox";
import Themes from "../Themes/Themes";

const ItemBanner = (props) => {
  return (
    <Box
      position={"absolute"}
      left={`8px`}
      top={`0`}
      height={props.height ?? `80%`}
      borderLeft={`${
        props.width ?? "16px"
      } solid ${Themes.CurrentTheme()?.nodes?.(props.node?.content_type)}`}
      borderBottom={`16px solid transparent`}
    ></Box>
  );
};

export default ItemBanner;
