import {
  Box,
  CardActionArea,
  Chip,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ToolBox from "ToolBox";
import ItemBanner from "../ItemBanner";
import NodeCardContainer from "./NodeCardContainer/NodeCardContainer";

const styles = makeStyles((theme) => ({
  cardActionArea: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    width: 408,
    height: 192,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    maxHeight: `140px`,
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    wordWrap: "break-word",
    overflowY: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 400,
  },
}));

const NodeCard = (props) => {
  const classes = styles();

  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
    (props.onMouseEnter ?? (() => {}))(props.node);
  };

  const handleMouseExit = () => {
    setHovered(false);
    (props.onMouseLeave ?? (() => {}))(props.node);
  };

  useEffect(() => {
    console.log(props.scale.current);
  }, [props.scale.current]);

  return (
    <NodeCardContainer
      hash={props.hash}
      hovered={hovered}
      node_id={props.node.uid}
      graph_id={props.graph_id}
      node={props.node}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      somehover={props.somehover}
      filtered={props.filtered}
      someFiltered={props.someFiltered}
    >
      {props.filtered && <ItemBanner node={props.node} />}
      <CardActionArea
        tabIndex={props.pinned.length && props.node.hovered ? 1 : 2}
        onClick={() => props.onClick(props.node)}
        className={`${classes.cardActionArea}`}
      >
        <Box
          display="flex"
          paddingLeft={"8px"}
          flexDirection="column"
          flexGrow={1}
          height="100%"
        >
          <Typography
            variant={`h4`}
            style={{ width: "100%" }}
            className={`${classes.titleText}`}
            align="left"
          >
            {props.node.title}
          </Typography>
        </Box>
      </CardActionArea>
      <Box
        position="absolute"
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        bottom="0"
        left="0"
        padding={"8px 0px 0px 44px"}
        width={`100%`}
        boxSizing={`border-box`}
        onClick={(e) => {
          e.stopPropagation(e);
        }}
        onMouseOver={(e) => {
          e.stopPropagation(e);
        }}
      >
        <Typography
          variant={`h4`}
          style={{
            width: "100%",
            fontWeight: 400,
            height: `fit-content`,
            display: `block`,
            fontSize: `1.75rem`,
          }}
          align="left"
        >
          {props.node.content_type}
        </Typography>
        {ToolBox.EPBC_LIST[ToolBox.CurrentTenant]?.includes(props.node.id) && (
          <Tooltip
            arrow
            title={
              <Typography
                style={{
                  fontSize: `0.65rem`,
                }}
              >
                Environment Protection and Biodiversity Conservation Act 1999
              </Typography>
            }
          >
            <a
              href={"https://www.environment.gov.au/epbc"}
              style={{ textDecoration: "none", color: `white` }}
            >
              <Chip
                style={{ background: "#336600", cursor: `pointer` }}
                label={
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: `white`,
                    }}
                  >
                    EPBC Act listed
                  </Typography>
                }
              />
            </a>
          </Tooltip>
        )}
        <Tooltip
          arrow
          title={`Click to focus on this node and pathway.`}
          enterDelay={500}
        >
          <IconButton
            className={`tour-guide ${
              props.node.id === 115 ? `tour-6` : `tour-5`
            }`}
            tabIndex={props.pinned.length && props.node.hovered ? 1 : 2}
            onClick={(e) => {
              e.stopPropagation();
              props.handlePin(props.node);
            }}
            style={{ width: `64px`, height: `64px` }}
          >
            <span
              className={`material-icons`}
              style={{
                color: props.isPinned ? "orange" : "",
                fontSize: `2rem`,
              }}
            >
              alt_route
            </span>
          </IconButton>
        </Tooltip>
      </Box>
    </NodeCardContainer>
  );
};

export default NodeCard;
