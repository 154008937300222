import { Box, CircularProgress, Divider, Tab, Tabs } from "@material-ui/core";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import RESTAPI from "../../RESTAPI";
import ToolBox from "../../ToolBox";
import ContentDrawer from "../ContentDrawer/ContentDrawer";
import ContentHeading from "../ContentHeading";
import ContentPage from "../ContentPage";
import ContentPaneLinks from "../ContentPaneLinks";
import EntityContentViewer from "../EntityContentViewer/EntityContentViewer";
import EntityViewHandler from "../EntityViewHandler";
import MapPage from "../MapPage";
import Toaster from "../Toaster";

const InfoView = (props) => {
  const [item, setItem] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  const [drawerWidth, setDrawerWidth] = React.useState(800);

  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [trigger, setTrigger] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    let docIds = props?.match?.params?.drawer;

    console.log(docIds);

    if (docIds === undefined) {
      setIsOpen(false);
      return;
    } else {
      setItem(undefined);
      setIsOpen(true);
      let ids = docIds.split(/-/g);

      Promise.all(ids.map((id) => RESTAPI.GetItemFromSolr(id, true))).then(
        (data) => {
          let items = data.map((d) => d.docs?.[0]);

          if (items.length === 1) {
            setItem(items?.[0]);

            RESTAPI.GetEntity(items[0]?.uid).then(
              (d) => {
                setItem({ ...items[0], entity: d?.docs?.[0] });
                setLoading(false);
              },
              () => {
                Toaster.makeToast(
                  `Document with ID ${
                    docIds.split(/-/g)[0]
                  } could not be found.`,
                  "error"
                );
                setIsOpen(false);
              }
            );
          } else {
            // is link
            setItem({ startNode: items[0], endNode: items[1], isLink: true });
            setLoading(false);
          }
        }
      );
    }
  }, [props?.match?.params?.drawer]);

  const hasMaps = (item) =>
    !!item?.endNode?.parent_links?.find((l) => l.id === item.startNode.uid)?.d
      ?.geojson_maps?.length || item?.geojson_maps?.length;

  return (
    <ContentDrawer open={isOpen} width={800} setDrawerWidth={setDrawerWidth}>
      <ContentHeading
        resetNetwork={props.resetNetwork}
        item={item}
        handleCloseItem={() => ToolBox.SetDrawer("", props.history)}
        isLink={!!item?.isLink}
      />
      <Box
        height={`calc(100% - ${trigger > 400 || isCollapsed ? 64 : 104}px)`}
        id={`info-scroll-container`}
        className={`custom-scrollbar`}
        style={{
          overflowY: `overlay`,
          overflowX: `hidden`,
          flexGrow: 1,
        }}
      >
        <EntityViewHandler
          item={item}
          nodes={(props.network?.nodes ?? []).flat()}
          drawerWidth={drawerWidth}
        />
      </Box>
    </ContentDrawer>
  );
};

export default InfoView;
