import { Box, CircularProgress } from "@material-ui/core";
import { getNodeText } from "@testing-library/react";
import React, { Children, useEffect } from "react";
import { useParams } from "react-router-dom";
import RESTAPI from "../../RESTAPI";
import ToolBox from "../../ToolBox";
import NodeCard from "../NodeCard";
import Toaster from "../Toaster/Toaster";
import ArrowContainer from "./ArrowContainer/ArrowContainer";
import NetworkArrow from "./NetworkArrow/NetworkArrow";
import NodeGroup from "./NodeGroup/NodeGroup";

const NetworkView = (props) => {
  const [nodes, setNodes] = React.useState([]);
  const [loading, setLoading] = React.useState(0);

  const [pinned, setPinned] = React.useState([]);
  const [openNode, setOpenNode] = React.useState(null);
  const [hash, setHash] = React.useState(null);

  const hoverTimeout = React.useRef(null);
  const hoveredNode = React.useRef();

  const [filters, setFilters] = React.useState({});

  const forceUpdate = React.useReducer(() => ({}))[1];

  useEffect(() => {
    let tenant = props?.match?.params?.tenant;
    let rIds = props?.match?.params?.rootId?.split(/-/g);

    if (!rIds?.length) {
      RESTAPI.GetAllItemsOfType("Driver").then((resp) =>
        ToolBox.SetTenant(ToolBox.CurrentTenant, props.history)
      );
      return;
    }

    if (tenant && rIds) {
      Promise.all(
        rIds.map((id) =>
          RESTAPI.GetItemFromSolr(id, true).then((r) => [r.docs?.[0], id])
        )
      ).then((data) => {
        let roots = data;

        roots
          .filter((r) => !r[0])
          .forEach((r) =>
            Toaster.makeToast(`Item with id ${r[1]} does not exist!`, "error")
          );

        roots = roots.map((r) => r[0]).filter((r) => r);

        if (roots.length) setLoading((old) => old + 1);

        fetchChildren(
          roots.map((r) => r?.uid).filter((r) => r),
          1
        ).then((items) => {
          let loaded = [
            roots.map((n) => ({ ...n, node_depth: 0, root: true })),
            items,
          ].flat();

          loaded = loaded.filter(
            (item) =>
              item.root ||
              item.parent_links === undefined ||
              item.parent_links.some(
                (link) =>
                  loaded.some((ite) => ite.uid === link.id) &&
                  link.link_type === 0
              )
          );

          let unique = [
            ...loaded.filter(
              (c, i, s) => s.findIndex((cc) => cc.uid === c.uid) === i
            ),
          ];

          unique.forEach((node) => {
            loaded
              .filter((n) => n.uid === node.uid)
              .forEach((duplicateNode) => {
                node.depth = Math.max(
                  node.node_depth,
                  duplicateNode.node_depth
                );
              });
          });

          let sorted = sortByParentPos(groupBy(unique, "depth"), 3);

          setNodes(sorted);
          let openN = sorted
            .flat()
            .find(
              (n) =>
                `${n.id}` === props.history.location.pathname.split(/\//g)[3]
            );

          setOpenNode(openN);

          setLoading((old) => old - 1);

          process.nextTick(() =>
            props.onCreation({
              pinned: pinned,
              nodes: sorted,
              unpin: handlePin,
              handlePinMultiple: handlePinMultiple,
              setFilters: handleSetFilters,
            })
          );
        });
        forceUpdate();
      });
    }
  }, [props?.match?.params?.rootId]);

  useEffect(() => {
    if (!!pinned.length) return;
    let openN = nodes
      .flat()
      .find(
        (n) => `${n.id}` === props.history.location.pathname.split(/\//g)[3]
      );
    setOpenNode(openN);

    if (!!openN) {
      handleHover(openN, true);
    } else handleHoverLeave(null, true);
  }, [props.history.location.pathname.split(/\//g)[3], pinned, loading]);

  const handleHash = React.useState(() =>
    ToolBox.debounce((hash) => {
      RESTAPI.GetItemFromSolr(hash.slice(1), true).then(
        (data) => setHash(data?.docs?.[0]),
        () => setHash(null)
      );
    })
  )[0];

  useEffect(() => {
    if (props.location.hash === "") {
      setHash(null);
    } else {
      handleHash(props.location.hash);
    }
  }, [props.location.hash]);

  const sendUpdateToParent = () => {
    (props.onUpdate ?? (() => {}))({
      pinned: pinned,
      nodes: nodes,
      unpin: handlePin,
      handlePinMultiple: handlePinMultiple,
      setFilters: handleSetFilters,
    });
  };

  useEffect(() => {
    sendUpdateToParent();
  }, [pinned]);

  const handleSetFilters = (fltrs) => {
    setFilters(fltrs);
    forceUpdate();
  };

  const fetchChildren = (parent_uids, depth) =>
    new Promise((res) =>
      RESTAPI.GetChildrenOf(parent_uids).then((children) =>
        children.numFound > 0
          ? (() => {
              return fetchChildren(
                children.docs
                  .map((c) => c.uid)
                  .flat()
                  .filter((c, i, s) => s.indexOf(c) === i),
                depth + 1
              ).then((c) =>
                res(
                  [
                    c,
                    children.docs.map((cc) => ({ ...cc, node_depth: depth })),
                  ].flat()
                )
              );
            })()
          : res([])
      )
    );

  const groupBy = (items, key) =>
    items
      .map((i) => i[key])
      .sort()
      .filter((c, i, s) => s.indexOf(c) === i)
      .map((k) => items.filter((i) => i[key] === k));

  const sortByParentPos = (groupedItems, count) => {
    groupedItems.forEach((nodeGroup, index) => {
      nodeGroup.forEach(
        (node) =>
          (node.parentRatio =
            (
              [
                node.parent_uids,
                groupedItems
                  .flat()
                  .filter((n) =>
                    (n.parent_uids ?? []).some((uid) => uid === node.uid)
                  )
                  .map((n) => n.uid),
              ].flat() || [0]
            ).reduce((z, x) => {
              return (
                z +
                (groupedItems
                  .map((g) =>
                    g
                      .map((i) => (i.uid === x ? i : undefined))
                      .map((x, i) => (!!x ? i : undefined))
                      .filter((x) => x !== undefined)
                      .map((x) => (x ?? 0) / g.length)
                  )
                  .flat()[0] ?? 0)
              );
            }, 0) /
            ([
              (node?.parent_uids ?? []).filter((p) =>
                groupedItems.flat().some((n) => n.uid === p)
              ),
              groupedItems
                .flat()
                .filter((n) =>
                  (n.parent_uids || []).some((uid) => uid === node.uid)
                )
                .map((n) => n.uid),
            ].flat()?.length ?? 1))
      );

      nodeGroup = nodeGroup.sort((a, b) => a.parentRatio - b.parentRatio);
    });

    return count === 0
      ? groupedItems
      : sortByParentPos(groupedItems, count - 1);
  };

  const handleHoverTimeout = (node, force) => {
    hoveredNode.current = node.uid;

    clearTimeout(hoverTimeout.current);

    hoverTimeout.current = setTimeout(() => handleHover(node, force), 500);
  };

  // For the purpose of hovering & pinning.
  const handleHover = (node, force) => {
    if (!!openNode && !force) return;

    if (!!pinned.length && !force) return;
    //hoverParents(node);
    //hoverChildren([node]);

    nodes.flat().forEach((n) => (n.hovered = false));
    getConnectedNodes(node).forEach((n) => (n.hovered = true));

    forceUpdate();
  };

  const hoverParents = (node) => {
    if (node.hovered) return;
    node.hovered = true;
    (node.parent_uids ?? [])
      .map((p_uid) => nodes.flat().find((n) => n.uid === p_uid))
      .filter((n) => n)
      .forEach((p) => hoverParents(p));
  };

  const hoverChildren = (a) => {
    if (!a.length) return;
    a.filter((b) => !b.hovered);
    a.forEach((b) => (b.hovered = true));

    hoverChildren(
      a
        .map((b) =>
          nodes
            .flat()
            .filter((c) => (c.parent_uids ?? []).some((d) => d === b.uid))
        )
        .flat()
    );
  };

  const handleHoverLeave = (node, force) => {
    //if (!!hoverTimeout.current && !force) return;
    //;
    if (!!openNode && !force) return;
    if (!!pinned.length) return;

    if (node?.uid === hoveredNode?.current) {
      clearTimeout(hoverTimeout.current);
      hoveredNode.current = null;
    }

    nodes.flat().filter((n) => (n.hovered = false));

    forceUpdate();
  };

  const handlePin = (node) => {
    if (pinned.some((n) => n.uid === node.uid)) {
      let pinList = pinned.filter((n) => n.uid !== node.uid);

      setPinned(pinList);
      if (pinned.length > 1) calculateMultiplePins(pinList);
      else nodes.forEach((ng) => ng.forEach((n) => (n.hovered = false)));
    } else if (!node.hovered) {
      nodes.forEach((ng) => ng.forEach((n) => (n.hovered = false)));
      handleHover(node, true);
      setPinned([node]);
      process.nextTick(() => forceUpdate());
    } else {
      nodes.forEach((ng) => ng.forEach((n) => (n.hovered = false)));

      let pinList = [...pinned, node];

      setPinned(pinList);

      if (pinned.length < 1) {
        handleHover(node, true);
      } else {
        calculateMultiplePins(pinList);
      }
    }
  };

  const handlePinMultiple = (pinned, nodes) => {
    if (!pinned.length) {
      nodes.flat().forEach((n) => (n.hovered = false));
      setPinned([]);
      return;
    }

    calculateMultiplePins(pinned, nodes);

    setPinned(pinned);
  };

  const calculateMultiplePins = (nxPinned, nds) => {
    let allPinned = nxPinned.map((n) => getConnectedNodes(n, nds));

    allPinned.map((list) =>
      list.filter((i) =>
        allPinned.every((list) => list.some((n) => n.uid === i.uid))
      )
    );

    let s = allPinned
      .map((list) =>
        list.filter((i) =>
          allPinned.every((list) => list.some((n) => n.uid === i.uid))
        )
      )?.[0]
      .filter(
        (n) =>
          nxPinned.length <= 1 ||
          (n.depth <= Math.max(...nxPinned.map((i) => i.depth)) &&
            n.depth >= Math.min(...nxPinned.map((i) => i.depth)))
      );

    s.forEach((item) => (item.hovered = true));

    (nds ?? nodes)
      .flat()
      .filter((n) => !s.some((node) => node.uid === n.uid))
      .forEach((n) => (n.hovered = false));

    process.nextTick(() => forceUpdate());
  };

  const getConnectedNodes = (n, nds) => {
    let nodesClone = JSON.parse(JSON.stringify(nds ?? nodes));

    nodesClone.forEach((n) => (n.connected = false));

    let node = nodesClone.flat().find((no) => no.uid === n.uid);

    getConnectedParents(nodesClone.flat(), node);
    getConnectedChildren(nodesClone.flat(), [node]);

    nodesClone = nodesClone.flat().filter((n) => n.connected);

    return (nds ?? nodes)
      .flat()
      .filter((node) => nodesClone.some((n) => n.uid === node.uid));
  };

  const getConnectedParents = (nodesClone, node) => {
    if (node.connected) return;
    node.connected = true;
    (node.parent_uids ?? [])
      .map((p_uid) => nodesClone.flat().find((n) => n.uid === p_uid))
      .filter((n) => n)
      .forEach((p) => getConnectedParents(nodesClone, p));
  };

  const getConnectedChildren = (nodesClone, a) => {
    if (!a.length) return;
    a.filter((b) => !b.connected);
    a.forEach((b) => (b.connected = true));

    getConnectedChildren(
      nodesClone,
      a
        .map((b) =>
          nodesClone
            .flat()
            .filter((c) => (c.parent_uids ?? []).some((d) => d === b.uid))
        )
        .flat()
    );
  };

  return !!loading ? (
    <CircularProgress />
  ) : (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding={8}
        className={`tour-guide tour-2`}
      >
        {nodes.map((depth) => (
          <NodeGroup>
            {depth
              //.filter((n) => (!!pinned ? n.hovered : true))
              .map((node) => (
                <NodeCard
                  hash={hash}
                  onMouseEnter={handleHoverTimeout}
                  onMouseLeave={handleHoverLeave}
                  node={node}
                  graph_id={props.graph_id}
                  somehover={nodes.flat().some((n) => n.hovered)}
                  handlePin={handlePin}
                  isPinned={pinned.some((n) => n.uid === node.uid)}
                  onClick={() => ToolBox.SetDrawer(`${node.id}`, props.history)}
                  pinned={pinned}
                  filtered={
                    props.filtered?.some((i) => i.uid === node.uid) ?? true
                  }
                  someFiltered={props.filtered?.length}
                  scale={props.scale}
                />
              ))}
          </NodeGroup>
        ))}
      </Box>
      <ArrowContainer graph_id={props.graph_id}>
        {loading === 0 &&
          nodes
            .flat()
            //.filter((n) => (!!pinned ? n.hovered : true))
            .map((n) =>
              (n.parent_links ?? n.parent_uids ?? []).map((p_link) => ({
                p: nodes.flat().find((nn) => nn.uid === (p_link.id ?? p_link)),
                c: n,
                score: parseInt(p_link?.d?.assessment_score),
                effect: p_link?.d?.causal_effect,
              }))
            )
            .flat()
            .filter((n) => !!n.p)
            .map((link) => (
              <NetworkArrow
                startNode={link.p}
                endNode={link.c}
                score={link.score}
                effect={link.effect}
                graph_id={props.graph_id}
                somehover={nodes.flat().some((n) => n.hovered)}
                pinned={pinned}
                nodes={nodes}
                onClick={() =>
                  ToolBox.SetDrawer(`${link.p.id}-${link.c.id}`, props.history)
                }
                filters={filters}
                typeHidden={
                  filters.evaluationTypes?.[`${link.score}`] === false
                }
              />
            ))}
      </ArrowContainer>
    </>
  );
};

export default NetworkView;
