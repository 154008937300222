import { Box, Divider, Paper, Slider, Typography } from "@material-ui/core";
import React from "react";

const evaluationConfidenceMarks = [
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
];

const FilterPane = (props: {
  onFilterChange: (filters: any) => void;
  filtersRef: any;
}) => {
  const onFilterChange = () => {
    props.onFilterChange(props.filtersRef.current);
  };

  return (
    <Box width={`360px`} overflow={`hidden`}>
      <Box padding={`8px`}>
        <Box marginBottom={`8px`}>
          <Typography
            style={{ marginLeft: `8px`, fontWeight: 300 }}
            variant={`body1`}
          >
            Overall confidence
          </Typography>
          <Box padding={`0px 32px`} boxSizing={`border-box`}>
            <Slider
              style={{ padding: `16px 0px 8px 0px`, marginTop: `-8px` }}
              min={1}
              max={3}
              onChangeCommitted={(e, value) => {
                props.filtersRef.current.overallConfidence = value as number[];
                onFilterChange();
              }}
              defaultValue={props.filtersRef.current.overallConfidence}
              marks={[
                { value: 1, label: "Low" },
                { value: 2, label: "Medium" },
                { value: 3, label: "High" },
              ]}
            />
          </Box>
          <Divider />
        </Box>
        <Box marginBottom={`8px`}>
          <Typography
            style={{ marginLeft: `8px`, fontWeight: 300 }}
            variant={`body1`}
          >
            Confidence in evaluation
          </Typography>
          <Box padding={`0px 32px`} boxSizing={`border-box`}>
            <Slider
              style={{ padding: `16px 0px 8px 0px`, marginTop: `-8px` }}
              min={1}
              max={3}
              onChangeCommitted={(e, value) => {
                props.filtersRef.current.evaluationConfidence =
                  value as number[];
                onFilterChange();
              }}
              defaultValue={props.filtersRef.current.evaluationConfidence}
              marks={[
                {
                  value: 1,
                  label: (
                    <div style={{ transform: "translateX(30%)" }}>
                      Not Evaluated
                    </div>
                  ),
                },
                { value: 2, label: "Low" },
                { value: 3, label: "High" },
              ]}
            />
          </Box>
          <Divider />
        </Box>
        <Box marginBottom={`8px`}>
          <Typography
            style={{ marginLeft: `8px`, fontWeight: 300 }}
            variant={`body1`}
          >
            Confidence in materiality
          </Typography>
          <Box padding={`0px 32px`} boxSizing={`border-box`}>
            <Slider
              style={{ padding: `16px 0px 8px 0px`, marginTop: `-8px` }}
              min={1}
              max={3}
              onChangeCommitted={(e, value) => {
                props.filtersRef.current.materialityConfidence =
                  value as number[];
                onFilterChange();
              }}
              defaultValue={props.filtersRef.current.materialityConfidence}
              marks={[
                {
                  value: 1,
                  label: (
                    <div style={{ transform: "translateX(30%)" }}>
                      Not Evaluated
                    </div>
                  ),
                },
                { value: 2, label: "Low" },
                { value: 3, label: "High" },
              ]}
            />
          </Box>
          <Divider />
        </Box>
        <Box>
          <Typography
            style={{ marginLeft: `8px`, fontWeight: 300 }}
            variant={`body1`}
          >
            Confidence in mitigation strategies
          </Typography>
          <Box padding={`0px 32px`} boxSizing={`border-box`}>
            <Slider
              style={{ padding: `16px 0px 8px 0px`, marginTop: `-8px` }}
              min={1}
              max={3}
              onChangeCommitted={(e, value) => {
                props.filtersRef.current.mitigationConfidence =
                  value as number[];
                onFilterChange();
              }}
              defaultValue={props.filtersRef.current.mitigationConfidence}
              marks={[
                {
                  value: 1,
                  label: (
                    <div style={{ transform: "translateX(30%)" }}>
                      Not Evaluated
                    </div>
                  ),
                },
                { value: 2, label: "Low" },
                { value: 3, label: "High" },
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterPane;
