import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import ToolBox from "../../../ToolBox";
import Themes from "../../Themes/Themes";

const styles = makeStyles((theme) => ({}));

const TOCListItem = (props: any) => {
  const classes = styles();

  const [childrenExpanded, setChildrenExpanded]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = React.useState((props.depth <= 1) as boolean);

  const history = useHistory();

  const [isHovered, setIsHovered] = React.useState(false);

  const handleHoverEnter = () => {
    setIsHovered(true);
    props.onHoverEnter?.();
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
    props.onHoverLeave?.();
  };

  return (
    <>
      <ListItem
        button
        style={{
          paddingTop: props.depth <= 1 ? 8 : 6,
          paddingBottom: props.depth <= 1 ? 8 : 6,
          scrollBehavior: "smooth",
        }}
        onClick={() => ToolBox.SetHash(props.item?.urlText, history)}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
      >
        <Typography
          variant={`body2`}
          style={{
            color: props.depth === 0 ? "#00537F" : "black",
            textTransform: props.depth === 0 ? "none" : "none",
            fontSize: props.depth === 0 ? "14px" : "12px",
            fontWeight: props.depth === 0 ? 700 : isHovered ? 600 : 500,
            paddingLeft: `${props.depth === 0 ? 0 : 16 * (props.depth - 1)}px`,
          }}
        >
          {props.item?.el.innerText}
        </Typography>
        {props.item?.children && (
          <ListItemSecondaryAction>
            <IconButton
              onMouseEnter={handleHoverEnter}
              onMouseLeave={handleHoverLeave}
              size={`small`}
              onClick={(e) => {
                e.stopPropagation();
                setChildrenExpanded((e) => !e);
              }}
            >
              <span
                className={`material-icons`}
                style={{
                  transform: childrenExpanded ? "rotate(180deg)" : "",
                  transitionDuration: Themes.CurrentTheme().transition.short,
                }}
              >
                expand_more
              </span>
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={childrenExpanded}>
        <Box
          style={{
            backgroundColor: `#E9F4FF`,
          }}
        >
          {(props.item?.children ?? []).map((c: any, index: number) => (
            <>
              <Divider />
              <TOCListItem
                onHoverEnter={handleHoverEnter}
                onHoverLeave={handleHoverLeave}
                item={c}
                depth={props.depth + 1}
              />
            </>
          ))}
        </Box>
      </Collapse>
    </>
  );
};

export default TOCListItem;
