import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  makeStyles,
  Paper,
  Slide,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import ToolBox from "../../ToolBox";
import Themes from "../Themes/Themes";
import EvaluationPane from "./EvaluationPane";
import FilterPane from "./FilterPane";
import GraphLegendRow from "./GraphLegendRow/GraphLegendRow";

const styles = makeStyles((theme) => ({
  legendButton: {
    pointerEvents: "all",
    minWidth: "unset",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    boxSizing: `border-box`,
    width: `32px`,
    height: `32px`,
    position: "absolute",
    bottom: "152px",
    border: `1px solid ${theme.palette.divider}`,
  },
  legendPaper: {
    pointerEvents: "all",
    border: `1px solid ${theme.palette.divider}`,
    height: "fit-content",
  },
  tab: {
    minHeight: 0,
    minWidth: 0,
  },
  tabs: {
    height: "fit-content",
    minHeight: 0,
  },
}));

const GraphLegendControls = (props) => {
  const classes = styles();

  const [tabIndex, setTabIndex] = React.useState(0);

  const [cb, setCB] = React.useState(document.body.classList.contains("cb"));
  const [expanded, setExpanded] = React.useState(true);

  return (
    <>
      <Box position="relative" width={0}>
        <Slide in={!expanded} direction="right">
          <Button
            style={{ boxSizing: `border-box`, width: `32px`, height: `32px` }}
            variant={`contained`}
            className={`${classes.legendButton}`}
            onClick={() => setExpanded(true)}
          >
            <span className="material-icons">legend_toggle</span>
          </Button>
        </Slide>
      </Box>
      <Slide in={expanded} direction="right">
        <div style={{ width: `fit-content` }}>
          <Paper
            className={`${classes.legendPaper} tour-guide tour-4`}
            style={{
              transition: `all ease ${Themes.CurrentTheme().transition.short}`,
              boxSizing: `border-box`,
              width: !expanded ? 0 : 360,
              marginLeft: expanded ? 0 : -2,
              marginRight: expanded ? `8px` : `0px`,
              position: "relative",
              height: `100%`,
              display: `flex`,
              flexDirection: `column`,
            }}
            elevation={3}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
              paddingTop="4px"
              marginRight="32px"
            >
              <Tabs
                value={tabIndex}
                onChange={(e, v) => setTabIndex(v)}
                classes={{ root: classes.tabs }}
                indicatorColor={`primary`}
              >
                <Tab
                  value={0}
                  classes={{ root: classes.tab }}
                  label={<Typography variant={`body2`}>Evaluations</Typography>}
                />
                <Tab
                  value={1}
                  classes={{ root: classes.tab }}
                  label={<Typography variant={`body2`}>Nodes</Typography>}
                />
                <Tab
                  value={2}
                  classes={{ root: classes.tab }}
                  label={<span class="material-icons">filter_list</span>}
                />
              </Tabs>
              <IconButton
                size="small"
                onClick={() => setExpanded(false)}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </Box>
            <Divider />
            <Box
              overflow={`hidden auto`}
              className={`custom-scrollbar`}
              padding="0"
              position={`relative`}
              height={
                tabIndex === 0 ? `224px` : tabIndex === 1 ? `184px` : `303px`
              }
              style={{
                transition: `height ${
                  Themes.CurrentTheme().transition.short
                } ease`,
              }}
            >
              <Fade in={tabIndex === 0}>
                <Box
                  position={tabIndex === 0 ? `relative` : `absolute`}
                  top={0}
                  left={0}
                  width={`100%`}
                  height={tabIndex === 0 ? `fit-content` : `100%`}
                  display={`flex`}
                  flexDirection={`column`}
                  style={{ overflow: "visible" }}
                  boxSizing={`border-box`}
                >
                  <EvaluationPane
                    onFilterChange={props.onFilterChange}
                    filtersRef={props.filtersRef}
                  />
                </Box>
              </Fade>
              <Fade in={tabIndex === 1}>
                <Box
                  position={tabIndex === 1 ? `relative` : `absolute`}
                  top={0}
                  left={0}
                  width={`100%`}
                  height={tabIndex === 1 ? `fit-content` : `100%`}
                  display={`flex`}
                  flexDirection={`column`}
                  style={{ overflow: "visible" }}
                  boxSizing={`border-box`}
                >
                  <Box width={`360px`} overflow={`hidden`}>
                    <Box padding={`0px 8px`}>
                      <List dense disablePadding>
                        <GraphLegendRow type={"Driver"} title={<b>Driver</b>} />
                        <Divider />
                        <GraphLegendRow
                          type={"Activity"}
                          title={<b>Activity</b>}
                        />
                        <Divider />
                        <GraphLegendRow
                          type={"Stressor"}
                          title={<b>Stressor</b>}
                        />
                        <Divider />
                        <GraphLegendRow
                          type={"Process"}
                          title={<b>Process</b>}
                        />
                        <Divider />
                        <GraphLegendRow
                          type={"Endpoint"}
                          title={<b>Endpoint</b>}
                        />
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Fade>
              <Fade in={tabIndex === 2}>
                <Box
                  position={tabIndex === 2 ? `relative` : `absolute`}
                  top={0}
                  left={0}
                  width={`100%`}
                  height={tabIndex === 2 ? `fit-content` : `100%`}
                  display={`flex`}
                  flexDirection={`column`}
                  style={{ overflow: "visible" }}
                  boxSizing={`border-box`}
                >
                  <FilterPane
                    onFilterChange={props.onFilterChange}
                    filtersRef={props.filtersRef}
                  />
                </Box>
              </Fade>
            </Box>
          </Paper>
        </div>
      </Slide>
    </>
  );
};

export default GraphLegendControls;
