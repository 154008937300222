import {
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  MobileStepper,
  Paper,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { CustomHelperProps, ReactourStep } from "reactour";

const styles = makeStyles(() => ({
  stepperRoot: {
    backgroundColor: `transparent`,
    justifyContent: `space-evenly`,
  },
}));
const TourHelper = (props: {
  currentEl: HTMLElement | null;
  helperProps: CustomHelperProps;
  delay?: number;
  requiresInteraction?: boolean;
  onClose: () => void;
}) => {
  const { current, content, totalSteps, gotoStep, close } = props.helperProps;
  const classes = styles();

  const forceUpdate = React.useReducer(() => ({}), {})[1];

  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(false);

  const animationWatcher = React.useRef(null as null | NodeJS.Timeout);

  useEffect(() => {
    if (!props.currentEl) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);

    if (props.requiresInteraction) {
      props.currentEl.onclick = () => {
        if (!!props.delay) {
          console.log("Settnig timeout");
          setIsLoading(true);
          setTimeout(() => {
            gotoStep(current + 1);
            setIsLoading(false);
          }, props.delay);
        } else {
          gotoStep(current + 1);
        }
      };
    }
  }, [props.currentEl]);

  //if (props.currentEl) {
  //  if (props.requiresInteraction) {
  //    props.currentEl.onclick = () => gotoStep(current + 1);
  //  }
  //  setTimeout(() => setIsLoading(false), props.delay ?? 0);
  //} else {
  //  // Wait for the element to become mounted
  //  setTimeout(forceUpdate, 500);
  //}
  //
  //if (props.requiresInteraction) {
  //  props.currentEl;
  //}

  //if ([1, 9, 17, 21, 24].includes(current) && !isLoading) {
  //  if (!!props.currentEl)
  //    props.currentEl.onclick = () => {
  //      if (!!props.delay) setIsLoading(true);
  //      setTimeout(() => {
  //        setIsLoading(false);
  //        gotoStep(current + 1);
  //      }, props.delay ?? 0);
  //    };
  //  else {
  //    if (!!props.delay) setIsLoading(true);
  //    setTimeout(() => {
  //      setIsLoading(false);
  //      gotoStep(current + 1);
  //    }, props.delay ?? 0);
  //  }
  //}

  return (
    <Paper
      style={{
        padding: `16px 12px 0px 12px`,
        minWidth: `256px`,
        maxWidth: `330px`,
      }}
    >
      <Box
        padding={`0px 20px 8px`}
        style={{
          maxHeight: `400px`,
          overflowY: `auto`,
          minHeight: `128px`,
        }}
        className={`custom-scrollbar`}
      >
        {isLoading ? <CircularProgress style={{ margin: `auto` }} /> : content}
      </Box>
      <Divider />
      <MobileStepper
        style={{ width: `100%`, marginTop: `8px` }}
        variant="progress"
        steps={totalSteps}
        classes={{ root: classes.stepperRoot }}
        position="static"
        activeStep={current}
        nextButton={
          <Button
            size="small"
            onClick={() => {
              if (current === props.helperProps.totalSteps - 1) {
                props.onClose();
                return;
              }
              if (!!props.delay) {
                setIsLoading(true);
                setTimeout(() => {
                  gotoStep(current + 1);
                  setIsLoading(false);
                }, props.delay);
              } else {
                gotoStep(current + 1);
              }
            }}
            disabled={props.requiresInteraction || isLoading}
          >
            {current === props.helperProps.totalSteps - 1 ? "Finish" : "Next"}
            <span className={`material-icons`}>arrow_right</span>
          </Button>
        }
        backButton={null}
      />
    </Paper>
  );
};

export default TourHelper;
