const dev = {
  query: {
    PROTOCOL: window.location.protocol.slice(0, -1),
    HOSTNAME: window.location.hostname,
    PORT: 8080,
    PATH: "/api/solr",
    HEADERS: {},
  },
  unfurl: {
    URL: `http://localhost:8080/api/unfurl`,
    HEADERS: {},
  }
};

const prod = {
  query: {
    PROTOCOL: "https",
    HOSTNAME: window.location.hostname,
    PORT: 443,
    PATH: "/api/q",
    HEADERS: {},
  },
  unfurl: {
    URL: `${window.location.origin}/api/unfurl`,
    HEADERS: {},
  },
};

const env = process.env.NODE_ENV === "production" ? prod : dev;

let config = {
  // add common config values here
  GOOGLE_MAPS_API_KEY: `AIzaSyDhzmGnAbWNzixyeJqoNHPza0h1FJpArNU`,
  TOAST_TIMER: 4000,
  MAX_ATTACHMENT_SIZE: 5000000,
  ...env,
};

export default config;
