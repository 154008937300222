import { Box, IconButton, Zoom } from "@material-ui/core";
import React from "react";

const DotButton = (props) => {
  return (
    <Box
      width={props.size === "small" ? "30px" : "46px"}
      minWidth={props.size === "small" ? "30px" : "46px"}
      height={props.size === "small" ? "30px" : "46px"}
      position={"relative"}
    >
      <Zoom in={props.in} mountOnEnter unmountOnExit>
        {props.children}
      </Zoom>
      <Zoom in={!props.in} mountOnEnter unmountOnExit>
        <div
          style={{
            width: props.size === "small" ? "30px" : "46px",
            height: props.size === "small" ? "30px" : "46px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "0",
            left: "0",
          }}
        >
          <span className="material-icons" style={{ transform: "scale(0.3)" }}>
            fiber_manual_record
          </span>
        </div>
      </Zoom>
    </Box>
  );
};

export default DotButton;
