import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React, { useEffect } from "react";
import TableOfContentsEntry from "./TableOfContentsEntry";

const TableOfContents = (props: {
  items: { [key: string]: any }[];
  widthBreakpoint: boolean;
}) => {
  const [selected, setSelected] = React.useState("");

  useEffect(() => {
    if (!!document.location.hash?.length) {
      console.log(document.location.hash);
      const el = document.querySelector(document.location.hash) as HTMLElement;
      console.log(el);

      el.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    setSelected(window.location.hash);
  }, [window.location.hash]);

  return (
    <Box
      component={`nav`}
      position={props.widthBreakpoint ? `relative` : `sticky`}
      top={`32px`}
      height={props.widthBreakpoint ? `fit-content` : 0}
      marginLeft={props.widthBreakpoint ? 0 : `-216px`}
      width={props.widthBreakpoint ? `100%` : `200px`}
    >
      <Typography variant={`overline`}>Contents</Typography>
      <List dense>
        {props.items
          .sort((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
          )
          .map((g) => ({
            title: g.title.slice(0, 1).toUpperCase(),
            href: g.title.toLowerCase().replaceAll(" ", "_"),
          }))
          .filter(
            (item, i, s) => s.map((i) => i.title).indexOf(item.title) === i
          )
          .map((t) => (
            <TableOfContentsEntry
              item={t}
              selected={selected?.[1] === t.href?.[0]}
            />
          ))}
      </List>
    </Box>
  );
};

export default TableOfContents;
