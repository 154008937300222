import http from "http";
import config from "./config";
import ToolBox from "./ToolBox";

const solr_fl_search = [
  "id",
  "uid",
  "content_type_id",
  "content_type",
  "parent_links:[json]",
  "parent_uids",
  "title",
  "seo_browser_title",
  "seo_meta_description",
  "seo_image",
  "seo_twitter_card",
  "geojson_maps:[json]",
  "named_paths:[json]",
];

function buildUrl(fl, fq, q, rows, tenant) {
  const qs = [
    `fl=${encodeURIComponent((fl ?? solr_fl_search).join(","))}`,
    `q=${encodeURIComponent(q || "*:*")}`,
    `rows=${rows ?? 100}`,
  ];
  return `${config.query.PROTOCOL}://${config.query.HOSTNAME}${
    config.query.PORT.toString().length ? ":" + config.query.PORT : ""
  }${config.query.PATH}`
    .concat(`/${tenant ?? ToolBox.CurrentTenant}-d/select`)
    .concat(`?wt=json&${qs.join("&")}`)
    .concat(
      (fq ?? []).length
        ? "&fq=" + fq.map((s) => encodeURIComponent(s)).join("&fq=")
        : ""
    );
}

function makeRequest(options, data) {
  const p = new Promise((resolve, reject) => {
    const r = http
      .request(options, (response) => {
        let data = "";
        response.on("data", (chunk) => (data += chunk));
        if (
          response.statusCode === undefined ||
          response.statusCode < 200 ||
          response.statusCode >= 400
        ) {
          reject(Error(response.statusMessage ?? "Internal server error"));
          return;
        }
        response.on("end", () =>
          resolve(
            [data === "" ? {} : JSON.parse(data)].map(
              (o) => o?.response ?? o
            )?.[0]
          )
        );
      })
      .on("error", (e) => reject(e));
    if (!!data) r.write(data);
    r.end();
  });
  return p;
}

export default class RESTAPI {
  static ALL_FIELDS_LIST = [...solr_fl_search, "*"];

  static GetChildrenOf(parent_uids, fq) {
    const fqq = [
      `parent_uids:(${parent_uids.join(" ")})`,
      ...(fq ?? ["-content_type_id:fb36d6e2-3df3-4555-b528-3e88cf060b37"]),
    ];
    const url = buildUrl(solr_fl_search, fqq);
    return makeRequest(url);
  }

  static GetItemFromSolr(uid, useIdField) {
    const fq = [`${useIdField ? "id" : "uid"}:${uid}`];
    const url = buildUrl(solr_fl_search, fq);
    return makeRequest(url);
  }

  static GetAllItemsOfTypeById(content_type_id, fl, rows, tenant) {
    const fq = [`content_type_id:"${content_type_id}"`];
    const url = buildUrl(fl ?? solr_fl_search, fq, undefined, rows, tenant);
    return makeRequest(url);
  }

  static GetAllItemsOfType(type, fl, rows, tenant) {
    const fq = [`content_type:"${type}"`];
    const url = buildUrl(fl ?? solr_fl_search, fq, undefined, rows, tenant);
    return makeRequest(url);
  }

  static SearchForItem(q) {
    const query = `${(q.match(/('.*?'|".*?"|\S+)/g) ?? []).reduce(
      (z, x) =>
        `${z}${z.length ? " AND " : ""}${
          x.match(/"|'/g)?.length % 2 === 0
            ? x
            : x.match(/"|'/g)?.length % 2 === 1
            ? `${x.replaceAll(/"|'/g, "")}*`
            : `${x}*`
        }`,
      ``
    )} AND -content_type_id:(fb36d6e2-3df3-4555-b528-3e88cf060b37 OR 84d067f0-b211-4a67-b75b-2a78b7f48b1e)`;
    const url = buildUrl(solr_fl_search, null, query, 999);
    return makeRequest(url);
  }

  static SearchByTitle = (text) => {
    const url = buildUrl(
      solr_fl_search,
      (text ?? "").split(" ").map((s) => `title:${(s ?? "").length ? s : ""}*`),
      `* AND -content_type_id:(fb36d6e2-3df3-4555-b528-3e88cf060b37 OR 84d067f0-b211-4a67-b75b-2a78b7f48b1e)`,
      999
    );
    return makeRequest(url);
  };

  static GetEntity(uid) {
    const fq = [`uid:${uid}`];
    const url = buildUrl(RESTAPI.ALL_FIELDS_LIST, fq);
    return makeRequest(url);
  }

  static Fetch(url) {
    return makeRequest(url);
  }
}
