import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import React from "react";
import ToolBox from "../../../ToolBox";

const styles = makeStyles((theme) => ({
  dividerPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const EvaluationLinks = (props) => {
  const classes = styles();

  return (
    <Box height={`fit-content`} padding={`0 16px`}>
      <Typography variant="h6" component="h2">
        Preceding node
      </Typography>
      <Card variant="outlined">
        <List>
          <Slide direction={"left"} in={true} style={{ transitionDelay: 50 }}>
            <ListItem
              button
              onClick={() =>
                ToolBox.SetDrawer(`${props.item?.startNode.id}`, props.history)
              }
            >
              {!!props.item?.startNode?.content_type && (
                <Box
                  position={"absolute"}
                  left="4px"
                  width="4px"
                  height="90%"
                  className={`${ToolBox.NodeColorClass(
                    props.item?.startNode?.content_type
                  )}`}
                ></Box>
              )}
              <Box display="flex">
                <Typography
                  variant="body2"
                  noWrap
                  style={{ marginRight: "8px" }}
                >
                  {props.item?.startNode?.title ?? "NNF"}
                </Typography>
              </Box>
            </ListItem>
          </Slide>
        </List>
      </Card>
      <Divider className={`${classes.dividerPadding}`} />
      <Typography variant="h6" component="h2">
        Following node
      </Typography>
      <Card variant="outlined">
        <List>
          <Slide direction={"left"} in={true} style={{ transitionDelay: 50 }}>
            <ListItem
              button
              onClick={() => 
                ToolBox.SetDrawer(`${props.item.endNode.id}`, props.history)}
            >
              {!!props.item?.endNode?.content_type && (
                <Box
                  position={"absolute"}
                  left="4px"
                  width="4px"
                  height="90%"
                  className={`${ToolBox.NodeColorClass(
                    props.item?.endNode?.content_type
                  )}`}
                ></Box>
              )}
              <Box display="flex">
                <Typography
                  variant="body2"
                  noWrap
                  style={{ marginRight: "8px" }}
                >
                  {props.item?.endNode?.title ?? "NNF"}
                </Typography>
              </Box>
            </ListItem>
          </Slide>
        </List>
      </Card>
    </Box>
  );
};

export default EvaluationLinks;
