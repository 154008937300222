import { Card, LinearProgress, makeStyles, useTheme } from "@material-ui/core";
import http from "http";
import React, { useEffect } from "react";
import config from "../../config";
import UnfurledSummary from "./UnfurledSummary";
import UnfurledSummaryDescription from "./UnfurledSummaryDescription";
import UnfurledSummaryLargeImage from "./UnfurledSummaryLargeImage";

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: 96,
    display: "flex",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const LinkUnfurling = (props) => {
  const classes = styles();

  const [unfurledData, setUnfurledData] = React.useState(null);
  
  const getHostname = (url) => {
    try {
      return (new URL(url)).hostname;
    } catch {
      return (url ?? "").match(/^\/.+/gi)
        ? window.location.hostname
        : undefined;
    }
  }

  useEffect(() => {
    http.get(`${config.unfurl.URL}?url=${props.link.href}`, (resp) => {
      let data = "";
      resp.on("data", (chunk) => data += chunk);
      resp.on("end", () => {
        if (resp.statusCode === undefined || resp.statusCode < 200 || resp.statusCode >= 400) {
          return;
        }
        const obj = JSON.parse(data);
        if (Object.entries(obj).length) {
          setUnfurledData({
            ...obj,
            hostname: getHostname(obj[`og:url`] ?? "")
          });
        }
      });
    });
  }, [props.link]);

  return (
    <Card
      elevation={4}
      className={`${classes.root}`}
      style={{ justifyContent: !!unfurledData ? "flex-start" : "center" }}
      onClick={() => document.getElementById("unfurled-link")?.click()}
    >
      <>
        {!!unfurledData ? (
          unfurledData[`twitter:card`] === "summary_large_image" ? (
            <UnfurledSummaryLargeImage unfurledData={unfurledData} />
          ) : unfurledData[`twitter:card`] === "summary" ? (
            <UnfurledSummary unfurledData={unfurledData} />
          ) : (
            <UnfurledSummaryDescription unfurledData={unfurledData} />
          )
        ) : (
          <LinearProgress style={{ width: 300, margin: "auto" }} />
        )}
      </>
    </Card>
  );
};

export default LinkUnfurling;
