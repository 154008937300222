import {
  Box,
  Card,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  Tab,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Themes from "../Themes/Themes";
import EvaluationLinks from "./EvaluationLinks/EvaluationLinks";
import ExitingLinks from "./ExitingLinks/ExitingLinks";
import ExpandableText from "../ExpandableText";
import LinkItem from "./LinkItem/LinkItem";

const styles = makeStyles((theme) => ({
  dividerPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const ContentPaneLinks = (props) => {
  const classes = styles();
  const history = useHistory();

  const [fromNodes, setFromNodes] = React.useState([]);
  const [toNodes, setToNodes] = React.useState([]);

  useEffect(() => {
    if (props.item?.isLink) return;

    // Get the from nodes
    setFromNodes([]);
    let from = (props.item?.parent_uids ?? [])
      .map((p_uid) => (props.nodes ?? []).find((n) => n.uid === p_uid) || p_uid)
      .sort((a, b) => (typeof a === "string" ? 1 : -1));

    // Get the to nodes

    process.nextTick(() => setFromNodes(from));
  }, [props.item]);

  return !!props.item?.isLink ? (
    <EvaluationLinks
      item={props.item}
      handleSelectedItem={props.handleSelectedItem}
      history={history}
    />
  ) : (
    <Box
      className={`tour-guide tour-13`}
      height={`fit-content`}
      paddingBottom={`16px`}
      maxWidth={`calc(min(1200px, 90%))`}
      marginLeft={`auto`}
      marginRight={`auto`}
    >
      <Box margin={`16px 8px 0 -2px`}>
        <Typography variant={`h6`}>About</Typography>
        <Box padding={`0 16px`}>
          <Typography variant={`caption`}>
            The links have been evaluated using the method defined in the{" "}
            <b>impact assessment methodology</b> (Peeters et al., 2021a) and
            described in the <b>causal network guide</b> (Peeters et al.,
            2021b). A relationship denoted as 'increases' is a 'direct
            relationship' where the 'from node' and the 'to node' (the link
            within a pathway) both increase or decrease together. A relationship
            denoted as 'decreases' is an 'inverse relationship' where the 'from
            node' increases and the 'to node' decreases, or vice versa.
          </Typography>
        </Box>
      </Box>

      <Box margin={`16px 8px 0 -2px`}>
        <Typography variant="h6" component="h2" style={{ marginTop: "16px" }}>
          Preceding evaluations
        </Typography>
        <Box padding={`0 16px`}>
          <Card
            style={{
              maxHeight: !props.item
                ? `${36 + 16}px`
                : (props.item?.parent_uids ?? []).length === 0
                ? `${37}px`
                : `${37 * fromNodes.length + 16}px`,
              minHeight: !props.item
                ? `${36 + 16}px`
                : (props.item?.parent_uids ?? []).length === 0
                ? `${37}px`
                : `${37 * fromNodes.length + 16}px`,
              transition: `all ${Themes.CurrentTheme().transition.short}`,
            }}
            variant="outlined"
          >
            <List>
              {!props.item ? (
                <Slide in={true} direction="left">
                  <ListItem style={{ height: "36px" }}>
                    <LinearProgress style={{ width: "100%" }} />
                  </ListItem>
                </Slide>
              ) : (props.item?.parent_uids ?? []).length === 0 ? (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  style={{ marginLeft: "16px", fontStyle: "italic" }}
                >
                  There are no evaluations
                </Typography>
              ) : (
                fromNodes
                  .sort((a, b) => (a.title < b.title ? -1 : 1))
                  .map((node, index) => (
                    <>
                      {index !== 0 && <Divider />}
                      <LinkItem
                        isStandalone={props.isStandalone}
                        index={index}
                        item={props.item}
                        node={node}
                        history={history}
                      />
                    </>
                  ))
              )}
            </List>
          </Card>
        </Box>
      </Box>
      <Box margin={`16px 8px 0 -2px`}>
        <Typography variant="h6" component="h2">
          Exiting evaluations
        </Typography>
        <Box padding={`0 16px`}>
          <ExitingLinks
            isStandalone={props.isStandalone}
            item={props.item}
            handleSelectedItem={props.handleSelectedItem}
            history={history}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContentPaneLinks;
