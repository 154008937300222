import { Box } from "@material-ui/core";
import React from "react";
import GraphLegendControls from "../GraphLegendControls/GraphLegendControls";
import GraphPositionControls from "../GraphPositionControls/GraphPositionControls";

const GraphLegendGroup = (props) => {
  return (
    <Box
      margin="auto 0 0 0"
      paddingBottom={`8px`}
      paddingLeft={`8px`}
      display="flex"
      overflow={`hidden`}
    >
      <GraphLegendControls
        onFilterChange={props.onFilterChange}
        filtersRef={props.filtersRef}
      />
      <GraphPositionControls
        handleZoom={props.handleZoom}
        handleCenterGraph={() => props.handleCenterGraph?.()}
        in={true}
      />
    </Box>
  );
};

export default GraphLegendGroup;
