import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ToolBox from "../../ToolBox";
import DotButton from "../DotButton/DotButton";
import PinnedListItem from "./PinnedListItem/PinnedListItem";

const styles = makeStyles((theme) => ({
  paper: {
    pointerEvents: "all",
    border: `1px solid ${theme.palette.divider}`,
    display: `flex`,
    flexDirection: `column`,
    marginBottom: "8px",
    width: `360px`,
    boxSizing: `border-box`,
    overflow: `hidden`,
    minHeight: `38px`,
  },
  listHeading: {
    fontSize: ".6875rem!important",
    fontWeight: "700!important",
    textTransform: "uppercase!important",
    color: `${theme.palette.text.secondary}!important`,
    lineHeight: "2.66!important",
  },
  headerBox: {
    boxShadow: `0px 0px ${theme.spacing(1)}px ${theme.palette.divider}`,
  },
}));

const GraphPinnedControls = (props) => {
  const classes = styles();

  const [isIn, setIsIn] = React.useState(props.in);
  const isInTimerRef = React.useRef(null);

  const [init, setInit] = React.useState(true);

  const history = useHistory();

  useEffect(() => {
    if (props.in) {
      clearTimeout(isInTimerRef.current);
      isInTimerRef.current = setTimeout(() => setIsIn(true), 500);
    } else {
      clearTimeout(isInTimerRef.current);
      setIsIn(false);
    }
  }, [props.in]);

  useEffect(() => {
    if (props?.pinnedItems?.length === 0) {
      setInit(false);
    }
    if (init) return;
    ToolBox.SetQueries(
      "pins",
      (props.pinnedItems ?? []).reduce(
        (z, p, i) => `${z}${i !== 0 ? "-" : ""}${p.id}`,
        ``
      ),
      history
    );
  }, [props.pinnedItems]);

  return (
    <Slide direction="right" in={isIn} mountOnEnter unmountOnExit>
      <Paper elevation={3} className={`${classes.paper}`}>
        <Box
          display={`flex`}
          alignItems={`center`}
          justifyContent={`center`}
          width={`100%`}
          height={`38px`}
          minHeight={`38px`}
          position={`relative`}
          className={`${classes.headerBox}`}
        >
          <Typography
            className={classes.listHeading}
            align={`center`}
            style={{ paddingTop: `4px`, paddingBottom: `4px` }}
          >
            {props.pathways.find(
              (pathway) =>
                pathway.node_ids.every((nodeId) =>
                  props.pinnedItems.some((item) => item.uid === nodeId)
                ) && pathway.node_ids.length === props.pinnedItems.length
            )?.name ?? `Pinned Items`}
          </Typography>
        </Box>
        <Divider />
        <List
          style={{
            width: "360px",
            paddingBottom: 0,
            paddingTop: 0,
            overflow: `auto`,
          }}
          className={`custom-scrollbar`}
          dense
        >
          {(props.pinnedItems ?? [])
            .sort((a, b) => (a.depth > b.depth ? 1 : -1))
            .map((item, index) => (
              <>
                <PinnedListItem
                  key={index}
                  item={item}
                  removePin={props.removePin}
                />
              </>
            ))}
        </List>
      </Paper>
    </Slide>
  );
};

export default GraphPinnedControls;
