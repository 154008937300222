import {
  Box,
  Button,
  Collapse,
  Divider,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MapTypeLegend from "components/MapTypeLegend";
import React, { useEffect } from "react";
import ToolBox from "ToolBox";
import Themes from "../../Themes/Themes";

const styles = makeStyles((theme) => ({
  tabs: {
    height: `fit-content`,
    minHeight: 0,
  },
  tab: {
    minHeight: 0,
    minWidth: 0,
  },
  description: {
    "& > p": {
      margin: `4px`,
    },
  },
}));

const ContentPageMapLegend = (props: any) => {
  const classes = styles();

  const [expanded, setExpanded] = React.useState(true);

  const [tabIndex, setTabIndex] = React.useState(0 as null | number);

  const handleTabChange = (newVal: number) => {
    setTabIndex(null);
    setTimeout(() => process.nextTick(() => setTabIndex(newVal)), 210);
  };

  return (
    <>
      <Paper
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          maxHeight: `calc(100% - 100px)`,
          minHeight: `200px`,
          minWidth: "360px",
          maxWidth: `360px`,
          display: "flex",
          flexDirection: "column",
          transition: `transform 200ms ease`,
          transform: `translateX(${expanded ? 0 : `calc(-100% + 42px)`})`,
        }}
      >
        <Box>
          <Box
            width={`100%`}
            boxSizing={`border-box`}
            overflow={`hidden`}
            height={`fit-content`}
          >
            <Box
              width={`100%`}
              display="flex"
              justifyContent={`space-between`}
              padding={"8px"}
              boxSizing={`border-box`}
            >
              <Box width={`100%`}>
                <Typography variant={`body1`}>{props.map?.name}</Typography>
              </Box>
              <Box>
                <IconButton
                  size={"small"}
                  onClick={() => setExpanded((e) => !e)}
                >
                  <span
                    className={`material-icons`}
                    style={{
                      transition: `transform 200ms ease`,
                      transform: `rotate(${expanded ? 180 : 0}deg)`,
                    }}
                  >
                    double_arrow
                  </span>
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Tabs
            value={tabIndex}
            classes={{ root: classes.tabs }}
            indicatorColor={`primary`}
            onChange={(e, v) => handleTabChange(v)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {[
              props.map?.map_type,
              props.selectedOverlays.map((o: any) => o.map_type),
            ]
              .flat()
              .filter((t, i, s) => s.indexOf(t) === i)
              .map((type, i) => (
                <Tab
                  value={i}
                  classes={{ root: classes.tab }}
                  label={type ?? `[No name]`}
                />
              ))}
            <Tab
              value={
                [
                  props.map?.map_type,
                  props.selectedOverlays.map((o: any) => o.map_type),
                ]
                  .flat()
                  .filter((t, i, s) => s.indexOf(t) === i).length
              }
              classes={{ root: classes.tab }}
              label={`Description`}
            />
          </Tabs>
          <Divider />
        </Box>
        <Box
          flexGrow={1}
          display={`flex`}
          flexDirection={`column`}
          overflow={`hidden`}
        >
          <Box
            position={`relative`}
            overflow={`auto`}
            flexGrow={1}
            className={`custom-scrollbar`}
          >
            {[
              props.map?.map_type,
              props.selectedOverlays.map((o: any) => o.map_type),
            ]
              .flat()
              .filter((t, i, s) => s.indexOf(t) === i)
              .map((type, index) => (
                <Fade
                  in={tabIndex === index}
                  mountOnEnter
                  unmountOnExit
                  timeout={200}
                >
                  <Box
                    width={`100%`}
                    height={`100%`}
                    padding={`8px`}
                    boxSizing={`border-box`}
                    style={{ overflowY: `auto` }}
                    className={`custom-scrollbar`}
                  >
                    <MapTypeLegend
                      mapType={type}
                      maps={[props.map, props.selectedOverlays]
                        .filter((m) => m)
                        .flat()}
                    />
                  </Box>
                </Fade>
              ))}
            <Fade
              in={
                tabIndex ===
                [
                  props.map?.map_type,
                  props.selectedOverlays.map((o: any) => o.map_type),
                ]
                  .flat()
                  .filter((t, i, s) => s.indexOf(t) === i).length
              }
              mountOnEnter
              unmountOnExit
              timeout={200}
            >
              <Box
                width={`100%`}
                height={`100%`}
                padding={`8px`}
                boxSizing={`border-box`}
                style={{ overflowY: `auto` }}
                className={`custom-scrollbar`}
              >
                <Typography variant={`caption`}>
                  <div
                    style={{
                      textOverflow: `ellipsis`,
                      overflow: `hidden`,
                    }}
                    className={`${classes.description}`}
                    dangerouslySetInnerHTML={{
                      __html: props.map?.description,
                    }}
                  ></div>
                </Typography>
                <Divider style={{ margin: `8px 0` }} />
                <Typography variant={`caption`}>
                  {props.map?.map_type === "Evaluation" ||
                  props.map?.map_type === "Impact" ||
                  props.map?.map_type === "Stressor heatmap" ? (
                    <>
                      The map layer(s) is derived for visualisation purposes on
                      the GBA Explorer. The complete datasets from which these
                      map layers are derived are available at{" "}
                      <a
                        href={
                          ToolBox.CurrentTenant === "bee"
                            ? `https://data.gov.au/data/dataset/f86f6b04-56b3-43b1-a622-bd70b1f9596d`
                            : `https://data.gov.au/data/dataset/f8ce4a84-531b-4afc-9aa1-bc6ad51def06`
                        }
                      >
                        data.gov.au
                      </a>
                    </>
                  ) : props.map?.map_type === "Information" ? (
                    <>
                      The map layer(s) is derived for visualisation purposes on
                      the GBA Explorer. The extent of some data layers has been
                      clipped for visualisation purposes. The dataset from which
                      the map layer(s) is derived is available from the
                      Datasource attribute, accessible by clicking on the map
                      feature.
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>
              </Box>
            </Fade>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ContentPageMapLegend;
