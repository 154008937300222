import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
  linkIcon: {
    color: theme.palette.text.secondary,
    fontSize: `20px`,
    transform: `rotate(-45deg)`,
    marginRight: theme.spacing(0.5),
  },
  linkIconNewTab: {
    color: theme.palette.text.secondary,
    fontSize: `20px`,
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
}));

const UnfurledSummaryDescription = (props) => {
  const classes = styles();

  return (
    <>
      <Box
        flexGrow={1}
        display={"flex"}
        flexDirection={`column`}
        alignItems={`flex-start`}
        textAlign={`left`}
        padding={`16px 12px`}
        boxSizing={`border-box`}
        width={`100%`}
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 600, width: `100%` }}
          noWrap
          dangerouslySetInnerHTML={{
            __html: props.unfurledData[`og:title`],
          }}
        ></Typography>
        <Typography
          variant="body2"
          style={{
            lineHeight: "20px",
            width: `100%`,
          }}
          dangerouslySetInnerHTML={{
            __html: props.unfurledData[`og:description`],
          }}
        ></Typography>
        {props.unfurledData?.hostname && (
          <Box display="flex" alignItems={`center`} style={{ opacity: 0.5, marginTop: `auto` }}>
            <span className={`${classes.linkIcon} material-icons`}>link</span>
            <Typography variant="body2">{props.unfurledData?.hostname}</Typography>
          </Box>
        )}
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={props.unfurledData[`og:url`]}
          style={{ display: "none" }}
          id={"unfurled-link"}
        >open in new window</a>
      </Box>
    </>
  );
};

export default UnfurledSummaryDescription;
