import { Box, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import Themes from "../../Themes/Themes";

const styles = makeStyles((theme) => ({
  rootNodeDrawerPaper: {
    boxSizing: `border-box`,
    transition: `all ${Themes.CurrentTheme().transition.normal} ease`,
    overflowX: `hidden`,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    pointerEvents: `all`,
  },
  innerDrawerBox: {
    overflowY: `auto`,
  },
}));

const GraphRootNodeDrawer = (props) => {
  const classes = styles();

  const [isIn, setIsIn] = React.useState(props.in);
  const inTimeout = React.useRef();

  useEffect(() => {
    if (props.in) {
      if (!!inTimeout.current) clearTimeout(inTimeout.current);
      setIsIn(true);
    } else {
      inTimeout.current = setTimeout(() => setIsIn(false), 500);
    }
  }, [props.in]);

  return (
    <Paper
      style={{
        width: `${props.in ? 300 : 0}px`,
        transform: `translate(${props.in ? 0 : -300}px, 0)`,
        maxHeight: `${props.in ? 500 : 40}px`,
      }}
      className={`${classes.rootNodeDrawerPaper}`}
      elevation={3}
    >
      <Box
        width={`300px`}
        height={`100%`}
        className={`${classes.innerDrawerBox} custom-scrollbar`}
      >
        {isIn ? props.children : null}
      </Box>
    </Paper>
  );
};

export default GraphRootNodeDrawer;
