import {
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import RESTAPI from "../../RESTAPI";
import ToolBox from "../../ToolBox";
import CrestBaCsiroLogo from "images/Crest-BA-CSIRO.png";

const styles = makeStyles((theme) => ({
  outlinedPaper: {
    boxSizing: `border-box`,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const GraphHeadingCard = (props: { [key: string]: any }) => {
  const classes = styles();
  const history = useHistory();

  const handleAssessmentOverview = () => {
    RESTAPI.GetAllItemsOfType("Summary").then((res) => {
      ToolBox.SetDrawer(`${res?.docs?.[0]?.id}`, history);
    });
  };

  return (
    <Paper
      elevation={3}
      style={{
        width: `360px`,
        zIndex: 0,
        pointerEvents: "all",
        marginBottom: "8px",
        display: `flex`,
        flexDirection: `column`,
      }}
      className={`${classes.outlinedPaper}`}
    >
      <a
        href="https://www.bioregionalassessments.gov.au/"
        style={{ display: `flex`, height: `104px` }}
      >
        <img
          style={{
            margin: `auto`,
            width: `330px`,
            cursor: "pointer",
          }}
          alt={`ba logo`}
          src={CrestBaCsiroLogo}
        />
      </a>
      <Divider />
      <Box
        padding={"5px 16px 4px 0px"}
        boxSizing={`border-box`}
        display={`flex`}
        alignItems={`center`}
      >
        <Box padding={`0px 16px`}>
          <Link to={`/`} style={{ textDecoration: "none" }}>
            <IconButton size={`small`} style={{ color: `black` }}>
              <span className={`material-icons`}>home</span>
            </IconButton>
          </Link>
        </Box>
        <Typography
          variant={"h6"}
          style={{
            fontWeight: 300,
            opacity: 1,
            fontSize: `1.5rem`,
            whiteSpace: `normal`,
          }}
        >
          {`${ToolBox.GetTenantName()}`}
        </Typography>
      </Box>
      <Divider />
      <Box display={`flex`}>
        <Box>
          <Tooltip title={`Take the site tour`}>
            <Link to={`/start_tour`} style={{ textDecoration: "none" }}>
              <Button size={`small`} style={{ color: `black` }}>
                <span className={`material-icons`}>help</span>
              </Button>
            </Link>
          </Tooltip>
        </Box>
        <Divider orientation={`vertical`} flexItem />
        <Box width={`100%`}>
          <ButtonBase
            style={{
              width: `100%`,
              height: `100%`,
              padding: `0 4px`,
            }}
            onClick={handleAssessmentOverview}
          >
            <Typography variant={"caption"} style={{ fontSize: `0.8rem` }}>
              Assessment summary
            </Typography>
            <span className={`material-icons`} style={{ fontSize: `0.84rem` }}>
              chevron_right
            </span>
          </ButtonBase>
        </Box>
      </Box>
    </Paper>
  );
};

export default GraphHeadingCard;
