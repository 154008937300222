import {
  Box,
  Card,
  CircularProgress,
  Divider,
  LinearProgress,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import ToolBox from "../../../ToolBox";
import ContentPage from "../../ContentPage";
import ContentPaneLinks from "../../ContentPaneLinks";
import EntityContentViewer from "../../EntityContentViewer/EntityContentViewer";
import MapPage from "../../MapPage";
import "styles/ckeditor.css";
import "styles/gbacolours.scss";
import EntityCitation from "components/EntityCitation";

const styles = makeStyles((theme) => ({
  selecetedTab: {
    fontWeight: 700,
  },
  tabFlexContainer: {
    justifyContent: `center`,
  },
}));

const Links = (props: {
  item: { entity?: any; [key: string]: any };
  nodes: any[];
  drawerWidth: number;
  [key: string]: any;
}) => {
  let entity = props.item.endNode?.parent_links?.find(
    (l: any) => l.id === props.item.startNode.uid
  )?.d;

  const classes = styles();
  const history = useHistory();

  return (
    <>
      <Tabs
        indicatorColor={`primary`}
        style={{
          backgroundColor: `#00293F`,
          color: `white`,
          position: !!props.isStandalone ? `sticky` : `sticky`,
          top: !!props.isStandalone ? `104px` : `0`,
          zIndex: 1,
          boxShadow: `3px 3px 5px rgba(0, 0, 0, 0.3)`,
        }}
        TabIndicatorProps={{ style: { backgroundColor: `white` } }}
        onChange={(e, v) => ToolBox.SetTab(v, history)}
        value={history.location.pathname.split(/\//g)[4] || "0"}
      >
        <Tab
          classes={{ selected: classes.selecetedTab }}
          value={"0"}
          label={`Evaluation Content`}
        />
        {!!entity?.geojson_maps?.length && <Tab value={"1"} label={`Maps`} />}
        <Tab
          classes={{ selected: classes.selecetedTab }}
          value={"2"}
          label={`Nodes`}
        />
      </Tabs>
      <Divider />
      {!!entity ? (
        <Box
          position={"relative"}
          height={`calc(100% - 49px)`}
          width={`100%`}
          marginLeft={`auto`}
          marginRight={`auto`}
        >
          <ContentPage value={"0"}>
            <EntityContentViewer
              inline
              content={[entity.html]}
              titles={[null, "Link evaluation summary"]}
            />
            <div
              className={`ck-content`}
              style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: `calc(min(1200px, 86%))`,
                minWidth: `calc(min(1200px, 86%))`,
              }}
            >
              <Card
                variant="outlined"
                style={{
                  margin: "16px",
                  marginLeft: "16px",
                }}
                className={`table`}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant={`head`} component={`th`}>
                        Evaluation
                      </TableCell>
                      <TableCell>
                        {ToolBox.GetAssessment(entity.assessment_score)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant={`head`} component={`th`}>
                        Relationship
                      </TableCell>
                      <TableCell>{entity.causal_effect}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant={`head`} component={`th`}>
                        Overall confidence
                      </TableCell>
                      <TableCell>{entity.assessment_confidence}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant={`head`} component={`th`}>
                        Confidence in evaluation
                      </TableCell>
                      <TableCell>
                        {entity.assessment_confidence_hypothesis}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant={`head`} component={`th`}>
                        Confidence in materiality
                      </TableCell>
                      <TableCell>
                        {entity.assessment_confidence_materiality}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant={`head`} component={`th`}>
                        Confidence in mitigation strategies
                      </TableCell>
                      <TableCell>
                        {entity.assessment_confidence_mitigation}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </div>
          </ContentPage>
          <ContentPage value={"1"}>
            <MapPage maps={entity.geojson_maps} />
          </ContentPage>
          <ContentPage value={"2"} scroll>
            <ContentPaneLinks item={props.item} nodes={props.nodes} />
          </ContentPage>
        </Box>
      ) : props.item.endNode?.parent_links?.find(
          (l: any) => l.id === props.item.startNode.uid
        ) ? null : (
        <LinearProgress style={{ width: `90%`, margin: `16px auto` }} />
      )}
    </>
  );
};

export default Links;
