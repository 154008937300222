import {
  AppBar,
  Box,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CrestBaCsiroLogo from "images/Crest-BA-CSIRO.png";
import RESTAPI from "RESTAPI";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ToolBox from "ToolBox";
import { isTemplateSpan } from "typescript";
import GlossaryPageItem from "./GlossaryPageItem";
import TableOfContents from "./TableOfContents";

const GlossaryPage = (props: {}) => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [glossaries, setGlossaries] = React.useState(
    [] as { [key: string]: any }[]
  );

  const theme = useTheme();
  const widthBreakpoint = useMediaQuery(theme.breakpoints.down("md"));

  const [scrollPos, setScrollPos] = React.useState(0);

  const handleHash = React.useState(() =>
    ToolBox.debounce((item: HTMLElement) => {}, 200)
  )[0];

  useEffect(() => {
    RESTAPI.GetAllItemsOfTypeById(
      "84d067f0-b211-4a67-b75b-2a78b7f48b1e",
      RESTAPI.ALL_FIELDS_LIST,
      100,
      "gba"
    ).then((data) => {
      setGlossaries(data.docs);
      setLoading(false);
    });
  }, []);

  const scrollTarget = document.getElementById(`standalone-scroll-container`);

  useEffect(() => {
    if (loading) return;

    document.getElementById(`standalone-scroll-container`)!.onscroll = (e) => {
      const items = [...document.querySelectorAll(`.content-page-item`)];

      const sorted = items.sort((a, b) =>
        Math.abs(
          a.getBoundingClientRect().top +
            a.clientHeight / 2 -
            window.innerHeight / 4
        ) <
        Math.abs(
          b.getBoundingClientRect().top +
            b.clientHeight / 2 -
            window.innerHeight / 4
        )
          ? -1
          : 1
      );

      const item = sorted[0];

      console.log(sorted);
      console.log(
        item.getBoundingClientRect().top +
          item.clientHeight / 2 -
          window.innerHeight / 2
      );

      const id = item.id;
      item.id = "";
      window.location.hash = `#${id}`;
      item.id = id;

      setScrollPos(
        document.getElementById(`standalone-scroll-container`)!.scrollTop
      );
    };
  }, [scrollTarget, loading]);

  return loading ? (
    <Box
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <LinearProgress style={{ width: "50%", marginBottom: `16px` }} />
      <Typography variant={`overline`}>Loading...</Typography>
    </Box>
  ) : (
    <Box
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Toolbar
        style={{
          transition: `height ease 200ms`,
          height: `${scrollPos > 64 ? 64 : 104}px`,
        }}
      >
        <AppBar
          elevation={3}
          style={{
            display: `flex`,
            flexDirection: `row`,
            backgroundColor: `white`,
            transition: `height ease 200ms`,
            height: `${scrollPos > 64 ? 64 : 104}px`,
            padding: `0 32px`,
            alignItems: `center`,
          }}
        >
          <a
            href="https://www.bioregionalassessments.gov.au/"
            style={{
              margin: `auto 0px`,
              display: `flex`,
              width: `fit-content`,
              height: `calc(100% - 8px)`,
            }}
          >
            <img
              style={{
                margin: `auto`,
                height: `calc(100% - 8px)`,
                cursor: "pointer",
              }}
              alt={`ba logo`}
              src={CrestBaCsiroLogo}
            />
          </a>
          <Collapse in={scrollPos > 64} style={{ marginLeft: `32px` }}>
            <Box display={`flex`} alignItems={`center`}>
              <Typography
                variant="h1"
                component={`h1`}
                align={"left"}
                style={{
                  color: `black`,
                  display: `block`,
                  overflow: `hidden`,
                  width: `100%`,
                  textOverflow: `ellipsis`,
                  transition: `transform ease 200ms`,
                  fontSize: `2.25rem`,
                }}
                noWrap
              >
                Glossary
              </Typography>
            </Box>
          </Collapse>
          <Box margin={`auto 0 auto auto`}>
            <Link
              style={{ display: `contents`, textDecoration: "none" }}
              to={`/`}
            >
              <IconButton>
                <span className={`material-icons`}>home</span>
              </IconButton>
            </Link>
          </Box>
        </AppBar>
      </Toolbar>
      <Box
        height={`calc(100% - ${scrollPos > 400 ? 64 : 104}px)`}
        id={`standalone-scroll-container`}
        className={`custom-scrollbar`}
        style={{
          overflow: `overlay`,
          overflowX: `hidden`,
          flexGrow: 1,
          scrollBehavior: `smooth`,
        }}
      >
        <Box
          display={`flex`}
          top={0}
          style={{
            zIndex: 1,
            background: `#336600`,
          }}
          color={`white`}
          padding={`8px 32px`}
        >
          <Box
            justifyContent="flex-start"
            alignItems="center"
            overflow={`hidden`}
            margin={`auto`}
            maxWidth={`calc(min(720px, 90%))`}
            minWidth={`calc(min(720px, 90%))`}
          >
            <Box display={`flex`} alignItems={`flex-end`}>
              <Typography
                variant="h1"
                component={`h1`}
                align={"left"}
                style={{
                  display: `inline-block`,
                  overflow: `hidden`,
                  width: `100%`,
                  textOverflow: `ellipsis`,
                  transition: `transform ease 200ms`,
                  fontSize: `3rem`,
                }}
                noWrap
              >
                Glossary
              </Typography>
            </Box>
          </Box>
        </Box>
        <Paper
          elevation={3}
          style={{
            height: `fit-content`,
            margin: `0px`,
            minHeight: `calc(100% - 64px)`,
            display: `grid`,
          }}
        >
          <Box display={`flex`} flexDirection={`column`} height={`100%`}>
            <Box
              margin={`0 auto`}
              padding={`32px 0 96px 0`}
              maxWidth={`calc(min(720px, 90%))`}
            >
              <Typography variant={`body1`}>
                {`For further information, refer to `}
                <a href={`http://w3id.org/gba/glossary`}>GBA Glossary</a>.
              </Typography>
              <TableOfContents
                items={glossaries}
                widthBreakpoint={widthBreakpoint}
              />
              <Box>
                {glossaries
                  .sort((a, b) =>
                    a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
                  )
                  .map((glossary, index) => (
                    <>
                      {index !== 0 && <Divider />}
                      <GlossaryPageItem glossary={glossary} />
                    </>
                  ))}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default GlossaryPage;
