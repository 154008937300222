import { Box, Divider, List, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import MapPageItem from "../MapPageItem";

const MapPageGrid = (props: any) => {
  const [isIn, setIsIn] = React.useState(props.in);

  const history = useHistory();

  useEffect(() => {
    if (props.in) {
      setIsIn(true);
    } else {
      setTimeout(() => setIsIn(false), props.maps.length * 100);
    }
  }, [props.in]);

  console.log(props.offset)

  return (
    <List style={{ marginLeft: `16px` }}>
      <Paper variant={`outlined`}>
        {props.maps.map((m: any, i: any, s: any) => (
          <>
            {i !== 0 && <Divider />}
            <MapPageItem
              map={m}
              in={props.in}
              index={i}
              totalCount={s.length}
              onClick={() => history.push(`${window.location.search}#${i + props.offset}`)}
            />
          </>
        ))}
      </Paper>
    </List>
  );
};

export default MapPageGrid;
