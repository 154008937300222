import { Box } from "@material-ui/core";
import React from "react";

const NodeGroup = (props) => {
  return (
    <Box
      margin={`0 ${150 + props.children.length * 10}px`}
      minHeight={"50%"}
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
    >
      {props.children}
    </Box>
  );
};

export default NodeGroup;
