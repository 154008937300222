import { Box, Divider, List } from "@material-ui/core";
import React from "react";
import EvaluationItem from "./EvaluationItem";

const EvaluationPane = (props: {
  onFilterChange: (filters: any) => void;
  filtersRef: any;
}) => {
  const handleEvaluationTypes = (type: string) => {
    props.filtersRef.current.evaluationTypes[type] =
      !props.filtersRef.current.evaluationTypes[type];
    onFilterChange();
  };

  const onFilterChange = () => {
    props.onFilterChange(props.filtersRef.current);
  };

  return (
    <Box width={`360px`} overflow={`hidden`}>
      <Box padding={`0px 8px`}>
        <List dense disablePadding>
          <EvaluationItem
            text={<b>Not possible</b>}
            count={(Math.random() * 20) | 0}
            defaultChecked={props.filtersRef.current.evaluationTypes["0"]}
            color={`#237a53`}
            onChange={() => handleEvaluationTypes("0")}
          />
          <Divider />
          <EvaluationItem
            text={
              <>
                <b>Possible</b> but <b>not material</b>
              </>
            }
            count={(Math.random() * 20) | 0}
            defaultChecked={props.filtersRef.current.evaluationTypes["1"]}
            color={`#61b89c`}
            onChange={() => handleEvaluationTypes("1")}
          />
          <Divider />
          <EvaluationItem
            text={
              <>
                <b>Possible</b> and <b>material</b> but can be <b>avoided</b>
              </>
            }
            count={(Math.random() * 20) | 0}
            defaultChecked={props.filtersRef.current.evaluationTypes["2"]}
            color={`#aaabac`}
            onChange={() => handleEvaluationTypes("2")}
          />
          <Divider />
          <EvaluationItem
            text={
              <>
                <b>Possible</b>, <b>material</b> and <b>unavoidable</b> but can
                be <b>mitigated</b>
              </>
            }
            count={(Math.random() * 20) | 0}
            defaultChecked={props.filtersRef.current.evaluationTypes["3"]}
            color={`#b062ba`}
            onChange={() => handleEvaluationTypes("3")}
          />
          <Divider />
          <EvaluationItem
            text={
              <>
                <b>Possible</b>, <b>material</b>, <b>unavoidable</b> and{" "}
                <b>cannot</b> be <b>mitigated</b>
              </>
            }
            count={(Math.random() * 20) | 0}
            defaultChecked={props.filtersRef.current.evaluationTypes["4"]}
            color={`#6d2077`}
            onChange={() => handleEvaluationTypes("4")}
          />
        </List>
      </Box>
    </Box>
  );
};

export default EvaluationPane;
