import React from "react";
import Activity from "./ContentTypeViews/Activity";
import Driver from "./ContentTypeViews/Driver";
import EndPoint from "./ContentTypeViews/EndPoint";
import Links from "./ContentTypeViews/Links";
import Process from "./ContentTypeViews/Process";
import Stressor from "./ContentTypeViews/Stressor";
import Glossary from "./ContentTypeViews/Glossary";
import AssessmentOverview from "./ContentTypeViews/AssessmentOverview";

const EntityViewHandler = (props: {
  item: { entity?: any; content_type: string; [key: string]: any };
  nodes: any[];
  drawerWidth: number;
  isStandalone?: boolean;
  [key: string]: any;
}) => {
  switch (props.item?.content_type) {
    case "Driver":
      return (
        <Driver
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    case "Activity":
      return (
        <Activity
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    case "Stressor":
      return (
        <Stressor
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    case "Process":
      return (
        <Process
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    case "Endpoint":
      return (
        <EndPoint
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    case "Glossary":
      return (
        <Glossary
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    case "Summary":
      return (
        <AssessmentOverview
          setIsCollapsed={props.setIsCollapsed}
          item={props.item}
          nodes={props.nodes}
          drawerWidth={props.drawerWidth}
          isStandalone={props.isStandalone}
        />
      );
    default: {
      if (props.item?.isLink)
        return (
          <Links
            setIsCollapsed={props.setIsCollapsed}
            item={props.item}
            nodes={props.nodes}
            drawerWidth={props.drawerWidth}
            isStandalone={props.isStandalone}
          />
        );
      return null;
    }
  }
};

export default EntityViewHandler;
