import Toaster from "components/Toaster";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Tour, { ReactourStep } from "reactour";
import ToolBox from "ToolBox";
import FirstVisitBanner from "./FirstVisitBanner";
import TourHelper from "./TourHelper";
import TourSteps from "./TourSteps";

const SiteTour = () => {
  const [bannerOpen, setBannerOpen] = React.useState(
    !(window.localStorage.getItem("tour-taken") === "true")
  );

  const history = useHistory();

  const [tourOpen, setTourOpen] = React.useState(false);

  const timeout = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const [tourSteps, setTourSteps] = React.useState(TourSteps);

  useEffect(() => {
    if (history.location.pathname !== `/start_tour`) return;

    history.push(`/`);
    setTimeout(() => setTourOpen(true), 1000);
  }, [history.location.pathname]);

  const handleBannerClose = () => {
    setBannerOpen(false);
    window.localStorage.setItem("tour-taken", "true");
  };

  const handleTourStart = () => {
    history.push(`/`);
    handleBannerClose();
    setTimeout(() => setTourOpen(true), 500);
  };

  const handleTourSteps = (stepNum: number) => {
    console.log(stepNum);
  };

  const handleTourClose = () => {
    window.localStorage.removeItem(`no-transition`);
    window.localStorage.setItem("tour-taken", "true");
    setTourOpen(false);
  };

  return (
    <>
      <FirstVisitBanner
        in={
          bannerOpen && !(window.localStorage.getItem("tour-taken") === "true")
        }
        onClose={() => handleBannerClose()}
        onStartTour={() => handleTourStart()}
      />
      {tourOpen && (
        <Tour
          scrollDuration={20}
          onAfterOpen={() => {
            console.log(`opened`);
            window.localStorage.setItem(`no-transition`, "true");
            handleTourSteps(0);
          }}
          isOpen={tourOpen}
          steps={tourSteps}
          onRequestClose={() => handleTourClose()}
          rounded={2}
          maskSpace={2}
          CustomHelper={(p) => (
            <TourHelper
              currentEl={
                document.querySelector(
                  tourSteps[p.current].selector ?? ""
                ) as HTMLElement | null
              }
              delay={tourSteps[p.current].delay}
              requiresInteraction={tourSteps[p.current].requiresInteraction}
              helperProps={p}
              onClose={handleTourClose}
            />
          )}
        />
      )}
    </>
  );
};

export default SiteTour;
