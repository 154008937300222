import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import EntityCitation from "components/EntityCitation";
import React from "react";
import { useHistory } from "react-router";
import ToolBox from "../../../ToolBox";
import ContentPage from "../../ContentPage";
import ContentPaneLinks from "../../ContentPaneLinks";
import EntityContentViewer from "../../EntityContentViewer/EntityContentViewer";
import MapPage from "../../MapPage";

const styles = makeStyles((theme) => ({
  selecetedTab: {
    fontWeight: 700,
  },
  tabFlexContainer: {
    justifyContent: `center`,
  },
}));

const Glossary = (props: {
  item: { entity?: any; [key: string]: any };
  nodes: any[];
  drawerWidth: number;
  isStandalone?: boolean;
  setIsCollapsed?: (collapsed: boolean) => void;
  [key: string]: any;
}) => {
  const classes = styles();
  const history = useHistory();

  return (
    <>
      <Tabs
        classes={{
          flexContainer: props.isStandalone ? classes.tabFlexContainer : "",
        }}
        indicatorColor={`primary`}
        style={{
          backgroundColor: `#00293F`,
          color: `white`,
          position: !!props.isStandalone ? `sticky` : `sticky`,
          top: !!props.isStandalone ? `104px` : `0`,
          zIndex: 1,
          boxShadow: `3px 3px 5px rgba(0, 0, 0, 0.3)`,
        }}
        TabIndicatorProps={{ style: { backgroundColor: `white` } }}
        onChange={(e, v) => ToolBox.SetTab(v, history, !!props.isStandalone)}
        value={
          history.location.pathname.split(/\//g)[!!props.isStandalone ? 5 : 4] || "0"
        }
        variant={`scrollable`}
      >
        <Tab
          onClick={() => props.setIsCollapsed?.(false)}
          classes={{ selected: classes.selecetedTab }}
          value={"0"}
          label={`${props.item?.content_type} Content`}
        />
      </Tabs>
      <Divider />
      {!!props.item.entity ? (
        <Box
          position={"relative"}
          height={`calc(100% - 49px)`}
          width={`100%`}
          marginLeft={`auto`}
          marginRight={`auto`}
        >
          <ContentPage value={"0"} isStandalone={props.isStandalone}>
            <EntityContentViewer
              inline
              isStandalone={props.isStandalone}
              noTOC
              content={[
                props.item.entity.seo_meta_description,
                props.item.entity.html,
              ]}
              titles={[null, null]}
            />
            <EntityCitation item={props.item} />
          </ContentPage>
        </Box>
      ) : (
        <LinearProgress style={{ width: `90%`, margin: `16px auto` }} />
      )}
    </>
  );
};

export default Glossary;
