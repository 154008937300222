import {
  Box,
  Divider,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import EntityCitation from "components/EntityCitation";
import React from "react";
import { useHistory } from "react-router";
import ToolBox from "../../../ToolBox";
import ContentPage from "../../ContentPage";
import ContentPaneLinks from "../../ContentPaneLinks";
import EntityContentViewer from "../../EntityContentViewer/EntityContentViewer";
import MapPage from "../../MapPage";

const styles = makeStyles((theme) => ({
  selecetedTab: {
    fontWeight: 700,
  },
  tabFlexContainer: {
    justifyContent: `center`,
  },
}));

const EndPoint = (props: {
  item: { entity?: any; [key: string]: any };
  nodes: any[];
  drawerWidth: number;
  isStandalone?: boolean;
  setIsCollapsed?: (collapsed: boolean) => void;
  [key: string]: any;
}) => {
  const classes = styles();
  const history = useHistory();

  return (
    <>
      <Tabs
        classes={{
          flexContainer: props.isStandalone ? classes.tabFlexContainer : "",
        }}
        indicatorColor={`primary`}
        style={{
          backgroundColor: `#00293F`,
          color: `white`,
          position: !!props.isStandalone ? `sticky` : `sticky`,
          top: !!props.isStandalone ? `104px` : `0`,
          zIndex: 1,
          boxShadow: `3px 3px 5px rgba(0, 0, 0, 0.3)`,
        }}
        TabIndicatorProps={{ style: { backgroundColor: `white` } }}
        onChange={(e, v) => ToolBox.SetTab(v, history, !!props.isStandalone)}
        value={
          history.location.pathname.split(/\//g)[!!props.isStandalone ? 5 : 4] || "0"
        }
        variant={`scrollable`}
      >
        <Tab
          onClick={() => props.setIsCollapsed?.(false)}
          classes={{ selected: classes.selecetedTab }}
          value={"0"}
          label={`Content`}
        />
        {!!props.item.entity?.geojson_maps?.length && (
          <Tab
            onClick={() => props.setIsCollapsed?.(true)}
            classes={{ selected: classes.selecetedTab }}
            value={"1"}
            label={`Maps`}
          />
        )}
        <Tab
          onClick={() => props.setIsCollapsed?.(true)}
          classes={{ selected: classes.selecetedTab }}
          value={"2"}
          label={`Tables`}
        />
        <Tab
          onClick={() => props.setIsCollapsed?.(true)}
          classes={{ selected: classes.selecetedTab }}
          value={"3"}
          label={`References`}
        />
      </Tabs>
      <Divider />
      {!!props.item.entity ? (
        <Box
          position={"relative"}
          height={`calc(100% - 49px)`}
          width={`100%`}
          marginLeft={`auto`}
          marginRight={`auto`}
        >
          <ContentPage value={"0"} isStandalone={props.isStandalone}>
            <EntityContentViewer
              inline
              isStandalone={props.isStandalone}
              content={[
                props.item.entity.node_description,
                props.item.entity.measurement_endpoints,
                props.item.entity.node_assessment,
                props.item.entity.node_monitoring,
                props.item.entity.node_avoidance,
                props.item.entity.knowledge_gaps,
              ]}
              titles={[null, null, null, null, null, null]}
            />
            <EntityCitation item={props.item} />
          </ContentPage>
          <ContentPage value={"1"} isStandalone={props.isStandalone}>
            <MapPage maps={props.item.entity.geojson_maps} />
          </ContentPage>
          <ContentPage value={"2"} isStandalone={props.isStandalone}>
            <EntityContentViewer
              inline
              isStandalone={props.isStandalone}
              content={[props.item.entity.node_spare]}
              titles={[null]}
            />
          </ContentPage>
          <ContentPage value={"3"} isStandalone={props.isStandalone}>
            <EntityContentViewer
              noTOC
              inline
              isStandalone={props.isStandalone}
              content={[props.item.entity.node_references]}
              titles={[null]}
            />
          </ContentPage>
        </Box>
      ) : (
        <LinearProgress style={{ width: `90%`, margin: `16px auto` }} />
      )}
    </>
  );
};

export default EndPoint;
