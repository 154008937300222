import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import Draggable from "react-draggable";
import ToolBox from "../../../ToolBox";
import Themes from "../../Themes/Themes";
import Toaster from "../../Toaster";

const SCALE_MULTIPLIER = 0.005;

const styles = makeStyles((theme) => ({
  graphContainer: {
    position: "relative",
    overflow: "hidden",
    WebkitTransform: `translate3d(0,0,0)`,
  },
  scaleListener: {},
  graphPaper: {
    display: "flex",
    transition: `all ${Themes.CurrentTheme().transition.normal}`,
    backgroundColor: theme.palette.background.default,
  },
  controlsContainer: {
    zIndex: 3,
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
  controlsScaleSlider: {
    width: "200px",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    minWidth: "unset",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
  },
  graph: {
    transform: `translateZ(0)`,
    margin: "auto",
    display: "flex",
    height: "fit-content",
    width: "fit-content",
    position: "relative",
  },
  nodeGroup: {
    width: "750px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    flexWrap: "wrap-reverse",
    alignItems: "center",
  },
  searchResults: {
    maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 3,
    width: "300px",
    overflowX: "hidden",
  },
  searchItem: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },
  searchItemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  listContainer: {
    flexGrow: 1,
    overflowY: "auto",
  },
  rootChip: {
    marginTop: theme.spacing(0.5),
    maxWidth: "100%",
  },
  multiselectHint: {
    position: "sticky",
    bottom: "0",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Graph = (props) => {
  const graph_id = React.useState(`graph_container_${ToolBox.uuidv4()}`)[0];

  const graph_width = React.useState(props.graphWidth ?? 10000)[0];
  const graph_height = React.useState(props.graphHeight ?? 10000)[0];
  const scale_min = React.useState(props.scaleMin ?? 0.1)[0];
  const scale_max = React.useState(props.scaleMax ?? 2.5)[0];

  const draggableRef = React.useRef();
  const scaleTimeoutRef = React.useRef();

  const [dragging, setDragging] = React.useState(false);

  const [position] = React.useState(undefined);

  let scale = React.useRef(1.0);

  const classes = styles();

  useEffect(() => {
    let centerPos = getCenterPos();
    draggableRef.current.setState((state) => ({
      ...state,
      x: centerPos.x,
      y: centerPos.y,
    }));

    // Wheel Scrolling
    let scaleListener = document.getElementById(`scale-listener-${graph_id}`);

    scaleListener.onwheel = (e) => {
      scaleListener.style.transition = `all ${
        Themes.CurrentTheme().transition.normal
      } ease`;
      let delta = e.deltaY * -(Math.pow(scale.current, 1.5) * SCALE_MULTIPLIER);

      let container = document
        .getElementById(`graph-viewport-${graph_id}`)
        ?.getBoundingClientRect();

      let mousePosInView = {
        x: e.clientX - container.x,
        y: e.clientY - container.y,
      };

      let centerPos = getCenterPos(mousePosInView);

      let dragDelta = {
        x: draggableRef.current.state.x - centerPos.x,
        y: draggableRef.current.state.y - centerPos.y,
      };

      console.log(centerPos);

      draggableRef.current.setState((state) => ({
        ...state,
        x:
          (dragDelta.x / scale.current) *
            Math.min(Math.max(scale.current + delta, scale_min), scale_max) +
          centerPos.x,
        y:
          (dragDelta.y / scale.current) *
            Math.min(Math.max(scale.current + delta, scale_min), scale_max) +
          centerPos.y,
      }));

      let scaleContainer = document.getElementById(`graph-scale-${graph_id}`);

      scale.current = Math.min(
        Math.max(scale.current + delta, scale_min),
        scale_max
      );

      scaleContainer.style.transform = `scale(${scale.current.toFixed(2)})`;

      clearTimeout(scaleTimeoutRef.current);
      scaleTimeoutRef.current = setTimeout(
        () => (scaleListener.style.transition = ``),
        500
      );
    };

    process.nextTick(() => {
      if (!!props.onCreation) {
        props.onCreation({
          graph_id: graph_id,
          handleCenterGraph,
          handleMoveGraphBy,
          handleZoom,
          scale: scale,
        });
      }
      //handleCenterGraph();
    });

    return () => props.onUnmount();
  }, []);

  const handleZoom = (delta) => {
    let scaleListener = document.getElementById(`scale-listener-${graph_id}`);

    scaleListener.style.transition = `all ${
      Themes.CurrentTheme().transition.normal
    } ease`;

    let centerPos = getCenterPos();

    let dragDelta = {
      x: draggableRef.current.state.x - centerPos.x,
      y: draggableRef.current.state.y - centerPos.y,
    };

    draggableRef.current.setState((state) => ({
      ...state,
      x:
        (dragDelta.x / scale.current) *
          Math.min(Math.max(scale.current + delta, scale_min), scale_max) +
        centerPos.x,
      y:
        (dragDelta.y / scale.current) *
          Math.min(Math.max(scale.current + delta, scale_min), scale_max) +
        centerPos.y,
    }));

    let scaleContainer = document.getElementById(`graph-scale-${graph_id}`);

    scale.current = Math.min(
      Math.max(scale.current + delta, scale_min),
      scale_max
    );

    scaleContainer.style.transform = `scale(${scale.current})`;

    clearTimeout(scaleTimeoutRef.current);
    scaleTimeoutRef.current = setTimeout(
      () => (scaleListener.style.transition = ``),
      500
    );
  };

  const getViewport = (graphId) =>
    document.getElementById(`graph-viewport-${graphId}`);

  const halfGraphWidth = () => graph_width / 2;
  const halfGraphHeight = () => graph_height / 2;

  const getCenterPos = (mousePos) => {
    let viewport = getViewport(graph_id);

    let viewportRect = viewport?.getBoundingClientRect();

    let halfVPWidth = (viewportRect?.width - 360) / 2 + 360;
    let halfVPHeight = viewportRect?.height / 2;

    let halfWidth = mousePos?.x ?? halfVPWidth;
    let halfHeight = mousePos?.y ?? halfVPHeight;

    return {
      x: -(halfGraphWidth() - halfWidth),
      y: -(halfGraphHeight() - halfHeight),
    };
  };

  const handleCenterGraph = () => {
    let scaleListener = document.getElementById(`scale-listener-${graph_id}`);

    if (!scaleListener) return;

    scaleListener.style.transition = `all ${
      Themes.CurrentTheme().transition.normal
    } ease`;

    let centerPos = getCenterPos();
    draggableRef.current.setState((state) => ({
      ...state,
      x: centerPos.x,
      y: centerPos.y,
    }));

    let graph = document.getElementById(`graph-${graph_id}`);
    let graphSize = graph.getBoundingClientRect();

    let viewPort = getViewport(graph_id);
    let viewPortSize = viewPort.getBoundingClientRect();

    let scaleX = (viewPortSize.width - 360) / (graphSize.width / scale.current);
    let scaleY = viewPortSize.height / (graphSize.height / scale.current);

    let initScale = Math.max(
      Math.min(Math.min(scaleX, scaleY), 1.0),
      scale_min
    );

    scale.current = initScale;

    let scaleContainer = document.getElementById(`graph-scale-${graph_id}`);

    scaleContainer.style.transform = `scale(${scale.current})`;

    clearTimeout(scaleTimeoutRef.current);
    scaleTimeoutRef.current = setTimeout(
      () => (scaleListener.style.transition = ``),
      500
    );
  };

  const handleMoveGraphBy = (amount) => {
    let scaleListener = document.getElementById(`scale-listener-${graph_id}`);

    scaleListener.style.transition = `all ${
      Themes.CurrentTheme().transition.normal
    } ease`;

    draggableRef.current.setState((state) => ({
      ...state,
      x: state.x + amount?.x ?? 0,
      y: state.y + amount?.y ?? 0,
    }));

    scaleTimeoutRef.current = setTimeout(
      () => (scaleListener.style.transition = ``),
      500
    );
  };

  const handleCenterGraphOnItem = (item) => {
    let element = document.getElementById(`${graph_id}_node_${item.uid}`);

    let container = document.getElementById(`graph-${graph_id}`);

    let elLoc = {
      x: element.offsetLeft + element.clientWidth / 2,
      y: element.offsetTop + element.clientHeight / 2,
    };

    let center = {
      x: container.clientWidth / 2,
      y: container.clientHeight / 2,
    };

    let offset = {
      x: elLoc.x - center.x,
      y: elLoc.y - center.y,
    };

    handleCenterGraph(offset);
  };

  return (
    <Box
      id={`graph-viewport-${graph_id}`}
      className={classes.graphContainer}
      width={props.width ?? "100%"}
      height={props.height ?? "100%"}
      flexGrow={props.flexGrow ?? 0}
    >
      <Draggable
        disabled={!!position}
        axis={!!position ? "none" : "both"}
        position={position}
        ref={draggableRef}
        onStart={() => setDragging(true)}
        onStop={() => setDragging(false)}
      >
        <div
          id={`scale-listener-${graph_id}`}
          className={`${classes.scaleListener}`}
        >
          <Box
            width={graph_width}
            height={graph_height}
            className={`${classes.graphPaper}`}
            id={`graph-scale-${graph_id}`}
            style={{ cursor: dragging ? "grabbing" : "grab" }}
          >
            <div className={classes.graph} id={`graph-${graph_id}`}>
              {props.children}
            </div>
          </Box>
        </div>
      </Draggable>
    </Box>
  );
};

export default Graph;
