import {
  Box,
  Card,
  CardActionArea,
  makeStyles,
  useTheme,
  Tooltip,
  Typography,
  Divider,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import ToolBox from "../../../ToolBox";
import Themes from "../../Themes/Themes";
import NodeCardIndicator from "../NodeCardIndicator/NodeCardIndicator";

const styles = makeStyles((theme) => ({
  nodeCardContainer: {
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    padding: 0,
    width: "fit-content",
    height: "fit-content",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 3,
    position: "relative",
  },
  nodeCardHover: {
    "&:hover": {
      transform: `scale(2.5, 2.5)`,
      opacity: `1 !important`,
      transitionDelay: `250ms !important`,
      transitionDuration: `200ms !important`,
      zIndex: 10000,
    },
  },
  selected: {
    boxShadow: `0 0 20px ${theme.palette.text.primary}`,
  },
}));

const NodeCardContainer = (props) => {
  const classes = styles();
  const id = React.useState(`${props.graph_id}_node_${props.node_id}`)[0];

  const history = useHistory();

  const theme = useTheme();

  return (
    <Card
      id={id}
      className={`${classes.nodeCardContainer} ${
        history.location.pathname.split(/\//g)[3] === `${props.node.id}`
          ? classes.selected
          : ""
      } ${
        window.localStorage.getItem(`no-transition`) === "true"
          ? ""
          : classes.nodeCardHover
      } tour-guide ${props.node.id === 107 ? `tour-7` : ""}`}
      style={{
        transition: `all ${Themes.CurrentTheme().transition.short}`,
        opacity: props.somehover ? (props.node.hovered ? 1 : 0.3) : 1,
        backgroundColor:
          props.someFiltered && props.filtered
            ? `rgba(255, 255, 200, 1)`
            : theme.palette.background.paper,
      }}
      elevation={props.node.hovered ? 10 : 3}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </Card>
  );
};

export default NodeCardContainer;
