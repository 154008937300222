import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { defaultsDeep } from "lodash";
import React, { useEffect } from "react";
import RESTAPI from "../../RESTAPI";
import ToolBox from "../../ToolBox";
import Themes from "../Themes/Themes";
import GraphRootNodeDrawer from "./GraphRootNodeDrawer";

const styles = makeStyles((theme) => ({
  buttonSpacingBox: {
    transition: `width ${Themes.CurrentTheme().transition.normal} ease`,
    overflow: `visible`,
  },
  openDrawerButton: {
    margin: `auto`,
    pointerEvents: `all`,
  },
  headerBox: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  closeDrawerButton: {
    margin: `auto ${theme.spacing(0)}px auto auto`,
  },
  headerText: {
    color: theme.palette.text.secondary,
    fontSize: `0.725rem`,
    marginLeft: theme.spacing(2),
    textTransform: `uppercase`,
    fontWeight: 700,
  },
  noItemsMessage: {
    fontStyle: `italic`,
  },
  stickyDivider: {
    position: `sticky`,
    top: `38px`,
  },
  itemText: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
}));

const GraphRootNodeSelector = (props) => {
  const classes = styles();

  const [open, setOpen] = React.useState(false);
  const [selectedRoots, setSelectedRoots] = React.useState([]);

  const [disable, setDisable] = React.useState(false);

  useEffect(() => {
    let rIds = props?.match?.params?.rootId?.split(/-/g).filter((i) => i);

    if (!!rIds?.length) setOpen(false);
    else setOpen(true);
  }, []);

  useEffect(() => {
    if (disable) {
      setDisable(false);
      return;
    }
    let rIds = props?.match?.params?.rootId?.split(/-/g);

    Promise.all(
      (rIds ?? []).map((id) => RESTAPI.GetItemFromSolr(id, true))
    ).then((data) => {
      let roots = data.map((d) => d.docs?.[0]);
      setSelectedRoots(roots ?? []);
    });
  }, [props?.match?.params?.rootId]);

  const setLocation = React.useState(() =>
    ToolBox.debounce((string) => {
      setDisable(true);
      //ToolBox.SetLocation(`/bee/${string}`, props.history)
      ToolBox.SetRoot(string, props.history);
    }, 500)
  )[0];

  const handleRootItem = (e, item) => {
    let tenant = props?.match?.params?.tenant;

    if (selectedRoots.some((n) => n?.uid === item?.uid)) {
      let SRs = selectedRoots.filter((n) => n.uid !== item.uid);
      setSelectedRoots(SRs);
      setLocation(`${SRs.map((r) => r.id).join("-")}`);
    } else {
      setSelectedRoots(
        e.ctrlKey || e.metaKey ? [...selectedRoots, item] : [item]
      );
      setLocation(
        `${(e.ctrlKey || e.metaKey
          ? [...selectedRoots.map((r) => r.id), item.id]
          : [item.id]
        ).join("-")}`
      );
    }
  };

  return (
    <>
      <Box
        width={open ? `0px` : `30px`}
        height={`40px`}
        className={`${classes.buttonSpacingBox}`}
        display={`flex`}
      >
        <Slide direction={`right`} in={!open}>
          <IconButton
            className={`${classes.openDrawerButton}`}
            size="small"
            onClick={() => setOpen((o) => !o)}
          >
            <span className={`material-icons`}>chevron_right</span>
          </IconButton>
        </Slide>
      </Box>
      <GraphRootNodeDrawer in={open}>
        <Box
          height={`38px`}
          width={`100%`}
          display={`flex`}
          alignItems={`center`}
          position={`sticky`}
          top={0}
          className={`${classes.headerBox}`}
        >
          <Typography variant={`body1`} className={`${classes.headerText}`}>
            Select a root node...
          </Typography>
          <IconButton
            size="small"
            className={`${classes.closeDrawerButton}`}
            onClick={() => setOpen((o) => !o)}
          >
            <span className={`material-icons`}>chevron_left</span>
          </IconButton>
        </Box>
        <Divider className={`${classes.stickyDivider}`} />
        <List dense disablePadding>
          {(props.rootNodes ?? []).map((node, index) => (
            <>
              {!!index && <Divider />}
              <ListItem
                button
                selected={selectedRoots.some((n) => n?.uid == node?.uid)}
                onClick={(e) => handleRootItem(e, node)}
              >
                <Box
                  position={"absolute"}
                  left="4px"
                  width="4px"
                  height="100%"
                  bgcolor={Themes.CurrentTheme()?.nodes?.(node?.content_type)}
                ></Box>
                <ListItemText
                  primary={
                    <Typography
                      variant={`body2`}
                      className={`${classes.itemText}`}
                    >
                      {`${node.title}`}
                      <br />
                    </Typography>
                  }
                  secondary={
                    <Typography variant={`caption`}>
                      {node.content_type}
                    </Typography>
                  }
                />
              </ListItem>
            </>
          ))}
          {!props.rootNodes?.length && (
            <Typography
              align={`center`}
              variant={`body2`}
              className={`${classes.noItemsMessage}`}
            >
              There are no items...
            </Typography>
          )}
        </List>
      </GraphRootNodeDrawer>
    </>
  );
};

export default GraphRootNodeSelector;
