import {
  Box,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import bgImage from "../../bg-texture-intro.png";
import ltLogo from "images/LTLogo.svg";
import RegionCard from "./RegionCard";
import COOImage from "../../cooPhoto.jpg";
import BEEImage from "../../beePhoto.jpg";
import GBAImage from "../../gbaPhoto.jpg";
import ToolBox from "../../ToolBox";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import Toaster from "../Toaster";
import AboutTheSite from "./AboutTheSite";
import HomePage from "./HomePage";
import HowTo from "./HowTo";

const styles = makeStyles((theme) => ({
  logoPaper: {
    width: `100%`,
    height: `fit-content`,
    padding: theme.spacing(2),
    boxSizing: `border-box`,
  },
}));

const LandingPage = (props: { [key: string]: any }) => {
  const classes = styles();
  const history = useHistory();

  const forceUpdate = React.useReducer(() => ({}), {})[1];

  useEffect(() => {
    forceUpdate();
  }, [history.location.pathname]);

  return (
    <Box
      position={`relative`}
      width={`100%`}
      height={`100%`}
      display={`flex`}
      alignItems={`center`}
      justifyContent={`center`}
      style={{ background: `url(${bgImage})`, backgroundSize: "cover" }}
      overflow={`hidden`}
    >
      <img
        src={ltLogo}
        alt={`Lenticular`}
        style={{ position: `absolute`, bottom: 0, height: `64px`, zIndex: 0 }}
      />
      <Box
        width={`calc(min(1080px, 90%))`}
        maxHeight={`100%`}
        style={{ overflowY: `auto`, overflowX: `hidden` }}
        className={`custom-scrollbar`}
        zIndex={1}
      >
        <Switch>
          <Route path={`/`} exact>
            <HomePage in={true} resetNetwork={props.resetNetwork} />
          </Route>
          <Route path={`/how_to`} exact>
            <HomePage in={true} resetNetwork={props.resetNetwork} />
            <HowTo in={true} />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default LandingPage;
