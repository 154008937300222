import {
  Box,
  Card,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
  card: {
    position: "relative",
    padding: `8px 16px`,
  },
  cardOverlay: {
    display: `flex`,
    position: `absolute`,
    top: 0,
    left: 0,
    width: `100%`,
    height: `100%`,
    background: `linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 98%)`,
    cursor: "pointer",
    pointerEvents: "none",
  },
  expandMore: {
    pointerEvents: `all`,
    margin: `auto 8px auto auto`,
  },
  iconNormal: {
    transform: `rotate(0)`,
  },
  iconExpanded: {
    transform: `rotate(180deg)`,
  },
}));

const ExpandableText = (props: {
  content: string;
  storage: string;
  [key: string]: any;
}) => {
  const classes = styles();

  const [expanded, setExpanded] = React.useState(
    window.localStorage.getItem(props.storage) !== "false"
  );

  const handleClick = () => {
    window.localStorage.setItem(props.storage, `${!expanded}`);
    setExpanded((e) => !e);
  };

  return (
    <Card className={`${classes.card}`} variant={`outlined`}>
      <Typography variant={`body2`} noWrap={!expanded}>
        {props.content}
      </Typography>
      {expanded ? (
        <Box
          display={`flex`}
          position={`absolute`}
          top={0}
          left={0}
          width={`100%`}
          height={`100%`}
        >
          <IconButton
            style={{ margin: `auto 8px 8px auto` }}
            size={`small`}
            onClick={handleClick}
          >
            <span
              className={`material-icons ${
                expanded ? classes.iconExpanded : classes.iconNormal
              }`}
            >
              expand_more
            </span>
          </IconButton>
        </Box>
      ) : (
        <div className={`${classes.cardOverlay}`}>
          <IconButton
            className={`${classes.expandMore}`}
            size={`small`}
            onClick={handleClick}
          >
            <span
              className={`material-icons ${
                expanded ? classes.iconExpanded : classes.iconNormal
              }`}
            >
              expand_more
            </span>
          </IconButton>
        </div>
      )}
    </Card>
  );
};

export default ExpandableText;
