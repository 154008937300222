import {
  Box,
  ButtonBase,
  Card,
  createMuiTheme,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Themes from "../../../../Themes/Themes";

const ThemeButton = (props) => {
  const muiTheme = React.useState(createMuiTheme(props.theme))[0];

  return (
    <ButtonBase
      style={{ borderRadius: "4px", marginRight: "16px" }}
      onClick={props.onClick}
    >
      <Card
        variant={
          muiTheme.name === Themes.CurrentTheme().name
            ? "elevation"
            : `outlined`
        }
        style={{ overflow: "hidden", padding: "0 0 8px", width: "128px" }}
        elevation={4}
      >
        <Box
          overflow="hidden"
          display={"flex"}
          alignItems={"center"}
          justifyContent="center"
          height={"64px"}
        >
          <Box
            display={`flex`}
            width={`128px`}
            height={`128px`}
            style={{ transform: `rotate(20deg) scale(1.15)` }}
          >
            <Box
              flexGrow={1}
              height={`100%`}
              style={{ backgroundColor: muiTheme.palette.primary.main }}
            ></Box>
            <Divider flexItem orientation="vertical" />
            <Box
              flexGrow={1}
              height={`100%`}
              style={{ backgroundColor: muiTheme.palette.secondary.main }}
            ></Box>
            <Divider flexItem orientation="vertical" />
            <Box
              flexGrow={1}
              height={`100%`}
              style={{ backgroundColor: muiTheme.palette.background.paper }}
            ></Box>
            <Divider flexItem orientation="vertical" />
            <Box
              flexGrow={1}
              height={`100%`}
              style={{ backgroundColor: muiTheme.palette.background.default }}
            ></Box>
            <Divider flexItem orientation="vertical" />
            <Box
              flexGrow={1}
              height={`100%`}
              style={{ backgroundColor: muiTheme.palette.text.primary }}
            ></Box>
          </Box>
        </Box>
        <Divider />
        <Typography
          variant={"body1"}
          style={{ fontWeight: 500, marginTop: "8px" }}
        >
          {muiTheme.name}
        </Typography>
      </Card>
    </ButtonBase>
  );
};

export default ThemeButton;
