import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `us-west-2_TTVXzpRic`,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: `7fqp1kifr965s9sph9prk124rt`,

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "lenticular.auth.us-west-2.amazoncognito.com",
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `https://demo.lenticular.io/gba`,
      redirectSignOut: `https://demo.lenticular.io/gba/signout`,
      responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
