import {
  AppBar,
  Box,
  createMuiTheme,
  CssBaseline,
  jssPreset,
  MuiThemeProvider,
  Paper,
  StylesProvider,
  Typography,
} from "@material-ui/core";
import SiteTour from "components/SiteTour";
import { create } from "jss";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ToolBox from "ToolBox";
import "./App.css";
import GraphHeadingCard from "./components/GraphHeadingCard/GraphHeadingCard";
import GraphLegendGroup from "./components/GraphLegendGroup/GraphLegendGroup";
import GraphNamedPathwayCard from "./components/GraphNamedPathwayCard";
import GraphNamedPathwayControl from "./components/GraphNamedPathwayControl";
import GraphPinnedControls from "./components/GraphPinnedControls/GraphPinnedControls";
import GraphRootNodeSelector from "./components/GraphRootNodeSelector/GraphRootNodeSelector";
import GraphSearchControls from "./components/GraphSearchControls/GraphSearchControls";
import Graph from "./components/GraphView/Graph/Graph";
import GraphControlLayer from "./components/GraphView/Graph/GraphControlLayer/GraphControlLayer";
import InfoView from "./components/InfoView/InfoView";
import LandingPage from "./components/LandingPage";
import NetworkView from "./components/NetworkView/NetworkView";
import SettingsPage from "./components/SettingsPage/SettingsPage";
import SingleItemPage from "./components/SingleItemPage";
import Themes from "./components/Themes/Themes";
import Toaster from "./components/Toaster/Toaster";
import logo from "images/LTLogo.svg";
import CrestBaCsiroLogo from "images/Crest-BA-CSIRO.png";
import RESTAPI from "./RESTAPI";
import "./styles/colours.scss";
import "./styles/scrollbar.scss";
import GlossaryPage from "components/GlossaryPage";

const jss = create({
  ...jssPreset(),
  insertionPoint: "jss-insertion-point",
});

function App() {
  const [theme, setTheme] = React.useState(Themes.CurrentTheme());
  const [muiTheme, setMuiTheme] = React.useState(createMuiTheme(theme));

  const [graph, setGraph] = React.useState(null);
  const [network, setNetwork] = React.useState(null);

  const [filtered, setFiltered] = React.useState(null);

  const [tourOpen, setTourOpen] = React.useState(false);

  const filtersRef = React.useRef({
    overallConfidence: [1, 3],
    evaluationConfidence: [1, 3],
    materialityConfidence: [1, 3],
    mitigationConfidence: [1, 3],
    evaluationTypes: { 0: true, 1: true, 2: true, 3: true, 4: true },
  });

  useEffect(() => {
    document.body.classList.add(Themes.CurrentTheme().name);
  }, []);

  const changeTheme = (newTheme) => {
    document.body.classList.remove(Themes.CurrentTheme().name);
    document.body.classList.add(newTheme.name);
    setMuiTheme(createMuiTheme(newTheme));
    window.localStorage.setItem("site-theme", newTheme.name);
  };

  const handleSearch = (docs) => {
    setFiltered(docs);
  };

  console.log(ToolBox.GetQueries());

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={muiTheme}>
        <Box
          style={{ backgroundColor: muiTheme.palette.background.default }}
          className="App"
        >
          <Box height={`100%`} position={`relative`}>
            <Router>
              <SiteTour />
              <Switch>
                <Route
                  path={["/", "/about_the_site", "/how_to", "/start_tour"]}
                  exact
                >
                  <LandingPage resetNetwork={() => setNetwork(null)} />
                </Route>
                <Route path={["/glossary"]} exact>
                  <GlossaryPage />
                </Route>
                <Route
                  path={[
                    "/:tenant/items/item/:itemId",
                    "/:tenant/items/item/:itemId/:tabID",
                  ]}
                  exact={true}
                >
                  <SingleItemPage />
                </Route>
                <Route
                  path={[
                    "/:tenant/:rootId/:drawer/:tabID",
                    "/:tenant/:rootId/:drawer",
                    "/:tenant/:rootId",
                    "/:tenant",
                  ]}
                  exact={true}
                  children={(p) => (
                    <>
                      <Graph
                        flexGrow={1}
                        height="100%"
                        onCreation={(g) => {
                          setGraph(g);
                        }}
                        onUnmount={() => {
                          setGraph(null);
                        }}
                      >
                        {!!graph && (
                          <NetworkView
                            {...p}
                            onUpdate={setNetwork}
                            onCreation={(n) => {
                              graph.handleCenterGraph(graph.graph_id);

                              n.handlePinMultiple(
                                n.nodes.flat().filter((n) =>
                                  ToolBox.GetQueries()
                                    .pins?.split(/-/g)
                                    .some((id) => `${n.id}` === `${id}`)
                                ),
                                n.nodes.flat()
                              );

                              setNetwork(n);
                            }}
                            graph_id={graph.graph_id}
                            filtered={filtered}
                            scale={graph.scale}
                          />
                        )}
                      </Graph>
                      <img
                        alt={"Lenticular Logo"}
                        src={logo}
                        style={{
                          pointerEvents: "none",
                          position: "absolute",
                          bottom: 0,
                          left: "50%",
                          transform: "translate(-50%, 10%)",
                          width: "128px",
                        }}
                      />
                      <GraphControlLayer>
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          padding={`0 8px 0 0`}
                          height={`100%`}
                          maxHeight={`100%`}
                          boxSizing={`border-box`}
                          display={`flex`}
                          flexDirection={`column`}
                        >
                          <span
                            className={`tour-guide tour-3`}
                            style={{
                              paddingLeft: `8px`,
                              paddingTop: `8px`,
                              display: `flex`,
                              flexDirection: `column`,
                              overflow: `hidden`,
                            }}
                          >
                            <GraphHeadingCard />
                            <GraphSearchControls
                              in={true}
                              handleSearch={handleSearch}
                            />
                            <GraphPinnedControls
                              in={
                                !!network?.pinned?.length &&
                                !network?.nodes
                                  .flat()
                                  .filter((n) => n.root)
                                  .map((n) => n.named_paths)
                                  .flat()
                                  .filter((p) => p)
                                  .find(
                                    (pathway) =>
                                      pathway.node_ids.every((nodeId) =>
                                        (network?.pinned ?? []).some(
                                          (item) => item.uid === nodeId
                                        )
                                      ) &&
                                      pathway.node_ids.length ===
                                        (network?.pinned ?? []).length
                                  )
                              }
                              pinnedItems={network?.pinned}
                              pathways={
                                network?.nodes
                                  .flat()
                                  .filter((n) => n.root)
                                  .map((n) => n.named_paths)
                                  .flat()
                                  .filter((p) => p) ?? []
                              }
                              removePin={(item) => {
                                network.unpin(item);
                              }}
                            />
                            <GraphNamedPathwayControl
                              in={!network?.pinned?.length}
                              pathways={network?.nodes
                                .flat()
                                .filter((n) => n.root)
                                .map((n) => n.named_paths)
                                .flat()
                                .filter((p) => p)}
                              nodes={network?.nodes?.flat()}
                              pinMultiple={(ns) => {
                                network?.handlePinMultiple(ns, network?.nodes);
                              }}
                            />
                            <GraphNamedPathwayCard
                              in={
                                !!network?.nodes
                                  .flat()
                                  .filter((n) => n.root)
                                  .map((n) => n.named_paths)
                                  .flat()
                                  .filter((p) => p)
                                  .find(
                                    (pathway) =>
                                      pathway.node_ids.every((nodeId) =>
                                        (network?.pinned ?? []).some(
                                          (item) => item.uid === nodeId
                                        )
                                      ) &&
                                      pathway.node_ids.length ===
                                        (network?.pinned ?? []).length
                                  )
                              }
                              pathway={network?.nodes
                                .flat()
                                .filter((n) => n.root)
                                .map((n) => n.named_paths)
                                .flat()
                                .filter((p) => p)
                                .find(
                                  (pathway) =>
                                    pathway.node_ids.every((nodeId) =>
                                      (network?.pinned ?? []).some(
                                        (item) => item.uid === nodeId
                                      )
                                    ) &&
                                    pathway.node_ids.length ===
                                      (network?.pinned ?? []).length
                                )}
                              nodes={network?.nodes?.flat()}
                              handleClose={() =>
                                network?.handlePinMultiple([], network?.nodes)
                              }
                            />
                          </span>
                          <GraphLegendGroup
                            handleZoom={graph?.handleZoom}
                            handleCenterGraph={graph?.handleCenterGraph}
                            onFilterChange={(filters) => {
                              console.log(filters);
                              network.setFilters(filters);
                            }}
                            filtersRef={filtersRef}
                          />
                        </Box>
                        <SettingsPage changeTheme={changeTheme} />
                        <InfoView
                          {...p}
                          network={network}
                          resetNetwork={() => setNetwork(null)}
                        />
                      </GraphControlLayer>
                    </>
                  )}
                />
                <Route path={`*`}>
                  <Typography variant={`h4`}>404</Typography>
                </Route>
              </Switch>
            </Router>
          </Box>
          <Toaster />
        </Box>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
