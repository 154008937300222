import { makeStyles, Tooltip, useTheme } from "@material-ui/core";
import Toaster from "components/Toaster";
import React, { useEffect } from "react";
import Themes from "../../Themes/Themes";
import NetworkArrowTooltip from "../NetworkArrowTooltip";

const styles = makeStyles((theme) => ({
  arrowLine: {
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  noShow: {
    opacity: 0,
  },
}));

const NetworkArrow = (props) => {
  const classes = styles();
  const theme = useTheme();

  const [linkColour, setLinkColour] = React.useState();
  const [linkCausalEffect, setLinkCausalEffect] = React.useState();
  const [linkConfidence, setLinkConfidence] = React.useState();
  const [infoTooltipPosition, setInfoTooltipPosition] = React.useState();

  const [filteredOut, setFilteredOut] = React.useState(false);

  const [hovered, setHovered] = React.useState(false);

  useEffect(() => {
    setLinkColour(Themes.CurrentTheme().links(props.score));
    setLinkCausalEffect(
      props.endNode?.parent_links?.find((l) => l.id === props.startNode?.uid)?.d
        ?.causal_effect ?? "Increase"
    );
    let confidence = 1;
    switch (
      props.endNode?.parent_links?.find((l) => l.id === props.startNode?.uid)?.d
        ?.assessment_confidence ??
      "Low"
    ) {
      case "Medium": {
        confidence = 2;
        break;
      }
      case "High": {
        confidence = 3;
        break;
      }
      default:
        confidence = 1;
    }

    const confidences = ["Not evaluated", "Low", "High"];

    const linkInfo = props.endNode?.parent_links?.find(
      (l) => l.id === props.startNode?.uid
    )?.d;

    if (!linkInfo) {
      Toaster.makeToast("Error rendering link!", "warning");
    }

    const evaluationConfidence =
      confidences.findIndex(
        (val) =>
          val.toLowerCase() ===
          linkInfo?.assessment_confidence_hypothesis?.toLowerCase()
      ) + 1;

    const materialityConfidence =
      confidences.findIndex(
        (val) =>
          val.toLowerCase() ===
          linkInfo?.assessment_confidence_materiality?.toLowerCase()
      ) + 1;

    const mitigationConfidence =
      confidences.findIndex(
        (val) =>
          val.toLowerCase() ===
          linkInfo?.assessment_confidence_mitigation?.toLowerCase()
      ) + 1;

    if (
      props.typeHidden ||
      confidence < props.filters.overallConfidence?.[0] ||
      confidence > props.filters.overallConfidence?.[1] ||
      evaluationConfidence < props.filters.evaluationConfidence?.[0] ||
      evaluationConfidence > props.filters.evaluationConfidence?.[1] ||
      materialityConfidence < props.filters.materialityConfidence?.[0] ||
      materialityConfidence > props.filters.materialityConfidence?.[1] ||
      mitigationConfidence < props.filters.mitigationConfidence?.[0] ||
      mitigationConfidence > props.filters.mitigationConfidence?.[1]
    ) {
      console.log(`---- Hidden : ${props.typeHidden} ----`);
      console.log(`Overall ${confidence}`);
      console.log(`Evaluation ${evaluationConfidence}`);
      console.log(`Materiality ${materialityConfidence}`);
      console.log(`Mitigation ${mitigationConfidence}`);
      console.log(linkInfo.assessment_confidence_mitigation);
    }

    setFilteredOut(
      props.typeHidden ||
        confidence < props.filters.overallConfidence?.[0] ||
        confidence > props.filters.overallConfidence?.[1] ||
        evaluationConfidence < props.filters.evaluationConfidence?.[0] ||
        evaluationConfidence > props.filters.evaluationConfidence?.[1] ||
        materialityConfidence < props.filters.materialityConfidence?.[0] ||
        materialityConfidence > props.filters.materialityConfidence?.[1] ||
        mitigationConfidence < props.filters.mitigationConfidence?.[0] ||
        mitigationConfidence > props.filters.mitigationConfidence?.[1]
    );
    setLinkConfidence(confidence);

    let startEl = document.getElementById(
      `${props.graph_id}_node_${props.startNode.uid}`
    );
    let endEl = document.getElementById(
      `${props.graph_id}_node_${props.endNode.uid}`
    );
    let lineEl = document.getElementById(
      `${props.graph_id}_line_${props.startNode.uid}_${props.endNode.uid}`
    );
    let clickEl = document.getElementById(
      `${props.graph_id}_line_${props.startNode.uid}_${props.endNode.uid}_click`
    );
    let dashedEl = document.getElementById(
      `${props.graph_id}_line_${props.startNode.uid}_${props.endNode.uid}_dashed`
    );

    if (!startEl || !endEl || !lineEl || !clickEl) {
      console.error(`El could not be found!`);
      return;
    }

    if (props.startNode.depth < props.endNode.depth) {
      lineEl.setAttribute("d", lineDown(startEl, endEl, confidence));
      clickEl.setAttribute("d", lineDown(startEl, endEl, confidence));
      dashedEl?.setAttribute("d", lineDown(startEl, endEl, confidence));
    } else if (props.startNode.depth === props.endNode.depth) {
      lineEl.setAttribute("d", lineAcross(startEl, endEl));
      clickEl.setAttribute("d", lineAcross(startEl, endEl));
      dashedEl?.setAttribute("d", lineAcross(startEl, endEl));
    }
  }, [
    props.hovered,
    props.filters.overallConfidence,
    props.filters.evaluationConfidence,
    props.filters.materialityConfidence,
    props.filters.mitigationConfidence,
    props.typeHidden,
  ]);

  /**
   * Draws a curved line from a shallow node, to a deeper node.
   *
   * @param {HTMLElement} startEl
   * @param {HTMLElement} endEl
   */
  const lineDown = (startEl, endEl, lc) => {
    let start = {
      x: startEl.offsetLeft + startEl.clientWidth,
      y: startEl.offsetTop + startEl.clientHeight / 2,
    };
    let end = {
      x: endEl.offsetLeft - (lc - 1) * 3,
      y: endEl.offsetTop + endEl.clientHeight / 2,
    };
    let diff = {
      x: end.x - start.x,
      y: end.y - start.y,
    };

    return `m ${start.x} ${start.y} q ${diff.x * (3 / 8)} ${diff.y * (1 / 8)} ${
      diff.x / 2
    } ${diff.y / 2} q ${diff.x * (1 / 8)} ${diff.y * (3 / 8)} ${diff.x / 2} ${
      diff.y / 2
    }`;
  };

  const lineAcross = (startEl, endEl) => {};

  const getStroke = () =>
    props.somehover
      ? props.startNode.hovered &&
        props.endNode.hovered &&
        !props.pinned.some(
          (pin) =>
            pin.depth > props.startNode.depth && pin.depth < props.endNode.depth
        )
        ? linkColour + "FF"
        : linkColour + "08"
      : linkColour + "FF";

  const getStrokeDash = (hover) => undefined;
  //linkCausalEffect === "Increase" ? undefined : !!hover ? `32 16` : 16;

  return (
    <>
      <defs>
        <marker
          id={`${props.graph_id}_head_${props.startNode.uid}_${props.endNode.uid}`}
          orient="auto"
          markerWidth={`${16}`}
          markerHeight={`${16}`}
          refX={`${4}`}
          refY={`${4}`}
        >
          <path
            d={`M0,${8} L${4},${4} L-${4},-${4}`}
            style={{
              fill: "none",
              stroke: getStroke(),
            }}
          />
        </marker>
        <marker
          id={`${props.graph_id}_head_${props.startNode.uid}_${props.endNode.uid}_click`}
          orient="auto"
          markerWidth="12"
          markerHeight="12"
          refX="3"
          refY="3"
        >
          <path
            d="M0,6 L3,3 L-3,-3"
            style={{
              fill: "none",
              stroke: getStroke(),
            }}
          />
        </marker>
      </defs>
      <path
        markerEnd={`url(#${props.graph_id}_head_${props.startNode.uid}_${props.endNode.uid})`}
        style={{
          strokeWidth: `8px`,
          stroke: getStroke(),
          transition: `opacity 500ms ease`,
          fill: "none",
          cursor: "pointer",
          pointerEvents: filteredOut ? "none" : "stroke",
          zIndex: 1,
          opacity: filteredOut
            ? 0.17
            : !(
                props.somehover &&
                !(
                  props.startNode.hovered &&
                  props.endNode.hovered &&
                  !props.pinned.some(
                    (pin) =>
                      pin.depth > props.startNode.depth &&
                      pin.depth < props.endNode.depth
                  )
                )
              ) && hovered
            ? 0
            : 1,
        }}
        id={`${props.graph_id}_line_${props.startNode.uid}_${props.endNode.uid}`}
        strokeDasharray={getStrokeDash()}
      />
      <path
        className={`tour-guide tour-14`}
        markerEnd={`url(#${props.graph_id}_head_${props.startNode.uid}_${props.endNode.uid}_click)`}
        style={{
          strokeWidth: "15px",
          stroke: getStroke(),

          fill: "none",
          cursor: "pointer",
          pointerEvents: filteredOut ? "none" : "stroke",
          zIndex: 1,
          opacity:
            !(
              props.somehover &&
              !(
                props.startNode.hovered &&
                props.endNode.hovered &&
                !props.pinned.some(
                  (pin) =>
                    pin.depth > props.startNode.depth &&
                    pin.depth < props.endNode.depth
                )
              )
            ) && hovered
              ? 1
              : 0,
        }}
        id={`${props.graph_id}_line_${props.startNode.uid}_${props.endNode.uid}_dashed`}
        strokeDasharray={getStrokeDash(true)}
      />
      <Tooltip
        disableHoverListener={
          props.somehover &&
          !(
            props.startNode.hovered &&
            props.endNode.hovered &&
            !props.pinned.some(
              (pin) =>
                pin.depth > props.startNode.depth &&
                pin.depth < props.endNode.depth
            )
          )
        }
        arrow
        enterDelay={1000}
        title={
          <NetworkArrowTooltip
            startNode={props.startNode}
            endNode={props.endNode}
            data={
              props.endNode?.parent_links?.find(
                (l) => l.id === props.startNode?.uid
              )?.d
            }
          />
        }
        onMouseMove={(e) => setInfoTooltipPosition({ x: e.pageX, y: e.pageY })}
        PopperProps={{
          anchorEl: {
            clientHeight: 0,
            clientWidth: 0,
            getBoundingClientRect: () => ({
              top: infoTooltipPosition.y,
              left: infoTooltipPosition.x,
              right: infoTooltipPosition.x,
              bottom: infoTooltipPosition.y,
              width: 0,
              height: 0,
            }),
          },
        }}
      >
        <path
          markerEnd={`url(#${props.graph_id}_head_${props.startNode.uid}_${props.endNode.uid}_click)`}
          style={{
            strokeWidth: "15px",
            stroke: getStroke(),

            fill: "none",
            cursor: "pointer",
            pointerEvents: filteredOut ? "none" : "stroke",
            zIndex: 1,
            opacity: 0,
          }}
          id={`${props.graph_id}_line_${props.startNode.uid}_${props.endNode.uid}_click`}
          onClick={() =>
            props.onClick({
              isLink: true,
              startNode: props.startNode,
              endNode: props.endNode,
            })
          }
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        />
      </Tooltip>
    </>
  );
};

export default NetworkArrow;
