import {
  Card,
  makeStyles,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ToolBox from "../../../ToolBox";

const styles = makeStyles((theme) => ({
  card: {
    color: "white",
    minWidth: "300px",
    width: "300px",
    height: 200,
    flexGrow: 1,
    margin: theme.spacing(1),
    position: "relative",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    pointerEvents: "none",
  },
  contentShadow: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 45%, rgba(0, 0, 0, 0.5) 55%, rgba(0, 0, 0, 0) 100%)",
    opacity: 1,
    "&:hover": {
      opacity: 0,
    },
  },
  contentContainer: {
    boxSizing: `border-box`,
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    flexDirection: `column`,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    transition: "background-color 200ms",
    background: "transparent",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.4)",
    },
    cursor: "pointer",
    padding: theme.spacing(1),
  },
}));

const RegionCard = (props: { image: string; [key: string]: any }) => {
  const [loading, setLoading] = React.useState(false);

  const classes = styles();

  return (
    <Card
      className={`${classes.card} ${props.className ? props.className : ""}`}
      onClick={() => {
        setLoading(true);
        props.onClick();
      }}
    >
      <img className={classes.cardImage} src={props.image} alt="" />
      <div className={classes.contentShadow}></div>
      <div className={classes.contentContainer}>
        <Box
          display={`flex`}
          alignItems={`center`}
          justifyContent={`center`}
          width={`100%`}
        >
          <Typography align={`center`} variant="h6" component="h2" noWrap>
            {props.title}
          </Typography>
          <span className={`material-icons`} style={{ marginLeft: "8px" }}>
            chevron_right
          </span>
        </Box>
        {loading && <LinearProgress style={{ width: `90%` }} />}
      </div>
    </Card>
  );
};

export default RegionCard;
