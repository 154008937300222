import { ThemeProvider } from "@material-ui/core";
import React from "react";
import TabPanel from "../TabPanel";
import Appearance from "./Appearance/Appearance";
import Guide from "./Guide";

const Panels = (props) => {
  return (
    <>
      <TabPanel value={props.index} index={0}>
        <Appearance changeTheme={props.changeTheme} />
      </TabPanel>
      <TabPanel value={props.index} index={1}>
        <Guide />
      </TabPanel>
    </>
  );
};

export default Panels;
