import { ThemeOptions } from "@material-ui/core";

const theme = {
  name: "Blueberry",
  palette: {
    primary: {
      main: "#77C077",
    },
    secondary: {
      main: "#339933",
    },
    background: {
      paper: "rgb(60, 74, 230)",
      default: "rgb(30, 34, 170)",
    },
    text: {
      primary: "#305530",
    },
    type: "light",
  },
  shape: {
    borderRadius: 4,
  },
  links: (val) => {
    switch (val) {
      case 0:
        return "#237a53";
      case 1:
        return "#61b89c";
      case 2:
        return "#dadbdc";
      case 3:
        return "#b062ba";
      case 4:
        return "#6d2077";
      case 9:
        return "#a0d9e6";
      default:
        return "#FF00FF";
    }
  },
  nodes: (val) => {
    switch (val) {
      case "Driver":
        return "#28df99";
      case "Activity":
        return "#99f3bd";
      case "Stressor":
        return "#d2f6c5";
      case "Process":
        return "#f6f7d4";
      case "Endpoint":
        return "#00FF00";
      default:
        return "#FF00FF";
    }
  },
  transition: {
    short: "200ms",
    normal: "500ms",
    long: "1000ms",
  },
};

export default theme;
