import {
  Box,
  Collapse,
  Grow,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import RESTAPI from "../../../RESTAPI";
import ToolBox from "../../../ToolBox";
import Themes from "../../Themes/Themes";

const styles = makeStyles((theme) => ({
  secondaryColor: {
    color: theme.palette.text.secondary,
  },
}));

const LinkItem = (props) => {
  const classes = styles();

  const [node, setNode] = React.useState(props.node);
  const inNetwork = React.useState(typeof props.node !== "string")[0];

  useEffect(() => {
    if (typeof node !== "string") return;

    RESTAPI.GetItemFromSolr(node).then((res) => setNode(res.docs?.[0]));
  }, [props.node]);

  return (
    <Slide
      direction={"left"}
      in={true}
      style={{ transitionDelay: (props.index + 1) * 50 }}
    >
      <ListItem
        key={props.index}
        button
        onClick={() => {
          ToolBox.SetDrawer(`${node.id}-${props.item.id}`, props.history);
          if (props.isStandalone)
            ToolBox.SetRoot(`${node.id}-${props.item.id}`, props.history);
        }}
        style={{ height: "36px" }}
      >
        {!!node?.content_type && (
          <Box
            position={"absolute"}
            left="4px"
            width="4px"
            height="90%"
            bgcolor={Themes.CurrentTheme()?.links?.(
              props.item?.parent_links.find((l) => l.id === node.uid)?.d
                ?.assessment_score
            )}
          ></Box>
        )}
        {!node?.title ? (
          <LinearProgress style={{ width: "100%" }} />
        ) : (
          <Grow in={true}>
            <Box display="flex" width={"100%"}>
              <Typography variant="body2" noWrap style={{ marginRight: "8px" }}>
                {node?.title ?? "NNF"}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ marginRight: "8px", fontWeight: 700 }}
              >
                {`${
                  props.item?.parent_links.find((l) => l.id === node.uid)?.d
                    ?.causal_effect
                }s`.toLowerCase() ?? "N/A"}
              </Typography>
              <Typography variant="body2" noWrap style={{ marginRight: "8px" }}>
                {props.item?.title}
              </Typography>
              <Grow
                in={!inNetwork && !props.isStandalone}
                style={{ transitionDelay: (props.index + 1) * 50 }}
              >
                <Box
                  position={`absolute`}
                  top={`50%`}
                  right={`16px`}
                  style={{ transform: "translate(0, -50%)" }}
                  height={"24px"}
                >
                  <Tooltip
                    title={`This evaluation is not shown on the current graph`}
                    arrow
                  >
                    <span
                      className={`material-icons ${classes.secondaryColor}`}
                    >
                      visibility_off
                    </span>
                  </Tooltip>
                </Box>
              </Grow>
            </Box>
          </Grow>
        )}
      </ListItem>
    </Slide>
  );
};

export default LinkItem;
