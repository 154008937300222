import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Themes from "../../../Themes/Themes";
import ThemeButton from "./ThemeButton";

const styles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    marginBottom: 24,
  },
  content: {},
}));

const Appearance = (props) => {
  const classes = styles();

  const [counter, setCounter] = React.useState(25);

  const handleClick = () => {
    if (counter === 0) {
      props.changeTheme(Themes.GREEN);
    } else {
      setCounter((c) => c - 1);
    }
  };

  return (
    <>
      <Card className={classes.root} variant={`outlined`}>
        <CardHeader
          onClick={handleClick}
          title="Themes"
          subheader="Change the overall colour scheme of the site."
          subheaderTypographyProps={{ variant: "body2" }}
        />
        <CardContent className={classes.content}>
          {Themes.THEMES.map((theme) => (
            <ThemeButton
              theme={theme}
              onClick={() => props.changeTheme(theme)}
            />
          ))}
          <Collapse collapsedHeight={`${(128 / 25) * (25 - counter)}px`} style={{display: `inline-flex`}}>
            <span>
              <ThemeButton
                theme={Themes.GREEN}
                onClick={() => props.changeTheme(Themes.GREEN)}
              />
            </span>
          </Collapse>
        </CardContent>
      </Card>
    </>
  );
};

export default Appearance;
