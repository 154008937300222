import { Box, Button, Slide, Typography } from "@material-ui/core";
import React, { useEffect } from "react";

const FirstVisitBanner = (props: {
  in: boolean;
  onClose: () => void;
  onStartTour: () => void;
}) => {
  return (
    <Slide in={props.in} direction={`up`} mountOnEnter unmountOnExit>
      <Box
        position={`absolute`}
        zIndex={10000}
        bottom={0}
        left={0}
        right={0}
        height={`72px`}
        bgcolor={`rgba(0, 0, 0, 0.8)`}
        color={`white`}
        padding={`8px 32px`}
        display={`flex`}
        justifyContent={`space-between`}
      >
        <Box
          width={`fit-content`}
          display={`flex`}
          flexDirection={`column`}
          alignItems={`flex-start`}
          justifyContent={`center`}
        >
          <Typography
            align={`left`}
            variant={`h6`}
            style={{ marginBottom: `-4px` }}
          >
            Site Tour
          </Typography>
          <Typography align={`left`} variant={`body2`}>
            Looks like this is your first time visiting the site. We recommend
            first time users take the guided tour to learn how to use the GBA
            Explorer to understand the GBA Program’s results.
          </Typography>
        </Box>
        <Box display={`flex`} alignItems={`center`}>
          <Button
            onClick={() => props.onClose()}
            variant={`contained`}
            style={{
              marginRight: `32px`,
              height: `fit-content`,
              whiteSpace: "nowrap",
            }}
          >
            No Thanks
          </Button>
          <Button
            onClick={() => props.onStartTour()}
            variant={`contained`}
            color={`primary`}
            style={{ height: `fit-content`, whiteSpace: "nowrap" }}
          >
            Take the tour
          </Button>
        </Box>
      </Box>
    </Slide>
  );
};

export default FirstVisitBanner;
