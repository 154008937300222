import React from "react";
import Toast from "./Toast";

const Toaster: any = () => {
  const [loaf, setLoaf] = React.useState([]);

  /**
   *
   * @param {String} message - Message to be displayed on the toast.
   */
  Toaster.makeToast = (message, severity, brownness) => {
    let id = loaf.length === 0 ? 0 : loaf[loaf.length - 1].id + 1;
    let toast = {
      id: id,
      message: message,
      severity: severity,
      brownness: brownness === "indef" ? 999999 : brownness,
    };
    setLoaf([...loaf, toast]);
  };

  Toaster.handleClose = (id) => {
    let l = [...loaf];
    setLoaf(l.filter((t) => t.id !== id));
  };

  return (loaf || []).map((toast, index) => (
    <Toast
      key={toast.id}
      toast={toast}
      index={index}
      handleClose={Toaster.handleClose}
    />
  ));
};

export default Toaster;
