import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  Paper,
  Popover,
  Table,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";

const styles = makeStyles((theme) => ({
  gButton: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    fontWeight: 400,
    display: `flex`,
    alignItems: `flex-start`,
    zIndex: 1,
  },
}));

const OverlayPopover = withStyles({
  paper: {
    width: "300px",
    borderTopRightRadius: 0,
  },
})(Popover);

const OverlaySelector = (props: any) => {
  const classes = styles();

  const [tab, setTab] = React.useState("layers" as null | "layers" | "info");

  const [anchor, setAnchor] = React.useState(null as null | HTMLElement);

  useEffect(() => {
    setTimeout(() => {
      setAnchor(document.getElementById(`overlay-selector`));
    }, 500);
  }, []);

  useEffect(() => {
    if (!!props.selectedFeature) {
      setTab("info");
    }
  }, [props.selectedFeature]);

  return !!props.maps ? (
    <>
      <Button
        variant="contained"
        disableElevation
        style={{
          position: "absolute",
          top: "10px",
          right: "160px",
          padding: "8px",
          minWidth: "unset",
          height: tab === "info" ? "50px" : "40px",
          borderBottomLeftRadius: tab === "info" ? "0px" : "2px",
          borderBottomRightRadius: tab === "info" ? "0px" : "2px",
        }}
        className={`${classes.gButton}`}
        onClick={() => setTab(tab === "info" ? null : "info")}
      >
        <span className={`material-icons`}>info</span>
      </Button>
      <Button
        variant="contained"
        disableElevation
        style={{
          position: "absolute",
          top: "10px",
          right: "110px",
          padding: "8px",
          minWidth: "unset",
          height: tab === "layers" ? "50px" : "40px",
          borderBottomLeftRadius: tab === "info" ? "0px" : "2px",
          borderBottomRightRadius: tab === "info" ? "0px" : "2px",
        }}
        className={`${classes.gButton}`}
        onClick={() => setTab(tab === "layers" ? null : "layers")}
        id={`overlay-selector`}
      >
        <span className={`material-icons`}>layers</span>
      </Button>

      <Fade in={tab !== null} timeout={100}>
        <Paper
          style={{
            position: `absolute`,
            top: `60px`,
            right: `10px`,
            width: `300px`,
            height: `fit-content`,
            maxHeight: `calc(100% - 250px)`,
            overflow: `auto`,
          }}
          className={`custom-scrollbar`}
        >
          <Box position={`relative`} height={`fit-content`}>
            <Fade in={tab === "info"}>
              <Box
                position={tab === "info" ? `relative` : `absolute`}
                top={0}
                left={0}
                width={`100%`}
                height={tab === "info" ? `fit-content` : `0px`}
                boxSizing={`border-box`}
                className={`custom-scrollbar`}
                display={`flex`}
                flexDirection={`column`}
              >
                <Paper
                  variant={`outlined`}
                  style={{ position: `sticky`, top: 0, border: `none` }}
                >
                  <Typography variant={`body1`} style={{ margin: `8px 16px` }}>
                    Feature Information
                  </Typography>
                  <Divider />
                </Paper>
                <Box
                  style={{ overflowY: `auto` }}
                  className={`custom-scrollbar`}
                >
                  {!!props.selectedFeature ? (
                    <Box
                      style={{ overflowY: "auto" }}
                      className={`custom-scrollbar`}
                      padding={`16px 8px 16px 16px`}
                    >
                      <Paper variant={`outlined`}>
                        <Table>
                          {Object.entries(props.selectedFeature ?? {}).map(
                            (property: [string, any]) => (
                              <TableRow>
                                <TableCell style={{ width: "50%" }}>
                                  {property[0]}
                                </TableCell>
                                <TableCell style={{ width: "50%" }}>
                                  {property[1]}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </Table>
                      </Paper>
                    </Box>
                  ) : (
                    <Typography
                      variant={`caption`}
                      component={`div`}
                      style={{
                        fontStyle: `italic`,
                        padding: `16px 8px 16px 16px`,
                      }}
                    >
                      Select a feature on the map to view its details.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Fade>
            <Fade in={tab === "layers"}>
              <Box
                position={tab === "layers" ? `relative` : `absolute`}
                top={0}
                left={0}
                width={`100%`}
                height={tab === "layers" ? `fit-content` : `0px`}
                display={`flex`}
                flexDirection={`column`}
                boxSizing={`border-box`}
              >
                <Paper
                  variant={`outlined`}
                  style={{
                    position: `sticky`,
                    top: 0,
                    border: `none`,
                    zIndex: 1,
                  }}
                >
                  <Typography variant={`body1`} style={{ margin: `8px 16px` }}>
                    Contextual Layers
                  </Typography>
                  <Divider />
                </Paper>
                <Box
                  style={{ overflowY: `auto` }}
                  className={`custom-scrollbar`}
                >
                  <List dense>
                    {props.maps.map((m: any, i: number) => (
                      <>
                        {i !== 0 && <Divider />}
                        <ListItem
                          disabled={props.processing}
                          dense
                          button
                          onClick={() => props.onChange(m)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              size={`small`}
                              disabled={props.processing}
                              checked={props.selectedOverlays.some(
                                (o: any) => o.url === m.url
                              )}
                            />
                          </ListItemIcon>
                          <ListItemText primary={m.name} />
                        </ListItem>
                      </>
                    ))}
                  </List>
                </Box>
              </Box>
            </Fade>
          </Box>
        </Paper>
      </Fade>

      <OverlayPopover
        open={false}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List dense>
          {props.maps.map((m, i: number) => (
            <>
              {i !== 0 && <Divider />}
              <ListItem>
                <ListItemText primary={m.name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    disabled={props.processing}
                    checked={props.selectedOverlays.some(
                      (o: any) => o.url === m.url
                    )}
                    onChange={() => props.onChange(m)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </>
          ))}
        </List>
      </OverlayPopover>
    </>
  ) : null;
};

export default OverlaySelector;
