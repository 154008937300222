import Toaster from "./components/Toaster";
import RESTAPI from "./RESTAPI";

export default class ToolBox {
  static EPBC_LIST = {
    coo: [70, 71, 72, 73, 74, 75, 76, 77, 80, 81],
    bee: [15, 63, 64, 65, 66, 67, 68],
    gba: [112],
  };

  static CurrentTenant = window.location.pathname.split(/\//g)[1];

  static GetTenantName: () => string = () => {
    switch (ToolBox.CurrentTenant) {
      case "bee":
        return `Beetaloo GBA region`;
      case "coo":
        return `Cooper GBA region`;
      case "gba":
        return `GBA information`;
      default:
        return `Unknown`;
    }
  };

  static URLtypes = {
    base: `/:tenant`,
    itemPage: `/:tenant/items/item/:itemId`,
    roots: `/:tenant/:rootId`,
    drawer: `/:tenant`,
  };

  static uuidv4 = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) =>
      (c === "x"
        ? (Math.random() * 16) | 0
        : (((Math.random() * 16) | 0) & 0x3) | 0x8
      ).toString(16)
    );

  static BannerColor = (content_type: string) => {
    switch (content_type) {
      case "Driver":
        return "#8b8b8b";
      case "Activity":
        return "#f11500";
      case "Stressor":
        return "#a4f296";
      case "Process":
        return "#f8e000";
      case "Endpoint":
        return "#3b01ff";
      default:
        return "transparent";
    }
  };

  static NodeColorClass = (type_id: any) => {
    //if (process.env.NODE_ENV !== "production") return "#CCCCCC"
    switch (type_id) {
      case "Driver":
        return "driver";
      case "Activity":
        return "activity";
      case "Stressor":
        return "stressor";
      case "Process":
        return "process";
      case "Endpoint":
        return "endpoint";
      default:
        return "error";
    }
  };

  static LinkColorClass = (type_id: number) => {
    //if (process.env.NODE_ENV !== "production") return "#CCCCCC"
    switch (type_id) {
      case 0:
        return "driver";
      case 1:
        return "activity";
      case 2:
        return "stressor";
      case 3:
        return "process";
      case 4:
        return "endpoint";
      case 9:
        return "endpoint";
      default:
        return "error";
    }
  };

  static SetTenant = (tenant: string, history: any, pinItemId?: string) => {
    ToolBox.CurrentTenant = tenant;
    RESTAPI.GetAllItemsOfType("Driver").then((res) => {
      history.push(
        `/${tenant}/${res?.docs
          ?.map?.((d: { id: string; [key: string]: any }) => d.id)
          ?.reduce(
            (z: string, d: string) => `${z}${!!z.length ? "-" : ""}${d}`,
            ""
          )}${!!pinItemId ? `?pins=${pinItemId}` : window.location.search}`
      );
    });
  };

  static SetRoot = (rootId: string, history: any) => {
    let comps = history.location.pathname.split(/\//g);

    comps[2] = rootId || "-";

    let path = comps.join("/");

    if (!history) return;

    try {
      history?.push?.(`${path}${history.location.search ?? ""}`);
    } catch (e) {
      Toaster.makeToast(JSON.stringify(e));
    }
  };

  static SetDrawer = (itemId: string, history: any) => {
    let comps: string[] = history.location.pathname.split(/\//g);

    if (!itemId?.length && comps[2] === "-") {
      history.push(`/${comps[1]}${window.location.search}`);
      return;
    }

    if (!comps[2]?.length) {
      comps[2] = "-";
    }

    let trimmed = comps.slice(0, 3);

    history.push(
      `${trimmed.join("/")}${itemId.length ? `/${itemId}` : ""}/${
        itemId.length ? `0` : ""
      }${window.location.search}`
    );
  };

  static SetTab = (
    tabId: string | number,
    history: any,
    isStandalone?: boolean
  ) => {
    let comps: string[] = history.location.pathname.split(/\//g);

    comps[!!isStandalone ? 5 : 4] = `${tabId}`;

    let trimmed = comps.slice(0, !!isStandalone ? 6 : 5);

    history.push(`${trimmed.join("/")}${window.location.search}`);
  };

  static SetHash = (hash: string | number, history: any) => {
    console.log(`${window.location.search}#${hash}`);
    history.push(`${window.location.search}#${hash}`);
  };

  static GetQueries = () =>
    Object.fromEntries(
      window.location.search
        .slice(1)
        .split(/&/g)
        .map((param) => param.split(/=/g))
        .filter((p) => p[0]?.length && p[1]?.length)
    );

  static SetQueries = (key: string, content: string, history: any) => {
    const queries = ToolBox.GetQueries();

    queries[key] = content?.length ? content : undefined;

    console.log(queries);

    const queryString = Object.entries(queries)
      .filter((e) => e[1])
      .filter((q: [string, any]) => q[0]?.length && q[1]?.length)
      .reduce((z, q, i) => `${z}${i !== 0 ? "&" : ""}${q[0]}=${q[1]}`, ``);

    console.log(queryString);

    history.push(`?${queryString}${window.location.hash}`);
  };

  static debounce = (func: any, wait = 250) => {
    let timeout: any;

    return function a(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  static GetAssessment = (score: number | string | undefined) => {
    switch (`${score}`) {
      case "0":
        return "Not possible";
      case "1":
        return "Possible but not material";
      case "2":
        return "Possible and material but can be avoided";
      case "3":
        return "Possible, material and unavoidable but can be mitigated";
      case "4":
        return "Possible, material, unavoidable and cannot be mitigated";
      case "9":
        return "Not assessed";
      default:
        return undefined;
    }
  };

  static validTenants = ["naa", "gba"];

  static groupBy(xs: any, key: string) {
    return xs.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}
