import {
  Box,
  Button,
  makeStyles,
  Paper,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import React, { useEffect } from "react";
import config from "../../config";
import RESTAPI from "../../RESTAPI";
import Themes from "../Themes/Themes";
import Toaster from "../Toaster";
import ContentPageMapLegend from "./ContentPageMapLegend";
import FeatureInfoPane from "./FeatureInfoPane";
import OverlaySelector from "./OverlaySelector";

const markerPathSmall = "M -1,-1 a 1,1 0 1,1 2,0 a 1,1 0 1,1 -2,0";
const markerPathMedium = "M -2,-2 a 2,2 0 1,1 4,0 a 2,2 0 1,1 -4,0";
const markerPathLarge = "M -4,-4 a 4,4 0 1,1 8,0 a 4,4 0 1,1 -8,0";

const styles = makeStyles((theme) => ({
  mapContainer: {
    boxShadow: `0px 0px 5px rgba(0, 0, 0, 0.4) inset`,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    pointerEvents: "none",
  },
  mapDark: {
    filter: `invert(1)`,
    "& a img": {
      filter: `invert(1)`,
    },
  },
  mapGreen: {
    filter: `hue-rotate(90deg)`,
    "& a img": {
      filter: `hue-rotate(-90deg)`,
    },
  },
  hideImage: {
    "& a img": {
      display: `none`,
    },
  },
  closeButton: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    fontWeight: 400,
  },
}));

const ContentPageMap = (props) => {
  const classes = styles();

  const [isIn, setIsIn] = React.useState(false);
  const isInTimerRef = React.useRef(null);

  const [selectedFeature, setSelectedFeature] = React.useState();

  const [processing, setProcessing] = React.useState(false);

  const [mapGeoJSON, setMapGEOJSON] = React.useState();
  const [overlayMaps, setOverlayMaps] = React.useState();
  const [selectedOverlays, setSelectedOverlays] = React.useState([]);

  const mapRef = React.useRef(null);

  useEffect(() => {
    console.log(props.map);
    if (!!props.map && !!mapRef.current) {
      RESTAPI.Fetch(props.map.url).then((geojson) => {
        const fixed = {
          ...geojson,
          features: geojson.features.map((f, index) => ({
            ...f,
            id: undefined,
            properties: {
              ...f.properties,
              zIndex: index,
              geometry: f.geometry.type,
            },
          })),
        };

        setMapGEOJSON(fixed);
        const features = mapRef.current.data.addGeoJson(fixed);
        props.map.features = features;
        handleCenterMap(mapRef.current);
        handleOnLoad(null, selectedOverlays);
      });
    }
  }, [props.map, mapRef.current]);

  useEffect(() => {
    RESTAPI.GetAllItemsOfType("Summary").then((res) => {
      setOverlayMaps(
        res.docs[0]?.geojson_maps?.filter(
          (m) => m.map_type === "Contextual layer"
        ) ?? []
      );
    });
  }, []);

  useEffect(() => {
    if (isInTimerRef.current) clearTimeout(isInTimerRef.current);

    if (props.in) {
      selectedOverlays.forEach((o) => (o.features = undefined));
      setTimeout(() => setIsIn(true), props.enterDelay);
    } else {
      setTimeout(() => {
        mapRef.current?.data?.forEach?.((feature) =>
          mapRef.current.data.remove(feature)
        );
      }, 0);
      setSelectedFeature(null);
      isInTimerRef.current = setTimeout(() => setIsIn(false), 200);
    }
  }, [props.in]);

  const handleOnLoad = (map, selectedOverlays) => {
    setProcessing(true);
    if (!map && !mapRef.current) return;

    if (!map) map = mapRef.current;
    if (!mapRef.current) mapRef.current = map;

    console.log(selectedOverlays);

    Promise.all([
      ...selectedOverlays
        .filter((o) => !o.features)
        .map((o, index) =>
          RESTAPI.Fetch(o.url).then((geojson) => {
            const fixed = {
              ...geojson,
              features: geojson.features.map((f, index) => ({
                ...f,
                id: undefined,
                properties: {
                  ...f.properties,
                  zIndex: index,
                  geometry: f.geometry.type,
                },
              })),
            };

            let features = map.data.addGeoJson(fixed);
            o.features = features;
          })
        ),
    ]).then(
      () => {
        map.data.setStyle((feature) => {
          let fill = feature.getProperty("fill") ?? feature.getProperty("Fill");
          let fillOpacity = feature.getProperty("fill-opacity");
          let stroke = feature.getProperty("stroke");
          let strokeWidth = feature.getProperty("stroke-width");
          let strokeOpacity = feature.getProperty("stroke-opacity");
          let markerColour = feature.getProperty("marker-color");
          let markerSize = feature.getProperty("marker-size");
          const markerSymbol = feature.getProperty("marker-symbol");

          const overlay = selectedOverlays.find((overlay) =>
            overlay.features?.some((f) => f === feature)
          );

          const featureIndex = feature.getProperty("zIndex");

          return {
            fillColor: fill ?? "#000000",
            fillOpacity: fillOpacity ?? 1,
            strokeColor: stroke,
            strokeOpacity: strokeOpacity,
            strokeWeight: strokeWidth,
            icon: {
              path:
                markerSize === "small"
                  ? markerPathSmall
                  : markerSize === "large"
                  ? markerPathLarge
                  : markerPathMedium,
              fillColor: markerSymbol === "circle" ? markerColour : fill,
              fillOpacity: (markerSymbol === "circle" ? !markerColour : !fill)
                ? 0.0
                : fillOpacity ?? 1.0,
              strokeColor: markerColour,
              strokeWeight: markerSymbol === "circle" ? 0 : 1,
              rotation: 0,
              scale: 1,
            },
            zIndex:
              featureIndex + (selectedOverlays.indexOf(overlay) + 1) * 4096,
          };
        });

        setProcessing(false);
      },
      () => {
        setProcessing(false);
        Toaster.makeToast("Failed to get map data!", "error");
      }
    );
  };

  const handleCenterMap = (map) => {
    let bounds = new window.google.maps.LatLngBounds();

    map.data.forEach((feature) =>
      processPoints(feature.getGeometry(), bounds.extend, bounds)
    );

    map.data.addListener("mouseover", function (event) {
      let strokeWeight = event.feature.getProperty("stroke-width");

      map.data.overrideStyle(event.feature, {
        strokeWeight: strokeWeight * 2,
      });
    });

    map.data.addListener("mouseout", function (event) {
      map.data.revertStyle(event.feature);
    });

    map.data.addListener("click", function (event) {
      let properties = [];
      event.feature.forEachProperty((value, key) => {
        properties.push([key, value]);
      });

      properties = properties.filter(
        (f) =>
          ![
            "stroke",
            "stroke-width",
            "stroke-opacity",
            "fill",
            "fill-opacity",
            "marker-symbol",
            "marker-size",
            "marker-color",
            "legend-group",
            "zIndex",
            "geometry",
            "Disclaimer",
          ].includes(f[0])
      );

      setSelectedFeature(Object.fromEntries(properties));
    });

    map.fitBounds(bounds);
  };

  const processPoints = (geometry, callback, thisArg) => {
    if (geometry instanceof window.google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof window.google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else {
      geometry.getArray().forEach((g) => {
        processPoints(g, callback, thisArg);
      });
    }
  };

  return (
    <Slide in={isIn} direction={`up`} timeout={200}>
      <Box
        position={`absolute`}
        top={0}
        left={0}
        width={`100%`}
        height={`100%`}
      >
        <Box
          overflow={`hidden`}
          height="100%"
          width="100%"
          position={"relative"}
          className={`${classes.hideImage} ${
            Themes.CurrentTheme().palette.type === "dark" ? classes.mapDark : ""
          } ${Themes.CurrentTheme().name === "Green" ? classes.mapGreen : ""}`}
        >
          <LoadScript
            id="script-loader"
            googleMapsApiKey={config.GOOGLE_MAPS_API_KEY}
            language="en"
            region="us"
          >
            <GoogleMap
              mapContainerClassName="App-map"
              mapContainerStyle={{ width: `100%`, height: `100%` }}
              zoom={10}
              version="weekly"
              on
              onLoad={(map) => handleOnLoad(map, selectedOverlays)}
            ></GoogleMap>
          </LoadScript>
          <Button
            variant="contained"
            disableElevation
            style={{
              position: "absolute",
              top: "10px",
              right: "60px",
              padding: "8px",
              minWidth: "unset",
            }}
            className={`${classes.closeButton}`}
            onClick={() => {
              setSelectedFeature(null);
              props.handleClose();
            }}
          >
            <span className={`material-icons`}>clear</span>
          </Button>
          {isIn && mapRef.current && (
            <OverlaySelector
              selectedFeature={selectedFeature}
              maps={overlayMaps}
              processing={processing}
              onChange={(m) =>
                selectedOverlays.some((o) => o.url === m.url)
                  ? (() => {
                      m.features?.forEach((feature) =>
                        mapRef.current.data.remove(feature)
                      );

                      console.log(m.features);

                      // mapRef.current.data.forEach((feature) => {
                      //   if (m.features?.some((f) => f === feature))
                      //     mapRef.current.data.remove(feature);
                      // });

                      m.features = undefined;

                      setSelectedOverlays(
                        selectedOverlays.filter((o) => o.url !== m.url)
                      );
                    })()
                  : (() => {
                      setSelectedOverlays([...selectedOverlays, m]);
                      handleOnLoad(null, [...selectedOverlays, m]);
                    })()
              }
              selectedOverlays={selectedOverlays}
            />
          )}
          <ContentPageMapLegend
            map={props.map}
            selectedOverlays={selectedOverlays}
          />
          <div className={`${classes.mapContainer}`}></div>
        </Box>
      </Box>
    </Slide>
  );
};

export default ContentPageMap;
