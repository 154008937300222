import {
  Box,
  Card,
  CardActionArea,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";

const styles = makeStyles((theme) => ({
  actionArea: {
    padding: theme.spacing(1),
  },
  description: {
    "& > p": {
      margin: `4px`,
    },
  },
}));

const MapPageItem = (props: any) => {
  const classes = styles();

  const [isIn, setIsIn] = React.useState(false);

  useEffect(() => {
    if (props.in) {
      setTimeout(() => setIsIn(true), 100 * props.index);
    } else {
      setTimeout(
        () => setIsIn(false),
        100 * props.totalCount - 100 * props.index
      );
    }
  }, [props.in]);

  return (
    <ListItem button onClick={props.onClick}>
      <ListItemText
        primary={props.map.name}
        secondary={
          <div
          className={`${classes.description}`}
            dangerouslySetInnerHTML={{ __html: props.map.description ?? "" }}
          ></div>
        }
      />
      <ListItemIcon>
        <span className={`material-icons`}>chevron_right</span>
      </ListItemIcon>
    </ListItem>
  );
};

export default MapPageItem;
