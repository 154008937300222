import {
  Box,
  Card,
  Collapse,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import { LoadScript } from "@react-google-maps/api";
import React, { useEffect } from "react";
import { Switch, useHistory } from "react-router";
import ToolBox from "ToolBox";
import config from "../../config";
import ContentPageMap from "../ContentPageMap";
import ExpandableText from "../ExpandableText";
import MapPageGrid from "./MapPageGrid";
import MapPageItem from "./MapPageItem";

const styles = makeStyles((theme) => ({
  gridBox: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(345px, 1fr))",
  },
}));

const MapPage = (props: any) => {
  const classes = styles();

  const history = useHistory();

  const [mapID, setMapID] = React.useState(history.location.hash.slice(1));

  const [groupedMaps, setGroupedMaps] = React.useState(
    null as null | [string, any[]][]
  );

  useEffect(() => {
    const grouped = Object.entries(
      ToolBox.groupBy(props.maps ?? [], "map_type")
    );

    setGroupedMaps(grouped);
  }, []);

  useEffect(() => {
    setMapID(history.location.hash.slice(1));
  }, [history.location.hash]);

  console.log(groupedMaps);

  return (
    <Box
      overflow={`hidden`}
      position={"relative"}
      height={`calc(100%)`}
      width={`100%`}
      marginLeft={`auto`}
      marginRight={`auto`}
    >
      <Box
        style={{
          height: `100%`,
          overflowY: !!mapID ? "hidden" : `scroll`,
          overflowX: "hidden",
          paddingRight: !!mapID ? "14px" : `0`,
          position: `absolute`,
          top: 0,
          width: `100%`,
        }}
        className={`custom-scrollbar`}
      >
        {(groupedMaps ?? [])
          .filter((mg) => mg[0] !== "Contextual layer")
          .map((mapGroup, index) => (
            <Box
              margin={`16px auto 0 auto`}
              maxWidth={`calc(min(1200px, 90%))`}
            >
              <Typography variant={`h6`}>
                {mapGroup[0] !== `undefined` ? mapGroup[0] : `[No Type Given]`}
              </Typography>
              <Box padding={`0 16px`}>
                <Typography variant={`caption`}>
                  {mapGroup[0] === "Evaluation" ||
                  mapGroup[0] === "Stressor heatmap" ||
                  mapGroup[0] === "Impact" ? (
                    <>
                      The map layer(s) is derived for visualisation purposes on
                      the GBA Explorer. The complete datasets from which these
                      map layers are derived are available at{" "}
                      <a
                        href={
                          ToolBox.CurrentTenant === "bee"
                            ? `https://data.gov.au/data/dataset/f86f6b04-56b3-43b1-a622-bd70b1f9596d`
                            : `https://data.gov.au/data/dataset/f8ce4a84-531b-4afc-9aa1-bc6ad51def06`
                        }
                      >
                        data.gov.au
                      </a>
                    </>
                  ) : mapGroup[0] === "Information" ? (
                    <>
                      The map layer(s) is derived for visualisation purposes on
                      the GBA Explorer. The extent of some data layers has been
                      clipped for visualisation purposes. The dataset from which
                      the map layer(s) is derived is available from the
                      Datasource attribute, accessible by clicking on the map
                      feature.
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>
              </Box>
              <MapPageGrid
                in={
                  !mapID.length ||
                  parseInt(mapID) < 0 ||
                  parseInt(mapID) >= (props.maps?.length ?? 0)
                }
                offset={
                  groupedMaps
                    ?.filter((g) => g[0] !== "Contextual layer")
                    .slice(0, index)
                    .map((g) => g[1])
                    .flat().length
                }
                maps={(mapGroup[1] ?? []).sort((a, b) =>
                  a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                )}
              />
            </Box>
          ))}
      </Box>
      <ContentPageMap
        in={
          !!(groupedMaps ?? [])
            .filter((g) => g[0] !== "Contextual layer")
            .map((g) =>
              g[1].sort((a, b) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
              )
            )
            .flat()?.[parseInt(mapID)]
        }
        map={
          (groupedMaps ?? [])
            .filter((g) => g[0] !== "Contextual layer")
            .map((g) =>
              g[1].sort((a, b) =>
                a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
              )
            )
            .flat()?.[parseInt(mapID)]
        }
        enterDelay={0}
        handleClose={() =>
          history.push(history.location.pathname + history.location.search)
        }
      />
    </Box>
  );
};

export default MapPage;
