import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";

const styles = makeStyles((theme) => ({
  paper: {
    display: `flex`,
    flexDirection: `column`,
    maxHeight: `100%`,
    pointerEvents: "all",
    border: `1px solid ${theme.palette.divider}`,
  },
  headerBox: {
    boxShadow: `0px 0px ${theme.spacing(1)}px ${theme.palette.divider}`,
  },
  listHeading: {
    fontSize: ".6875rem",
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    lineHeight: 2.66,
  },
  text: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
}));

const GraphNamedPathwayControl = (props: {
  in: boolean;
  pathways: {
    name: string;
    description: string;
    level_of_concern: string;
    path_confidence: string;
    node_ids: string[];
  }[];
  [key: string]: any;
}) => {
  const classes = styles();

  const [isIn, setIsIn]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ] = React.useState(props.in);
  const isInTimerRef: React.MutableRefObject<NodeJS.Timeout | null> =
    React.useRef(null as NodeJS.Timeout | null);

  useEffect(() => {
    if (props.in) {
      if (!!isInTimerRef.current) clearTimeout(isInTimerRef.current);
      isInTimerRef.current = setTimeout(() => setIsIn(true), 500);
    } else {
      if (!!isInTimerRef.current) clearTimeout(isInTimerRef.current);
      setIsIn(false);
    }
  }, [props.in]);

  return (
    <Slide
      direction="right"
      in={!!props.pathways?.length && isIn}
      mountOnEnter
      unmountOnExit
    >
      <Box
        top="56px"
        margin={`-8px`}
        padding={`8px`}
        style={{ marginBottom: "0px" }}
        display="flex"
        flexDirection="column"
        width="360px"
        flexGrow={1}
        overflow={`hidden`}
      >
        <Paper
          elevation={3}
          className={`${classes.paper}`}
          style={{ boxSizing: `border-box` }}
        >
          <Box
            display={`flex`}
            alignItems={`center`}
            justifyContent={`center`}
            width={`100%`}
            minHeight={`38px`}
            className={`${classes.headerBox}`}
          >
            <Typography className={classes.listHeading} align={`center`}>
              Named Paths
            </Typography>
          </Box>
          <Divider />
          <List
            style={{
              width: "360px",
              paddingBottom: 0,
              paddingTop: 0,
              overflowY: `auto`,
            }}
            className={`custom-scrollbar`}
            dense
          >
            {(props.pathways ?? []).map((pathway, index) => (
              <>
                {index !== 0 && <Divider />}
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    props.pinMultiple(
                      pathway.node_ids
                        .map((nid) =>
                          props.nodes.find((n: any) => n.uid === nid)
                        )
                        .filter((n) => n)
                    );
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        noWrap
                        variant="body2"
                        className={`${classes.text}`}
                      >
                        {pathway.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        style={{ fontSize: `0.825rem` }}
                      >
                        {pathway.level_of_concern}
                      </Typography>
                    }
                  />
                </ListItem>
              </>
            ))}
          </List>
        </Paper>
      </Box>
    </Slide>
  );
};

export default GraphNamedPathwayControl;
