import {
  Box,
  Button,
  ButtonGroup,
  Tooltip,
  makeStyles,
  Paper,
  Slide,
} from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import ToolBox from "../../ToolBox";

const styles = makeStyles((theme) => ({
  positionControlsContainer: {
    "& > *": {
      marginBottom: theme.spacing(1),
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  positionControlsButtonGroup: {
    overflow: `hidden`,
    borderRadius: theme.shape.borderRadius,
  },
  graphPositionButton: {
    borderRadius: theme.shape.borderRadius,
    minWidth: `unset`,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    pointerEvents: `all`,
    border: `1px solid ${theme.palette.divider}`,
    height: `32px`,
    width: `32px`,
  },
}));

const GraphPositionControls = (props) => {
  const classes = styles();

  const history = useHistory();

  return (
    <Slide direction="right" in={props.in}>
      <Box
        marginTop={`auto`}
        width={`32px`}
        display={`flex`}
        flexDirection={`column`}
        className={`${classes.positionControlsContainer}`}
      >
        <Tooltip title={`Reset Graph`} arrow>
          <Link
            to={`/${ToolBox.CurrentTenant}`}
            onClick={() => {
              ToolBox.SetQueries("pins", "", history);
              window.location.pathname = `/${ToolBox.CurrentTenant}`;
            }}
            style={{ textDecoration: "none" }}
          >
            <Button
              onClick={() => ToolBox.SetTenant(ToolBox.CurrentTenant, history)}
              size="small"
              className={`${classes.graphPositionButton} tour-guide tour-12`}
              variant={`contained`}
            >
              <span className="material-icons">restart_alt</span>
            </Button>
          </Link>
        </Tooltip>
        <Button
          variant="contained"
          onClick={props.handleCenterGraph}
          className={`${classes.graphPositionButton}`}
        >
          <span className={`material-icons`}>filter_center_focus</span>
        </Button>
        <ButtonGroup
          color={`inherit`}
          variant="contained"
          orientation="vertical"
          className={`${classes.positionControlsButtonGroup}`}
        >
          <Button
            onClick={() => props.handleZoom(0.05)}
            size="small"
            variant={"contained"}
            className={`${classes.graphPositionButton}`}
          >
            <span className="material-icons">add</span>
          </Button>
          <Button
            onClick={() => props.handleZoom(-0.05)}
            size="small"
            variant={"contained"}
            className={`${classes.graphPositionButton}`}
          >
            <span className="material-icons">remove</span>
          </Button>
        </ButtonGroup>
      </Box>
    </Slide>
  );
};

export default GraphPositionControls;
