import theme_dark from "./Dark";
import theme_light from "./Light";
import theme_green from "./Green";
import theme_blue from "./Blue";

export default class Themes {
  static DARK = theme_dark;
  static LIGHT = theme_light;
  static GREEN = theme_green;
  static BLUEBERRY = theme_blue;

  static THEMES = [Themes.LIGHT, Themes.DARK];

  static CurrentTheme() {
    return (
      [Themes.THEMES, Themes.GREEN, Themes.BLUEBERRY]
        .flat()
        .find(
          (theme) => theme.name === window.localStorage.getItem("site-theme")
        ) ?? Themes.LIGHT
    );
  }
}
