import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  makeStyles,
  Switch,
} from "@material-ui/core";
import React from "react";
import Themes from "../../../Themes/Themes";

const styles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    marginBottom: 24,
  },
  content: {},
}));

const Guide = (props) => {
  const classes = styles();

  const [counter, setCounter] = React.useState(25);

  const handleClick = () => {
    if (counter === 0) {
      props.changeTheme(Themes.GREEN);
    } else {
      setCounter((c) => c - 1);
    }
  };

  return (
    <>
      <Card className={classes.root} variant={`outlined`}>
        <CardHeader
          onClick={handleClick}
          title="Dev"
          subheader="This is a page to enable preview features."
          subheaderTypographyProps={{ variant: "body2" }}
        />
        <CardContent className={classes.content}>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  defaultChecked={
                    window.localStorage.getItem("show_guided_tour") === "true"
                  }
                  onChange={(e) => {
                    window.localStorage.setItem(
                      "show_guided_tour",
                      e.currentTarget.checked
                    );
                  }}
                />
              }
              label={`Tour`}
            />
            <p
              className={`MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled`}
            >
              Enable the guided tour.
            </p>
          </Box>
          <Box display="flex" justifyContent={`space-between`}>
            <p
              style={{ display: `block` }}
              className={`MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled`}
            >
              Reset the tour.
            </p>
            <Button
              color="primary"
              onClick={() => window.localStorage.removeItem("tour-taken")}
            >
              Reset
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Guide;
