import { Box, Divider, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ToolBox from "ToolBox";

const EntityCitation = (props: { item: any }) => {
  return (
    <Box
      boxSizing={`border-box`}
      maxWidth={`calc(min(1200px, 90%))`}
      minWidth={`calc(min(1200px, 90%))`}
      marginLeft={`auto`}
      marginRight={`auto`}
    >
      <Divider />
      <Typography
        variant={`body2`}
        component={`div`}
        style={{ fontWeight: 700, margin: `8px 8px 0 8px` }}
      >
        How to cite this page:
      </Typography>
      <Typography
        style={{ boxSizing: `border-box`, margin: `8px 32px` }}
        variant={`caption`}
        component={`div`}
      >
        Geological and Bioregional Assessment Program (2021){" "}
        <a
          href={`${window.location.protocol}//${window.location.hostname}:${
            window.location.port
          }/${window.location.pathname.split(/\//g)[1]}/items/item/${
            window.location.pathname.split(/\//g)[2] === "items"
              ? window.location.pathname.split(/\//g)[4]
              : window.location.pathname.split(/\//g)[3]
          }/0`}
        >
          {`${props.item.title}: ${props.item?.content_type} node description`}
        </a>{" "}
        for the {ToolBox.GetTenantName()}, accessed{" "}
        {moment().format(`DD MMMM yyyy`)}.
      </Typography>
    </Box>
  );
};

export default EntityCitation;
