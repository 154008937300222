import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import ToolBox from "ToolBox";

const styles = makeStyles((theme) => ({
  text: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
}));

const MapTypeLegend = (props: { mapType: string; maps: any[] }) => {
  const classes = styles();

  const getMapLegend = () => {
    switch (props.mapType) {
      case "Impact":
        return (
          <Paper variant={`outlined`}>
            <Table size={`small`}>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#FFFFFF`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell className={`${classes.text}`}>
                  Very low concern
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#FFD6B6`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell className={`${classes.text}`}>Low concern</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#E87823`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell className={`${classes.text}`}>
                  Potential concern
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#923F00`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell className={`${classes.text}`}>
                  Potentially high concern
                </TableCell>
              </TableRow>
            </Table>
          </Paper>
        );
      case "Evaluation":
        return (
          <Paper variant={`outlined`}>
            <Table size={`small`}>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#007A53`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell>
                  <>
                    <b>Not possible</b>
                  </>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#61B89C`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell>
                  <>
                    <b>Possible</b> but <b>not material</b>
                  </>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#DADBDC`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell>
                  <>
                    <b>Possible</b> and <b>material</b> but can be{" "}
                    <b>avoided</b>
                  </>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#B062BA`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell>
                  <>
                    <b>Possible, material</b> and <b>unavoidable</b> but can be{" "}
                    <b>mitigated</b>
                  </>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      backgroundColor: `#6D2077`,
                      border: `1px solid grey`,
                      width: 8,
                      height: 8,
                    }}
                  ></div>
                </TableCell>
                <TableCell>
                  <>
                    <b>Possible, material, unavoidable</b> and <b>cannot</b> be{" "}
                    <b>mitigated</b>
                  </>
                </TableCell>
              </TableRow>
            </Table>
          </Paper>
        );
      case "Contextual layer": {
        const featureKey = props.maps
          .filter((map) => map.map_type === "Contextual layer")
          .map((map) => map.features)
          .flat()
          .map(
            (f) =>
              Object.entries(f ?? {}).find(
                (entry: [string, any]) => entry[1]?.legend || entry[1]?.Legend
              )?.[0] ?? "h"
          )
          .filter((key) => !!key)?.[0];

        return props.maps
          .filter((map) => map.map_type === "Contextual layer")
          .map((map) => map.features)
          .flat()
          .map((f) => f?.[featureKey]?.["legend-group"])
          .filter((g, i, s) => s.indexOf(g) === i)
          .map((legendGroup) => (
            <Paper
              variant={`outlined`}
              style={{ padding: `2px 4px`, marginBottom: `8px` }}
            >
              <Typography variant={`h6`} style={{ fontSize: `0.9rem` }}>
                {legendGroup ?? ``}
              </Typography>
              {console.log(
                props.maps
                  .filter((map) => map.map_type === "Contextual layer")
                  .map((map) => map.features)
              )}
              <Table size={`small`}>
                {props.maps
                  .filter((map) => map.map_type === "Contextual layer")
                  .map((map) =>
                    (map.features ?? []).map((f: any) => ({
                      feature: f,
                      mapName: map.name,
                    }))
                  )
                  .flat()
                  .filter(
                    (f) =>
                      f.feature?.[featureKey]?.["legend-group"] === legendGroup
                  )
                  .map((f) => ({
                    text:
                      f.feature?.[featureKey]?.["Legend"] ??
                      f.feature?.[featureKey]?.[`Feature name`] ??
                      f.mapName,
                    fill:
                      f.feature?.[featureKey]?.["fill"] ??
                      f.feature?.[featureKey]?.["Fill"] ??
                      f.feature?.[featureKey]?.["marker-color"],
                    fill_opacity: f.feature?.[featureKey]?.["fill-opacity"],
                    stroke:
                      f.feature?.[featureKey]?.["marker-color"] ??
                      f.feature?.[featureKey]?.["stroke"],
                    type: f.feature?.[featureKey]?.["marker-symbol"],
                    size: f.feature?.[featureKey]?.["marker-size"],
                    geometry: f.feature?.[featureKey]?.["geometry"],
                  }))
                  .filter(
                    (x, i, s) => s.map((f) => f.text).indexOf(x.text) === i
                  )
                  .map((legendEntry, index, s) => (
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: index === s.length - 1 ? "none" : "",
                        }}
                      >
                        {console.log(legendEntry)}
                        {["MultiLineString"].includes(legendEntry.geometry) ? (
                          <svg
                            width="14"
                            height="12"
                            style={{
                              margin: `auto`,
                              display: `block`,
                            }}
                          >
                            <path
                              d={`m 1 10 L 6 5 L 8 7 L 13 2`}
                              style={{
                                strokeWidth: `2px`,
                                stroke: legendEntry.stroke,
                                fill: "none",
                              }}
                            ></path>
                          </svg>
                        ) : (
                          <div
                            style={{
                              margin: `auto`,
                              backgroundColor:
                                legendEntry.type === `circle-stroked`
                                  ? `transparent`
                                  : `${legendEntry.fill}${
                                      !!legendEntry?.fill
                                        ? `${(
                                            ((legendEntry.fill_opacity ?? 1) *
                                              255) |
                                            0
                                          ).toString(16)}`
                                        : legendEntry.type === "circle"
                                        ? "FF"
                                        : "00"
                                    }`,
                              border: `${
                                !!legendEntry.fill_opacity ||
                                legendEntry.type === "circle"
                                  ? 1
                                  : 2
                              }px solid ${legendEntry.stroke}`,
                              borderRadius: !!legendEntry.type ? `50%` : `0%`,
                              width: !legendEntry.type
                                ? 8
                                : legendEntry.size === "small"
                                ? 2
                                : legendEntry.size === "medium"
                                ? 4
                                : 8,
                              height: !legendEntry.type
                                ? 8
                                : legendEntry.size === "small"
                                ? 2
                                : legendEntry.size === "medium"
                                ? 4
                                : 8,
                            }}
                          ></div>
                        )}
                      </TableCell>
                      <TableCell
                        className={`${classes.text}`}
                        style={{
                          borderBottom: index === s.length - 1 ? "none" : "",
                        }}
                      >
                        {legendEntry.text ?? `[No Value]`}
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Paper>
          ));
      }
      case "Stressor heatmap": {
        const featureKey = props.maps
          .filter((map) => map.map_type === "Stressor heatmap")
          .map((map) => map.features)
          .flat()
          .map(
            (f) =>
              Object.entries(f ?? {}).find(
                (entry: [string, any]) => entry[1]?.legend || entry[1]?.Legend
              )?.[0]
          )
          .filter((key) => !!key)?.[0];

        if (!featureKey) return <></>;

        return props.maps
          .filter((map) => map.map_type === "Stressor heatmap")
          .map((map) => map.features)
          .flat()
          .filter(
            (f) =>
              (f?.[featureKey]?.[`legend`] ?? f?.[featureKey]?.[`Legend`]) !==
              undefined
          )
          .map((f) => f?.[featureKey]?.["legend-group"])
          .filter((g, i, s) => s.indexOf(g) === i)
          .map((legendGroup) => (
            <Paper
              variant={`outlined`}
              style={{ padding: `2px 4px`, marginBottom: `8px` }}
            >
              {console.log(
                props.maps
                  .filter((map) => map.map_type === "Stressor heatmap")
                  .map((map) => map.features)
                  .flat()
              )}
              <Typography variant={`h6`} style={{ fontSize: `0.9rem` }}>
                {legendGroup ?? ``}
              </Typography>
              <Table size={`small`}>
                {props.maps
                  .filter((map) => map.map_type === "Stressor heatmap")
                  .map((map) => map.features)
                  .flat()
                  .filter(
                    (f) =>
                      (f?.[featureKey]?.[`legend`] ??
                        f?.[featureKey]?.[`Legend`]) !== undefined
                  )
                  .filter(
                    (f) => f?.[featureKey]?.["legend-group"] === legendGroup
                  )
                  .map((f) => ({
                    text: f?.[featureKey]?.["Legend"],
                    fill:
                      f?.[featureKey]?.["fill"] ??
                      f?.[featureKey]?.["Fill"] ??
                      f?.[featureKey]?.["marker-color"],
                    fill_opacity: f?.[featureKey]?.["fill-opacity"],
                    stroke:
                      f?.[featureKey]?.["marker-color"] ??
                      f?.[featureKey]?.["stroke"],
                    type: f?.[featureKey]?.["marker-symbol"],
                    size: f?.[featureKey]?.["marker-size"],
                    geometry: f?.[featureKey]?.["geometry"],
                  }))
                  .filter(
                    (x, i, s) => s.map((f) => f.text).indexOf(x.text) === i
                  )
                  .map((legendEntry, index, s) => (
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: index === s.length - 1 ? "none" : "",
                        }}
                      >
                        {["MultiLineString", "LineString"].includes(
                          legendEntry.geometry
                        ) ? (
                          <svg
                            width="14"
                            height="12"
                            style={{
                              margin: `auto`,
                              display: `block`,
                            }}
                          >
                            <path
                              d={`m 1 10 L 6 5 L 8 7 L 13 2`}
                              style={{
                                strokeWidth: `2px`,
                                stroke: legendEntry.stroke,
                                fill: "none",
                              }}
                            ></path>
                          </svg>
                        ) : (
                          <div
                            style={{
                              margin: `auto`,
                              backgroundColor: `${legendEntry.fill}${
                                !!legendEntry?.fill
                                  ? `${(
                                      ((legendEntry.fill_opacity ?? 1) * 255) |
                                      0
                                    ).toString(16)}`
                                  : legendEntry.type === "circle"
                                  ? "FF"
                                  : "00"
                              }`,
                              border: `1px solid ${legendEntry.stroke}`,
                              borderRadius: !!legendEntry.type ? `50%` : `0%`,
                              width: !legendEntry.type
                                ? 8
                                : legendEntry.size === "small"
                                ? 2
                                : legendEntry.size === "medium"
                                ? 4
                                : 8,
                              height: !legendEntry.type
                                ? 8
                                : legendEntry.size === "small"
                                ? 2
                                : legendEntry.size === "medium"
                                ? 4
                                : 8,
                            }}
                          ></div>
                        )}
                      </TableCell>
                      <TableCell
                        className={`${classes.text}`}
                        style={{
                          borderBottom: index === s.length - 1 ? "none" : "",
                        }}
                      >
                        {legendEntry.text ?? `[No Value]`}
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Paper>
          ));
      }
      case "Information": {
        const featureKey = props.maps
          .filter((map) => map.map_type === "Information")
          .map((map) => map.features)
          .flat()
          .map(
            (f) =>
              Object.entries(f ?? {}).find(
                (entry: [string, any]) => entry[1]?.legend || entry[1]?.Legend
              )?.[0] ?? "h"
          )
          .filter((key) => !!key)?.[0];

        return props.maps
          .filter((map) => map.map_type === "Information")
          .map((map) => map.features)
          .flat()
          .filter(
            (f) =>
              (f?.[featureKey]?.[`legend`] ?? f?.[featureKey]?.[`Legend`]) !==
              undefined
          )
          .map((f) => f?.[featureKey]?.["legend-group"])
          .filter((g, i, s) => s.indexOf(g) === i)
          .map((legendGroup) => (
            <Paper
              variant={`outlined`}
              style={{ padding: `2px 4px`, marginBottom: `8px` }}
            >
              {console.log(
                props.maps
                  .filter((map) => map.map_type === "Information")
                  .map((map) => map.features)
                  .flat()
              )}
              <Typography variant={`h6`} style={{ fontSize: `0.9rem` }}>
                {legendGroup ?? ``}
              </Typography>
              <Table size={`small`}>
                {props.maps
                  .filter((map) => map.map_type === "Information")
                  .map((map) => map.features)
                  .flat()
                  .filter(
                    (f) =>
                      (f?.[featureKey]?.[`legend`] ??
                        f?.[featureKey]?.[`Legend`]) !== undefined
                  )
                  .filter(
                    (f) => f?.[featureKey]?.["legend-group"] === legendGroup
                  )
                  .map((f) => ({
                    text: f?.[featureKey]?.["Legend"],
                    fill:
                      f?.[featureKey]?.["fill"] ??
                      f?.[featureKey]?.["Fill"] ??
                      f?.[featureKey]?.["marker-color"],
                    fill_opacity: f?.[featureKey]?.["fill-opacity"],
                    stroke:
                      f?.[featureKey]?.["marker-color"] ??
                      f?.[featureKey]?.["stroke"],
                    type: f?.[featureKey]?.["marker-symbol"],
                    size: f?.[featureKey]?.["marker-size"],
                    geometry: f?.[featureKey]?.["geometry"],
                  }))
                  .filter(
                    (x, i, s) => s.map((f) => f.text).indexOf(x.text) === i
                  )
                  .map((legendEntry, index, s) => (
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: index === s.length - 1 ? "none" : "",
                        }}
                      >
                        {["MultiLineString", "LineString"].includes(
                          legendEntry.geometry
                        ) ? (
                          <svg
                            width="14"
                            height="12"
                            style={{
                              margin: `auto`,
                              display: `block`,
                            }}
                          >
                            <path
                              d={`m 1 10 L 6 5 L 8 7 L 13 2`}
                              style={{
                                strokeWidth: `2px`,
                                stroke: legendEntry.stroke,
                                fill: "none",
                              }}
                            ></path>
                          </svg>
                        ) : (
                          <div
                            style={{
                              margin: `auto`,
                              backgroundColor: `${legendEntry.fill}${
                                !!legendEntry?.fill
                                  ? `${(
                                      ((legendEntry.fill_opacity ?? 1) * 255) |
                                      0
                                    ).toString(16)}`
                                  : legendEntry.type === "circle"
                                  ? "FF"
                                  : "00"
                              }`,
                              border: `1px solid ${legendEntry.stroke}`,
                              borderRadius: !!legendEntry.type ? `50%` : `0%`,
                              width: !legendEntry.type
                                ? 8
                                : legendEntry.size === "small"
                                ? 2
                                : legendEntry.size === "medium"
                                ? 4
                                : 8,
                              height: !legendEntry.type
                                ? 8
                                : legendEntry.size === "small"
                                ? 2
                                : legendEntry.size === "medium"
                                ? 4
                                : 8,
                            }}
                          ></div>
                        )}
                      </TableCell>
                      <TableCell
                        className={`${classes.text}`}
                        style={{
                          borderBottom: index === s.length - 1 ? "none" : "",
                        }}
                      >
                        {legendEntry.text ?? `[No Value]`}
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Paper>
          ));
      }
      default:
        return null;
    }
  };

  return <>{getMapLegend()}</>;
};

export default MapTypeLegend;
