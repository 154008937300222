import { ReactourStep } from "reactour";

const TourSteps: (ReactourStep & {
  delay?: number;
  requiresInteraction?: boolean;
})[] = [
  // Step 1
  {
    selector: `.tour-guide.tour-0`,
    content: (
      <>
        Welcome to the home page!
        <br />
        From here you can navigate to each of the GBA regions.
      </>
    ),
    stepInteraction: false,
  },
  // Step 2
  {
    selector: `.tour-guide.tour-1`,
    content: (
      <>
        For the purpose of this tour, lets take a look at the{" "}
        <b>‘About GBA Explorer’</b> network.
        <br />
        <br />
        <b>Click the ‘About GBA Explorer’ tile to continue</b>.
      </>
    ),
    stepInteraction: true,
    requiresInteraction: true,
    delay: 5000,
  },
  // Step 3
  {
    selector: `.tour-guide.tour-2`,
    content: (
      <>
        The Australian Government’s Geological and Bioregional Assessment
        Program assesses the potential impacts on water and the environment
        using causal networks.
        <br />
        <br />
        The causal network is a visual display of all the cause-and-effect
        relationships between an action and something that we are responsible
        for protecting. In simple terms, it shows the complex and connected
        nature of the environment and regulations that protect this environment
        in the region being assessed.
        <br />
        <br />
        You can navigate using the mouse by clicking, dragging, and zooming.
      </>
    ),
    stepInteraction: false,
  },
  // Step 4
  {
    selector: `.tour-guide.tour-2`,
    content: (
      <>
        This causal network is made up of five types of ‘node’ that describe the
        different points in a pathway from an action to potential impact.
        <br />
        <br />
        The nodes are connected via ‘links’
        <br />
        <br />
        Links are a visual way of showing the relationships between nodes. The
        GBA Program has evaluated each link using a consistent methodology.
      </>
    ),
    stepInteraction: false,
  },
  // Step 5
  {
    selector: `.tour-guide.tour-3`,
    content: (
      <>
        Each region will have a panel like this with the following features:
        <ul>
          <li>
            <b>Geological and Bioregional Assessment banner</b> - If you need
            more information, including how to contact us, you can view the
            Geological and Bioregional Assessments website by clicking on the
            banner image.
          </li>
          <li>
            <b>Home</b>
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              home
            </span>{" "}
            - you can return to the home page at any time by clicking this
            button.
          </li>
          <li>
            <b>Region name</b> - tells you which region you are looking at.
          </li>
          <li>
            <b>Take the tour</b>
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              help
            </span>{" "}
            - you can take this tour at any time by clicking this icon.
          </li>
          <li>
            <b>Assessment summary</b> - a summary of key findings from the GBA
            Program’s assessment for each GBA region is available here.
          </li>
          <li>
            <b>Search</b>
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              search
            </span>{" "}
            - you can use the search function to search for content or nodes
            that you want to know about.
          </li>
          <li>
            <b>Named paths</b> - These are pathways that have been created by
            GBA to easily show and give context to selected pathways.
          </li>
        </ul>
      </>
    ),
    stepInteraction: false,
  },
  // Step 6
  {
    selector: `.tour-guide.tour-4`,
    content: (
      <>
        This box at the bottom left of screen contains tabs that show
        information about evaluations, nodes and level of confidence.{" "}
        <b>Evaluations</b> are the links between each node and represent the
        likelihood (is it possible or not), consequence (is it material or not),
        avoidance (can it be avoided or not) and mitigation (can it be mitigated
        or not) for each link. Check boxes in this legend can be selected to
        show only specific link types.
        <br />
        <br />
        The Nodes tab shows type of node by colour and name. The
        <span
          className={`material-icons`}
          style={{
            transform: `scale(1.2)`,
            marginLeft: `4px`,
            height: `0px`,
            fontSize: `15px`,
          }}
        >
          filter_list
        </span>{" "}
        tab shows the levels of confidence in the evaluation and low, medium or
        high confidence can be selected.
      </>
    ),
    stepInteraction: false,
  },
  // Step 7
  {
    selector: `.tour-guide.tour-2`,
    content: (
      <>
        Importantly, the GBA assessment assesses both the links between nodes
        and the total risk from the Driver (grey) to a protected matter or
        ‘endpoint’ node (blue).
        <br />
        <br />
        GBA Explorer allows the assessment to be focused and simplified for
        matters of interest. Nodes can be pinned to view all linkages between
        the driver and an endpoint.
        <br />
        <br />
        In the next steps we’ll show you how to pin nodes to show individual
        pathways.
      </>
    ),
    stepInteraction: false,
  },
  // Step 8
  {
    selector: `.tour-guide.tour-5`,
    content: (
      <>
        You can start pinning at any node you like, but for the tour we’ll show
        the pathways from the driver to an endpoint.
        <br />
        <br />
        <b>Click on the path icon</b>{" "}
        <span
          className={`material-icons`}
          style={{
            transform: `scale(1.2)`,
            marginLeft: `4px`,
            height: `0px`,
            fontSize: `15px`,
          }}
        >
          alt_route
        </span>{" "}
        <b>to pin this node.</b>
      </>
    ),
    stepInteraction: true,
    requiresInteraction: true,
  },
  // Step 9
  {
    selector: `.tour-guide.tour-6`,
    content: (
      <>
        <b>Now pin Endpoint L.</b>
      </>
    ),
    stepInteraction: true,
    requiresInteraction: true,
  },
  {
    // Step 10
    selector: `.tour-guide.tour-2`,
    content: (
      <>
        You can see that now only nodes that link to this endpoint are
        highlighted and there is a list of pinned items under the search bar.
        <br />
        <br />
        A connected link from Driver to endpoint is called a pathway. Here you
        can see that there are 2 pathways to Endpoint L. One through ‘Activity
        B’ and one through ‘Activity C’.
        <br />
        <br />
        Once you have set your start and end nodes you can further refine the
        paths shown by pinning additional nodes.
        <br />
        <br />
        You can unpin nodes by clicking on the path icon{" "}
        <span
          className={`material-icons`}
          style={{
            transform: `scale(1.2)`,
            marginLeft: `4px`,
            height: `0px`,
            fontSize: `15px`,
          }}
        >
          alt_route
        </span>{" "}
        or by clicking on the pin icon in the pinned items list.
      </>
    ),
    stepInteraction: false,
  },
  {
    // Step 11
    selector: `.tour-guide.tour-7`,
    content: (
      <>
        <b>Viewing content</b>
        <br />
        <br />
        When viewing the actual assessment, you can explore the network however
        you wish – from front to back, back to front or dive right into the
        detail.
        <br />
        <br />
        For this tour we’ll start by exploring the node description for
        ‘Activity B’.
        <br />
        <br />
        This node has important information on assessing pathways, please look
        at this and the{" "}
        <a
          href={`https://www.bioregionalassessments.gov.au/gba/introduction-causal-networks`}
          rel="noreferrer"
          target="_blank"
        >
          ‘Introduction to causal networks’ plain English guide
        </a>{" "}
        when you have a chance.
        <br />
        <br />
        <b>Click activity B, anywhere except the path icon, to continue.</b>.
      </>
    ),
    stepInteraction: true,
    requiresInteraction: true,
    delay: 2000,
  },
  {
    // Step 12
    selector: `.tour-guide.tour-8`,
    content: (
      <>
        Clicking on any node (box) or link (line) opens a detailed information
        tray. From here you can find out all you need to know about that node or
        link.
        <br />
        <br />
        You can also:
        <ul>
          <li>
            Drag the edge tray to increase its size
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2) rotate(90deg) translate(50%, -6px)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              drag_handle
            </span>
            .
          </li>
          <li>
            Set the node you’re reading about as the root node{" "}
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              room
            </span>{" "}
            – this will set the current node as the starting point of the
            network and show all paths flowing from there.
          </li>
          <li>
            Expand the tray to a full page to make reading easier by clicking on
            the ‘open as standalone page’ icon{" "}
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              open_in_full
            </span>
            .{" "}
          </li>
          <li>
            Close the tray by clicking the cross{" "}
            <span
              className={`material-icons`}
              style={{
                transform: `scale(1.2)`,
                marginLeft: `4px`,
                height: `0px`,
                fontSize: `15px`,
              }}
            >
              close
            </span>
            .
          </li>
        </ul>
      </>
    ),
    stepInteraction: false,
  },
  // Step 13
  {
    selector: `.tour-guide.tour-9`,
    content: (
      <>
        A series of tabs at the top of each tray provides supporting detail on
        the parts of the assessment related to the node or link.
        <br />
        <br />
        Common node tabs are:
        <ul>
          <li>
            <b>Content</b> - The ‘content’ tab provides the detailed information
            for the node. This is the default tab that opens when you click on
            any node.
          </li>
          <li>
            <b>Maps</b> - Where available, maps of the assessment evaluations
            can be accessed from the ‘maps’ tab. Not all nodes will have a maps
            tab.
          </li>
          <li>
            <b>Evaluations</b> - The ‘Evaluations’ tab provides a summary of the
            links from this node to other nodes. You can see more information on
            each link by clicking on the ‘preceding’ or ‘exiting evaluations’
            buttons. You can also find this information by clicking on the link
            line in the network.
          </li>
          <li>
            <b>References</b> - The ‘References’ tab lists the supporting
            literature and datasets that the GBA Program has used for the
            assessment and links on where to download data.
          </li>
        </ul>
      </>
    ),
    stepInteraction: false,
  },
  // Step 14
  {
    selector: `.tour-guide.tour-10`,
    content: (
      <>
        On some tabs you will see a ‘Table of contents’ button.
        <br />
        <br />
        <b>Click the button</b>
      </>
    ),
    stepInteraction: true,
    requiresInteraction: true,
    delay: 750,
  },
  // Step 15
  {
    selector: `.tour-guide.tour-11`,
    content: (
      <>
        Selecting a heading in the table of contents allows you to quickly
        scroll to the selected heading in the content. This allows you to
        quickly find the information you want.
      </>
    ),
    stepInteraction: false,
  },
  // Step 16
  {
    selector: `.tour-guide.tour-12`,
    content: (
      <>
        To reset the network click the ‘reset graph’ button{" "}
        <span
          className={`material-icons`}
          style={{
            transform: `scale(1.2)`,
            marginLeft: `4px`,
            height: `0px`,
            fontSize: `15px`,
          }}
        >
          restart_alt
        </span>
        .
      </>
    ),
    stepInteraction: false,
  },
  // Step 17
  {
    selector: `body`,
    content: (
      <>
        This concludes the tour, continue with a self-guided tour by clicking on
        each of the nodes in this demonstration site. Start by clicking on the
        ‘Driver’ node.
      </>
    ),
    stepInteraction: false,
  },
];

export default TourSteps;
