import { ThemeOptions } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";

const theme = {
  name: "Light",
  palette: {
    primary: {
      main: "#00293F",
    },
    secondary: deepOrange,
    text: {
      secondary: "#424242",
    },
    type: "light",
  },
  shape: {
    borderRadius: 2,
  },
  links: (val) => {
    switch (`${val}`) {
      case "0":
        return "#237a53";
      case "1":
        return "#61b89c";
      case "2":
        return "#aaabac";
      case "3":
        return "#b062ba";
      case "4":
        return "#6d2077";
      case "9":
        return "#a0d9e6";
      default:
        return "#FF00FF";
    }
  },
  nodes: (val) => {
    switch (val) {
      case "Driver":
        return "#8b8b8b";
      case "Activity":
        return "#f11500";
      case "Stressor":
        return "#a4f296";
      case "Process":
        return "#f8e000";
      case "Endpoint":
        return "#3b01ff";
      default:
        return "#FF00FF";
    }
  },
  transition: {
    short: "200ms",
    normal: "500ms",
    long: "1000ms",
  },
};

export default theme;
