import {
  Box,
  Collapse,
  Divider,
  List,
  Card,
  Typography,
  IconButton,
  makeStyles,
  ListItem,
  Fab,
  Paper,
  Grow,
  Menu,
  Popover,
  Popper,
} from "@material-ui/core";
import Themes from "components/Themes/Themes";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Toaster from "../Toaster";
import TOCListItem from "./TOCListItem";
import ReactDOM from "react-dom";
import { ISO_8601 } from "moment";
import { transform } from "typescript";

const HEADINGS = ["h1", "h2", "h3", "h4", "h5"];

interface TOCHeading {
  lvl: string;
  el: Element;
  children: TOCHeading[];
}

const styles = makeStyles((theme) => ({
  card: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: `0px 0px 5px ${theme.palette.divider}`,
  },
}));

const TOC = (props: any) => {
  const classes = styles();

  const [tocItems, setTocItems] = React.useState(null);

  const history = useHistory();

  useEffect(() => {
    const occurance_count: { [key: string]: number } = {};

    const makeChildren: any = (nodes: any[]) => {
      let head = nodes[0];
      let tail = nodes.slice(1);

      if (!head) return [];

      let innertext: string = head?.el?.innerText
        .match(/[a-z|0-9|\s]/gi)
        ?.join("")
        .trim()
        .toLowerCase()
        .replace(/\s/g, "_");

      head.urlText = `${innertext}${
        occurance_count[`${innertext}`]
          ? `_${occurance_count[`${innertext}`]}`
          : ``
      }`;

      occurance_count[`${innertext}`] =
        (occurance_count[`${innertext}`] ?? 0) + 1;

      if (tail.length === 0) return [head];

      let numDecendents = tail.findIndex((n) => n.depth <= head.depth);

      if (numDecendents < 0) numDecendents = tail.length;

      let decendents = tail.splice(0, numDecendents);

      if (decendents.length) head.children = makeChildren(decendents);

      return [head, ...(tail.length ? makeChildren(tail) : [])];
    };

    let headings = [
      ...document
        .getElementById(props.contentId)
        ?.querySelectorAll(HEADINGS.join(", "))!,
    ]
      .map((el) => el as HTMLElement)
      .filter((el) => el.innerText.match(/[A-Z]/gi))
      .map((h) => ({
        el: h,
        depth: HEADINGS.findIndex((i) => i === h.tagName.toLowerCase()),
      }));

    let thingy = makeChildren(headings).filter((n: any) => n);

    setTocItems(thingy);
  }, [props.content]);

  useEffect(() => {
    process.nextTick(() => {
      props.setAnchor(null);

      let hash = history.location.hash.slice(1);

      if (!hash?.length) return;

      let hashHeading = [
        ...document
          .getElementById(props.contentId)
          ?.querySelectorAll(HEADINGS.join(", "))!,
      ]
        .map((el) => el as HTMLElement)
        .filter((el) => el.innerText.match(/[A-Z]/gi))
        .find((el) => {
          const elInnerText = el.innerText
            .match(/[a-z|0-9|\s]/gi)
            ?.join("")
            .trim()
            .toLowerCase()
            .replace(/\s/g, "_");

          const occurance = [
            ...document
              .getElementById(props.contentId)
              ?.querySelectorAll(HEADINGS.join(", "))!,
          ]
            .map((el) => el as HTMLElement)
            .filter((el) => el.innerText.match(/[A-Z]/gi))
            .filter(
              (e) =>
                e.innerText
                  .match(/[a-z|0-9|\s]/gi)
                  ?.join("")
                  .trim()
                  .toLowerCase()
                  .replace(/\s/g, "_") === elInnerText
            )
            .indexOf(el);

          const fin = `${elInnerText}${occurance > 0 ? `_${occurance}` : ``}`;

          return fin === hash;
        });

      if (!hashHeading) {
        Toaster.makeToast(`Cannot scroll to ${hash}, heading does not exist`);
        return;
      }

      const scrollContainer = document.getElementById(
        props.isStandalone
          ? `standalone-scroll-container`
          : `info-scroll-container`
      );

      hashHeading.scrollIntoView();

      scrollContainer!.scrollTop -= props.isStandalone ? 158 : 56;
    });
  }, [history.location.hash]);

  return (
    <Popover
      anchorEl={props.anchor}
      open={!!props.anchor}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={() => props.setAnchor(null)}
    >
      <Box
        style={{
          width: "100%",
          minWidth: `256px`,
          maxWidth: `360px`,
          maxHeight: `75vh`,
          boxSizing: "border-box",
          left: 0,
          borderTopWidth: "1px",
          borderBottomWidth: "1px",
          height: "fit-content",
          overflowY: "auto",
        }}
        className={`custom-scrollbar tour-guide tour-11`}
      >
        <List dense disablePadding style={{}}>
          {(tocItems ?? []).map((i, index) => (
            <List disablePadding>
              {index !== 0 && <Divider style={{ backgroundColor: "white" }} />}
              <TOCListItem item={i} depth={0} />
            </List>
          ))}
        </List>
      </Box>
    </Popover>
  );
};

export default TOC;
